import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./BulkOrderingForm .css";
import { toast } from "react-toastify";
import MobDealershipForm from "../../components/Mob/MobForms/MobDealershipForm";
import { isMobile } from "react-device-detect";
import Navbardesktop from "../../components/Web/Navbar/Navbardesktop,";

const baseUrl = process.env.REACT_APP_BASEURL;

const DealershipForm = () => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    email: "",
    name: "",
    phone: "",
    website: "",
    address: "",
    jobTitle: "",
    comments: "",
    business: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const responses = await axios.post(`${baseUrl}/api/products/dealership`, {
        email: formValues.email,
        name: formValues.name,
        phone: formValues.phone,
        website: formValues.website,
        address: formValues.address,
        jobTitle: formValues.jobTitle,
        comments: formValues.comments,
        business: formValues.business,
      });
      toast.success("Created successfully");
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  return isMobile ? (
    <MobDealershipForm />
  ) : (
    <div>
      <Navbardesktop />
      <div className="form-container">
        <h3 className="form-header-h3">Dealership Form</h3>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="dealer-name">Name:</label>
            <input
              type="text"
              id="dealer-name"
              onChange={handleInputChange}
              name="name"
              required
              placeholder="Enter your name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="dealer-email">Email:</label>
            <input
              type="email"
              id="dealer-email"
              onChange={handleInputChange}
              name="email"
              required
              placeholder="Enter your email"
            />
          </div>
          <div className="form-group">
            <label htmlFor="dealer-phone">Phone Number:</label>
            <input
              type="tel"
              id="dealer-phone"
              onChange={handleInputChange}
              name="phone"
              required
              placeholder="Enter your phone number"
            />
          </div>
          <div className="form-group">
            <label htmlFor="dealer-website">Website (optional):</label>
            <input
              type="url"
              id="dealer-website"
              onChange={handleInputChange}
              name="website"
              placeholder="Enter your website URL"
            />
          </div>
          <div className="form-group">
            <label htmlFor="dealer-address">Address:</label>
            <input
              type="text"
              id="dealer-address"
              onChange={handleInputChange}
              name="address"
              required
              placeholder="Enter your address"
            />
          </div>
          <div className="form-group">
            <label htmlFor="dealer-job-title">Job Title:</label>
            <input
              type="text"
              id="dealer-job-title"
              onChange={handleInputChange}
              name="jobTitle"
              required
              placeholder="Enter your job title"
            />
          </div>
          <div className="form-group">
            <label htmlFor="dealer-business">Business Info:</label>
            <textarea
              id="dealer-business"
              name="business"
              required
              onChange={handleInputChange}
              placeholder="Enter business information"
            />
          </div>
          <div className="form-group">
            <label htmlFor="dealer-comments">Comments:</label>
            <textarea
              id="dealer-comments"
              name="comments"
              onChange={handleInputChange}
              required
              placeholder="Any additional comments"
            />
          </div>
          <div className="form-actions">
            <button type="submit">
              Submit
            </button>
            <button
              type="button"
              className="close-button-secondary"
              onClick={() => navigate(-1)}
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DealershipForm;
