import React, { useEffect, useState } from "react";
import "./AboutUs.css";
import Footer from "../../components/Web/Footer/Footer";
import Navbardesktop from "../../components/Web/Navbar/Navbardesktop,";
import axios from "axios";
import { isMobile } from "react-device-detect";
import MobAboutUs from "../../components/Mob/MobContact/MobAboutUs";

const baseUrl = process.env.REACT_APP_BASEURL;

const AboutUs = () => {
  const [blogData, setBlogData] = useState([]);

  // Fetching data from the API
  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/blog/allonaboutus`);
        setBlogData(response.data);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    fetchBlogData();
  }, []);

  return isMobile ? (
    <MobAboutUs />
  ) : (
    <div>
      <Navbardesktop />

      <section className="about-hero-section">
        <div className="about-hero-content">
          <h1>Welcome to Manna Delights</h1>
          <p>Discover the art of flavors in every bite.</p>
        </div>
      </section>

      <section className="about-story-section">
        {blogData.map((item, index) => (
          <div key={index} className="about-story-content">
            <h2>{item.title}</h2>
            <p>{item.description}</p>
            <div className="about-images">
              {/* <img
                src={item.imageUrl}
                alt={item.title}
                className="about-blog-image"
              /> */}
            </div>
          </div>
        ))}
      </section>

      {/* <section className="about-mission-section">
        <div className="about-mission-content">
          <h2>Our Mission</h2>
          <p>
            At Manna Delights, we are committed to inspiring moments of happiness and satisfaction through our products. With a focus on quality, creativity, and customer delight, we aim to become synonymous with premium indulgence and joyful experiences.
          </p>
          <div className="about-images">
            <img src={peopleImage} alt="People" />
          </div>
        </div>
      </section> */}

      <Footer />
    </div>
  );
};

export default AboutUs;
