import React, { useEffect, useState } from "react";
import "./BlogPage.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isMobile } from "react-device-detect";
import MobileBlogPage from "../../components/Mob/MobileBlog/MobileBlogPage";
import Navbardesktop from "../../components/Web/Navbar/Navbardesktop,";
const baseUrl = process.env.REACT_APP_BASEURL;

const BlogPage = () => {
  const [selectedTab, setSelectedTab] = useState("newest");
  const [blogs, setBlogs] = useState([]);
  const [trendingBlogs, setTrendingBlogs] = useState([]);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/blog/allonblogs`);
      if (Array.isArray(response.data)) {
        setBlogs(response.data);
      } else {
        toast.error("Error fetching blogs");
      }
    } catch (error) {
      toast.error("Error fetching blogs");
    }
  };

  const fetchTrendingBlogs = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/blog/all/trending`);
      if (Array.isArray(response.data)) {
        setTrendingBlogs(response.data);
      } else {
        toast.error("Error fetching trending blogs");
      }
    } catch (error) {
      toast.error("Error fetching trending blogs");
    }
  };

  useEffect(() => {
    fetchBlogs();
    fetchTrendingBlogs();
  }, []);

  const renderBlogs = () => {
    if (selectedTab === "newest") {
      return blogs.map((item, index) => (
        <div className="blog-post" key={index}>
          <img src={item?.image} alt="Blog Post" className="blog-post-img" />
          <div>
            <h2 className="blog-post-title">{item?.name}</h2>
            <p className="blog-post-content">{item?.description}</p>
          </div>
        </div>
      ));
    } else if (selectedTab === "trending") {
      return trendingBlogs.map((item, index) => (
        <div className="blog-post" key={index}>
          <img  src={item?.image} alt="Blog Post" className="blog-post-img" />
          <div>
            <h2 className="blog-post-title">{item?.name}</h2>
            <p className="blog-post-content">{item?.description}</p>
          </div>
        </div>
      ));
    }
  };

  return isMobile ? (
    <MobileBlogPage />
  ) : (
    <div className="blog-desktop">
      <Navbardesktop/>
      <div className="blog-content">
        <div className="blog-section">
          <div className="blog-nav">
            <ul>
              <li
                className={selectedTab === "newest" ? "selected" : ""}
                onClick={() => handleTabClick("newest")}
              >
                Newest
              </li>
              <li
                className={selectedTab === "trending" ? "selected" : ""}
                onClick={() => handleTabClick("trending")}
              >
                Trending
              </li>
            </ul>
          </div>
        </div>

        <div className="blog-posts">
          {renderBlogs()}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default BlogPage;
