import React, { useState, useEffect } from "react";
import "./PersonalDetailPage.css";
import icevan from "../../images/Desktop-icons/ice-cream-van.svg";
import Navbardesktop from "../../components/Web/Navbar/Navbardesktop,";
import MobPersonalSign from "../../components/Mob/MobLogin/MobPersonalSign";
import { isMobile } from "react-device-detect";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const baseUrl = process.env.REACT_APP_BASEURL;

const PersonalDetailPage = () => {
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  const navigate = useNavigate();
  useEffect(() => {
    const isFormComplete = firstName && lastName && birthday;
    setIsButtonActive(isFormComplete);
  }, [firstName, lastName, birthday]);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  console.log(userData, "userData");
  const handleSubmit = async () => {
    try {
      if (storedData) {
        console.log("Retrieved user data:", userData);
        const response = await axios.post(`${baseUrl}/api/user/`, {
          email: userData.email,
          mobile: userData.mobile,
          password: userData.password,
          firstname: firstName,
          middlename: middleName,
          lastname: lastName,
          dateofbirth: birthday,
        });
        toast.success("Signup successful!", {
  className: "custom-toast",
});
        navigate("/login");
      } else {
        console.log("No user data found in localStorage");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    try {
     
      const response = await axios.post(`${baseUrl}/api/user/login`, {
        email: email,
        password: password,
      });
      const userData = response.data;
      localStorage.setItem("userData", JSON.stringify(userData));
      if(response.data){
        toast.success(response.data.message);
        
      }
      setIsPopupOpen(true);
        } catch (error) {
      if (error.response && error.response.data) {
        toast.error
        (error.response.data.message, {
  className: "custom-toast",
});
      } else {
        toast.error
       ('An unexpected error occurred', {
  className: "custom-toast",
});
      }
    }
  };
  return isMobile ? (
    <MobPersonalSign />
  ) : (
    <div className="personal-detail-main">
      <Navbardesktop />
      <div className="personal-detail-head"></div>
      <div className="personal-detail-steps">
        <div className="personal-detail-step personal-detail-step-1">1</div>
        <div className="personal-detail-step personal-detail-step-2">2</div>
        <div className="personal-detail-step personal-detail-step-3">3</div>
        <div className="personal-detail-line personal-detail-line-1"></div>
        <div className="personal-detail-animated-line"></div>
        <img
          src={icevan}
          alt="Ice Cream Van"
          className="personal-detail-ice-van"
        />
      </div>
      <div className="personal-detail-content">
        <h2 className="personal-detail-content-head">
          Almost Done, Complete Your Profile
        </h2>
        <div className="personal-detail-fields-container">
          <div className="personal-detail-field">
            <p>First Name</p>
            <input
              type="text"
              placeholder="Enter your First Name*"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="personal-detail-field">
            <p>Middle Name</p>
            <input
              type="text"
              placeholder="Enter your Middle Name"
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
            />
          </div>
          <div className="personal-detail-field">
            <p>Last Name</p>
            <input
              type="text"
              placeholder="Enter your Last Name*"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="personal-detail-field">
            <p>Birthday</p>
            <input
              type="date"
              placeholder="Select your Birthday"
              value={birthday}
              onChange={(e) => setBirthday(e.target.value)}
            />
          </div>
        </div>
        <div className="personal-detail-fields-buttons">
          <button
            className="finish-sign-up-button"
            disabled={!isButtonActive}
            onClick={handleSubmit}
          >
            Finish Sign Up
          </button>
        </div>
      </div>
      {isPopupOpen && (
       <div className="login-popup-container">
         <div className="login-popup-box">
           <button className="close-button" onClick={closePopup}>
             close
           </button>
           <div className="login-header">
             <h1>WELCOME</h1>
           </div>
           <form className="login-form" >
             <div className="login-field">
               <label htmlFor="email">Email Address</label>
               <input
                 type="email"
                 id="email"
                 placeholder="Enter Email"
                 name="email"
                 value={email}
                 onChange={(e) => setEmail(e.target.value)}
                 required
               />
             </div>
             <div className="login-field">
               <label htmlFor="password">Password</label>
               <input
                 type="password"
                 value={password}
                 onChange={(e) => setPassword(e.target.value)}
                 placeholder="Enter Password"
                 id="password"
                 name="password"
                 required
               />
             </div>
             <div className="login-actions">
               <button type="submit" className="login-button" onClick={handlesubmit}>
                 Log In
               </button>
             </div>
             <div className="mob-login-notsign-field">
               <p>
                 Don't have an account? <span>SignUp</span>
               </p>
             </div>
           </form>
         </div>
       </div>
     )}
    </div>
  );
};

export default PersonalDetailPage;
