import React, { useEffect, useState } from "react";
import Footer from "../../components/Web/Footer/Footer";
import "./Terms.css";
import axios from "axios";
import { isMobile } from "react-device-detect";
import MobTerms from "../../components/Mob/MobQuickLinks/MobTerms";
import Navbardesktop from "../../components/Web/Navbar/Navbardesktop,";

const baseUrl = process.env.REACT_APP_BASEURL;

const Terms = () => {
  const [data, setData] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState([]);

  const fetchDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/blog/allonterms`);
      setData(response.data);
      setExpandedDescriptions(Array(response.data.length).fill(false));
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const toggleDescription = (index) => {
    const updatedExpanded = expandedDescriptions.map((item, i) =>
      i === index ? !item : item
    );
    setExpandedDescriptions(updatedExpanded);
  };

  const getExcerpt = (text, index) => {
    const maxChars = 300;
    const isExpanded = expandedDescriptions[index];
  
    return (
      <>
        {isExpanded ? text : `${text.slice(0, maxChars)}${text.length > maxChars ? '...' : ''}`}
        {text.length > maxChars && (
          <button
            className="terms-toggle-button-inline"
            onClick={() => toggleDescription(index)}
          >
            {isExpanded ? "Read Less" : "Read More"}
          </button>
        )}
      </>
    );
  };
  
  const renderImages = (images) => {
    if (images.length === 1) {
      return (
        <div className="terms-image-container single-image">
          <img src={images[0]} alt="term" className="terms-image-round" />
        </div>
      );
    } else if (images.length === 2) {
      return (
        <div className="terms-image-container double-image">
          {images.map((img, idx) => (
            <img key={idx} src={img} alt={`term-${idx}`} className="terms-image-side" />
          ))}
        </div>
      );
    }
    return null;
  };

  return isMobile ? (
    <MobTerms />
  ) : (
    <div>
      <Navbardesktop/>
      <div className="terms-main-section">
        <div className="terms-content">
          <h1 className="terms-heading">Terms and Conditions</h1>
          {data?.map((item, index) => (
            <div key={index}>
              <h2 className="terms-subheading">{item?.title}</h2>
              <p className="terms-text">{getExcerpt(item?.description, index)}</p>
              {item?.image && renderImages(item?.image)}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
