import React, { useState } from "react";
import "./MobPersonalSign.css";
import backIcon from "../../../images/Mobile-icons/Backbutton.svg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
const baseUrl = process.env.REACT_APP_BASEURL;

const MobPersonalSign = () => {
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [isButtonActive, setIsButtonActive] = useState(false);
  const navigate = useNavigate();
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  console.log(userData, "userData");
  const handleFormValidation = async () => {
    const isFormComplete = firstName && lastName && birthday;
    setIsButtonActive(isFormComplete);
    try {
      if (storedData) {
        console.log("Retrieved user data:", userData);
        const response = await axios.post(`${baseUrl}/api/user/`, {
          email: userData.email,
          mobile: userData.mobile,
          password: userData.password,
          firstname: firstName,
          middlename: middleName,
          lastname: lastName,
          dateofbirth: birthday,
        });
        toast.success("Signup successful!", {
          className: 'mob-toast ',
        });
        navigate("/");
      } else {
        console.log("No user data found in localStorage");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="mob-personal-sign-main">
      <div className="mob-signup-navbar">
        <img src={backIcon} alt="Back" className="mob-signup-back-icon" />
        <p className="mob-signup-title">Verification</p>
      </div>
      <div className="mob-personal-sign-head"></div>
      <div className="mob-personal-sign-steps">
        <div className="mob-personal-sign-step mob-personal-sign-step-1">1</div>
        <div className="mob-personal-sign-step mob-personal-sign-step-2">2</div>
        <div className="mob-personal-sign-step mob-personal-sign-step-3">3</div>
        <div className="mob-personal-sign-line mob-personal-sign-line-1"></div>
        <div className="mob-personal-sign-animated-line"></div>
      </div>
      <div className="mob-personal-sign-content">
        <h2 className="mob-personal-sign-content-head">Almost Done, Complete Your Profile</h2>
        <div className="mob-personal-sign-fields-container">
          <div className="mob-personal-sign-field">
            <p>First Name </p>
            <input
              type="text"
              placeholder="Enter your First Name*"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              onBlur={handleFormValidation}
            />
          </div>
          <div className="mob-personal-sign-field">
            <p>Middle Name </p>
            <input
              type="text"
              placeholder="Enter your Middle Name"
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
            />
          </div>
          <div className="mob-personal-sign-field">
            <p>Last Name</p>
            <input
              type="text"
              placeholder="Enter your Last Name*"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              onBlur={handleFormValidation}
            />
          </div>
          <div className="mob-personal-sign-field">
            <p>Birthday</p>
            <input
              type="date"
              placeholder="Select your Birthday"
              value={birthday}
              onChange={(e) => setBirthday(e.target.value)}
              onBlur={handleFormValidation}
            />
          </div>
        </div>
        <div className="mob-personal-sign-fields-buttons">
          <button
            className="mob-finish-sign-up-button"
            disabled={!isButtonActive}
          >
            Finish Sign Up
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobPersonalSign;
