import React, { useEffect, useState } from "react";
import "./MobMenupage.css";
import backbutton from "../../../images/Mobile-icons/Backbutton.svg";
import searchicon from "../../../images/Mobile-icons/search-lens.svg";
import carticon from "../../../images/Mobile-icons/cart-large-4.svg";
import dinein from "../../../images/Desktop-icons/dine-svgrepo.svg";
import takeAway from "../../../images/Desktop-icons/cup-take-away-.svg";
import MobPopupDine from "../MobPopDine/MobPopupDine"; 

import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const baseUrl = process.env.REACT_APP_BASEURL;

const MobMenupage = () => {
  const [orderType, setOrderType] = useState("Dinein");
  const [popupVisible, setPopupVisible] = useState(false);
  const [funtoppings, setFuntoppings] = useState([]);
  const [showPopup, setShowPopup] = useState(true);
  const navigate = useNavigate();
  const [searchstring, setSearchstring] = useState("");

  const [selectedVariation, setSelectedVariation] = useState(null);
  const [singleproduct, setsingleProducts] = useState("");
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [selectedToppings, setSelectedToppings] = useState([]);
  const [isSearchPageVisible, setIsSearchPageVisible] = useState(false);
  const [selectedSubcategory, setSelectedSubcategory] = useState("");

  const [selectedCategory, setSelectedCategory] = useState(0);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(0);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [funheading, setfunheading] = useState([]);
  const [variationheading, setvariationheading] = useState([]);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  console.log(userData?.user,'jjj');
  const userIdToCheck = userData?.user?._id; 

  const fetchSubCategories = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/all`);
      const categories = Array.isArray(response.data) ? response.data : [];
      setCategories(categories);
      if (categories.length > 0) {
        const selectedCategoryId = categories[0]._id;
        const responses = await axios.get(
          `${baseUrl}/api/subcategory/category/${selectedCategoryId}`
        );
        const subcategories = responses?.data || [];
        setSubcategories(subcategories);

        setSelectedCategory(selectedCategoryId);
        if (subcategories.length > 0) {
          setSelectedSubcategory(subcategories[0]._id);

          const responsedata = await axios.post(
            `${baseUrl}/api/products/category/${selectedCategoryId}`,
            {
              subcategory: subcategories[0]?._id,
              options: orderType
            }
          );

          setProducts(responsedata.data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {

    fetchSubCategories();
  }, [orderType]); // Include orderType as a dependency if it can change

  const handleCategoryClick = async (category) => {
    try {
      setSelectedCategory(category);

      // Fetch products by category
      const response = await axios.post(
        `${baseUrl}/api/products/category/${category}`,
        {
          subcategory: selectedSubcategory,
          options: orderType,
        }
      );
      setProducts(response.data);
      const productIds = products.map((product) => product._id);
      const userId = userData?.user?._id;
  

      const allresponses = await axios.get(
        `${baseUrl}/api/subcategory/category/${category}`
      );
      setSubcategories(allresponses?.data);

      // Fetch and set fun toppings
      const responses = await axios.get(
        `${baseUrl}/api/funtoppings/category/${category}`
      );
      setFuntoppings(responses?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubCategoryClick = async (index) => {
    setSelectedSubCategory(index);
    const response = await axios.post(
      `${baseUrl}/api/products/subcategory/${index}`,
      {
        options: orderType,
        category: selectedCategory,
      }
    );
    setProducts(response.data);
  };
  const handleAttributeChange = (event, attribute) => {
    // const { name, price } = attribute;

    // setSelectedAttributes((prev) => {
    //   const updatedAttributesMap = new Map(
    //     prev.map((attr) => [attr.name, attr.price])
    //   );

    //   console.log(
    //     "Map Before Update:",
    //     Array.from(updatedAttributesMap.entries())
    //   );

    //   if (event.target.checked) {
    //     updatedAttributesMap.set(name, price);
    //   } else {
    //     updatedAttributesMap.delete(name);
    //   }

    //   const updatedArray = Array.from(
    //     updatedAttributesMap,
    //     ([name, price]) => ({ name, price })
    //   );

    //   console.log("Array After Update:", updatedArray);

    //   return updatedArray;
    // });
    setSelectedAttributes(event);
  };
  const handleScoopChange = (event, variation) => {
    setSelectedVariation(variation);
    setSelectedAttributes([]);
  };
  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleOrderTypeSelect = (type) => {
    setOrderType(type);
    setShowPopup(false);
  };
  const handleAddButtonClick = async (id) => {
    
    setIsPopupVisible(true);
    const response = await axios.get(`${baseUrl}/api/products/on/${id}`);
    console.log(response.data,'data');
    setsingleProducts(response.data);
    const responses = await axios.get(
      `${baseUrl}/api/funtoppings/category/${selectedCategory}`
    );
    setFuntoppings(responses?.data);
    const variationresponse = await axios.get(
      `${baseUrl}/api/variation/allon/${selectedCategory}`
    );
    setvariationheading(variationresponse?.data);
    const funtoppingsresponse = await axios.get(
      `${baseUrl}/api/funtoppings/allontoppinsheading/${selectedCategory}`
    );
    setfunheading(funtoppingsresponse?.data);
  };
  const handleToppingChange = (event, item) => {
    if (event.target.checked) {
      setSelectedToppings([...selectedToppings, item]);
    } else {
      setSelectedToppings(
        selectedToppings.filter((topping) => topping !== item)
      );
    }
  };
  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };
  const handleAddClick = async (id) => {
    setPopupVisible(true);
    console.log(id, "id");
    const response = await axios.get(`${baseUrl}/api/products/on/${id}`);
    console.log(response.data);
    setsingleProducts(response.data);
    const responses = await axios.get(
      `${baseUrl}/api/funtoppings/category/${selectedCategory}`
    );
    setFuntoppings(responses?.data);
  };

  const handleAddtocart = async () => {
    // if (!selectedVariation) {
    //   alert("Please select variant");
    // }
    const totalPrice = getTotalPrice(singleproduct?.price);

    try {
      const selectedToppingIds = selectedToppings?.map(
        (topping) => topping._id
      );
      const response = await axios.post(`${baseUrl}/api/cart/`, {
        userId: userData?.user?._id,
        productId: singleproduct?._id,
        quantity: 1,
        productdetails: {
          price: singleproduct?.price,
          selectedvariation: selectedVariation,
          selectedAttributes: selectedAttributes,
          funtoppings: selectedToppingIds,
        },
        totalPrice: totalPrice,
      });
window.location.reload();
      const responses = await axios.post(
        `${baseUrl}/api/cart/products/${singleproduct?._id}`,
        {
          userId: userData?.user?._id,
          quantity: 1,
        }
      );
      const data = await axios.post(
        `${baseUrl}/api/products/subcategory/${selectedSubcategory}`,
        {
          options: orderType,
          category: selectedCategory,
        }
      );
      setPopupVisible(false);
    } catch (err) {}
  };

  const handleSearchIconClick = () => {
    setIsSearchPageVisible(true);
  };
const handleIncrement = async (itemId) => {
  try {
    const response = await axios.post(`${baseUrl}/api/cart/${itemId}/increment`, {
      userId: userIdToCheck, 
    });
    fetchSubCategories();
    if (response.data.success) {
      toast.success('Quantity increased successfully!');
    } else {
      toast.error('Failed to increase quantity');
    }
  } catch (error) {
    console.error('Error incrementing quantity:', error);
    toast.error('An error occurred while increasing quantity');
  }};

const handleDecrement = async (itemId) => {
  try {
    const response = await axios.post(`${baseUrl}/api/cart/${itemId}/decrement`, {
      userId: userIdToCheck, 
    });

    if (response.data.success) {
      toast.success('Quantity decreased successfully!');
      fetchSubCategories();
    } else {
      toast.error('Failed to decrease quantity');
    }
  } catch (error) {
    console.error('Error decrementing quantity:', error);
    toast.error('An error occurred while decreasing quantity');
  }};

  const handleCloseSearchPage = () => {
    setIsSearchPageVisible(false);
  };
  const getTotalPrice = (price) => {
    let totalPrice = selectedVariation ? selectedVariation?.price+price : price;
    let halfVariationPrice = selectedVariation
      ? selectedVariation.price / 2
      : 0;

    if (Object.keys(selectedAttributes).length > 0) {
      halfVariationPrice += selectedAttributes.price || 0;
      selectedToppings.forEach((topping) => {
        halfVariationPrice += parseInt(topping.price);
      });

      return halfVariationPrice;
    } else {
      selectedToppings.forEach((topping) => {
        totalPrice += topping.price;
      });
      return totalPrice;
    }
  };
  const toggleDescription = (index) => {
    setExpandedDescriptions((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const handleOrderTypeChange = async (type) => {
    setOrderType(type);
    try {
      const response = await axios.post(
        `${baseUrl}/api/products/subcategory/${selectedSubcategory}`,
        {
          options: orderType,
          category: selectedCategory,
        }
      );
      setProducts(response.data);
    } catch (error) {}
  };
  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSearchstring(value);
    console.log("Search string:", value);
    try {
      const response = await axios.post(`${baseUrl}/api/products/search/all`, {
        searchString: value,
      });
      setProducts(response.data);
    } catch (error) {
      console.error(
        "Search request error:",
        error.response?.data || error.message
      );
    }
  };
  return (
    
    <div className="mob-menu-page">
      <MobPopupDine
        showPopup={showPopup}
        // handlePopupClose={handlePopupClose}
        handleOrderTypeSelect={handleOrderTypeSelect}
      />
      <div className="mob-menu-navbar">
        <img src={backbutton} alt="Back" className="mob-back-button" onClick={() => navigate(-1)} />
        <h className="mob-menu-navhead">MENU</h>
        <div className="mob-menu-nav-icon">
          <img
            src={searchicon}
            alt="Search"
            className="mob-search-icon"
            onClick={handleSearchIconClick}
          />
          <img src={carticon} alt="Search" className="mob-search-icon" onClick={() => navigate("/cart")} />
        </div>
      </div>
      <div className="mob-sidebar">
        <div className="mob-main-categories">
          {categories?.map((item, index) => (
            <div
              key={index}
              className={`mob-main-category ${
                selectedCategory === item?._id ? "selected" : ""
              }`}
              onClick={() => handleCategoryClick(item?._id)}
            >
              <p>{item?.category}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="mob-menu-body">
        <div className="mob-menu-categories">
          <div className="mob-category-list">
            {subcategories?.map((subcategory, index) => (
              <div
                key={subcategory._id}
                className={`mob-category-item ${
                  selectedSubCategory === subcategory?._id ? "selected" : ""
                }`}
                onClick={() => handleSubCategoryClick(subcategory?._id)}
              >
                <img src={subcategory?.image} alt={subcategory?.subcategory} />
                <p>{subcategory?.subcategory}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="mob-menu-icecream-list">
  {products?.map((item, index) => {
    const isOutOfStock = item?.stock === "False" || item?.stock === 0;

    return (
      <div className={`mob-menu-icecream-item ${isOutOfStock ? "out-of-stock" : ""}`} key={index}>
        <div className="mob-menu-icecream-image-container">
          <img
            src={item?.image[0]}
            alt={`Ice Cream ${index + 1}`}
            className="mob-menu-icecream-image"
          />
        </div>
        <div className="mob-menu-icecream-details">
          <h2 className="mob-menu-icecream-name">{item?.productname}</h2>
          <p className="mob-menu-icecream-description">
            {expandedDescriptions[index]
              ? item?.description
              : `${item?.description.substring(0, 50)}...`}
          </p>
          <button
            className="mob-menu-toggle-description-button"
            onClick={() => toggleDescription(index)}
          >
            {expandedDescriptions[index] ? "See Less" : "See More"}
          </button>
          <div className="mob-menu-icecream-footer">
            <span className="mob-menu-icecream-price">${item?.price}</span>
            {isOutOfStock ? (
              <span className="mob-menu-out-of-stock-message">Out of Stock</span>
            ) : (
              item?.userIds?.find((user) => user.userId === userIdToCheck) ? (
                <div className="mob-menu-quan-controls">
                  <button
                    className="mob-menu-quan-button mob-menu-quan-minus"
                    onClick={() => handleDecrement(item?._id)}
                  >
                    -
                  </button>
                  <p className="mob-menu-quan-display">
                    {item.userIds.find((user) => user.userId === userIdToCheck)?.quantity}
                  </p>
                  <button
                    className="mob-menu-quan-button mob-menu-quan-plus"
                    onClick={() => handleIncrement(item?._id)}
                  >
                    +
                  </button>
                </div>
              ) : (
                <button
                  className="mob-menu-add-cart-button"
                  onClick={() => {
                    handleAddButtonClick(item?._id);
                  }}
                >
                  ADD
                </button>
              )
            )}
          </div>
        </div>
      </div>
    );
  })}
</div>



      </div>
      {isPopupVisible && (
  <div className="mob-menu-popup-overlay">
    <div className="mob-menu-popup-content">
      <div className="mob-menu-popup-header">
        <h3 className="mob-menu-popup-header-name"> {singleproduct?.productname}</h3>
        <button
          className="mob-menu-popup-close-button"
          onClick={handleClosePopup}
        >
          close
        </button>
      </div>
      <div className="mob-menu-popup-body">
          <h4  className="mob-menu-popup-body-head">
                  {singleproduct?.variationheading}
                  </h4>

        <div className="mob-menu-popup-variation-section">
          {singleproduct?.variations?.map((item, index) => (
            <div className="mob-custom-radio-main" key={index}>
              <p>{item?.variationname}</p>
              <div className="mob-custom-radio-sub">
                <p>+{item?.price}</p>
                <input
                  type="radio"
                  name="scoop"
                  value={item?.variationname}
                  onChange={(e) => handleScoopChange(e, item)}
                />
              </div>
            </div>
          ))}
        </div>
        {selectedVariation &&
                selectedVariation?.attributes?.length > 0 && (
        <div className="menu-pop-section">
          <h3 className="mob-menu-pop-option-head">Options</h3>
          {selectedVariation?.attributes?.map((attribute, attrIndex) => (
            <div key={attrIndex} className="menu-pop-option">
              <p>{attribute?.name}</p>
              <div className="menu-pop-option-right-check">
                <p>+{attribute?.price}</p>
                <input
                  name="attribute"
                  type="radio"
                  value={attrIndex}
                  onChange={(e) => handleAttributeChange(attribute)}
                />
              </div>
            </div>
          ))}
        </div>
                    )}
<div className="mob-menu-popup-body-head-sec">
          <h4 className="mob-menu-popup-body-head">
                {singleproduct?.funtoppinsheading}
                </h4>
                </div>

        <div className="mob-menu-popup-toppings-section">
          {singleproduct?.funtoppinsList?.map((item, index) => (
            <div className="mob-custom-checkbox-container" key={index}>
              <div className="mob-custom-checkbox-main">
              <img src={item?.image} className="mob-menu-popup-body-image"/>
                <p className="pmob-custom-checkbox-sub">{item?.name}</p>
                <div className="mob-custom-checkbox-sub">
                {item?.price==0?<>
                      <span>Free</span>
                      </>:<>
                    <span>+ ₹{item?.price}</span>
                    </>}                 <input
                    type="checkbox"
                    onChange={(e) => handleToppingChange(e, item)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mob-menu-popup-footer">
        <div className="mob-menu-popup-add-button" onClick={handleAddtocart}>
          <p>ADD TO CART</p>
          <span>{getTotalPrice()}</span>
        </div>
      </div>
    </div>
  </div>
)}


      {isSearchPageVisible && (
        <div className="mob-search-page">
          <div className="mob-search-header">
            <img
              src={backbutton}
              alt="Back"
              className="mob-back-button"
              onClick={handleCloseSearchPage}
            />
            <input
              type="text"
              className="mob-search-input"
              placeholder="Search..."
              value={searchstring}
              onChange={handleSearchChange}
            />
          </div>
        </div>
      )}
      {/* <div className="mob-menu-footer">
        <button
          className={`mob-menu-footer-button dine-in ${
            orderType === "Dinein" ? "selected" : ""
          }`}
          onClick={() => handleOrderTypeChange("Dinein")}
        >
          <img src={dinein} alt="Dine In" className="footer-button-icon" />
          Dine In
        </button>
        <button
          className={`mob-menu-footer-button dine-in ${
            orderType === "Takeaway" ? "selected" : ""
          }`}
          onClick={() => handleOrderTypeChange("Takeaway")}
        >
          <img src={takeAway} alt="Take Away" className="footer-button-icon" />
          Take Away
        </button>
      </div> */}
    </div>
  );
};

export default MobMenupage;
