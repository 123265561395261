import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginPage.css";
import userIcon from "../../images/Desktop-icons/icecream-login-icon.svg";
import helpcenterlogo from "../../images/Desktop-icons/help-center.svg";
import { isMobile } from "react-device-detect";
import MobLoginPage from "../../components/Mob/MobLogin/MobLoginPage";
import Navbardesktop from "../../components/Web/Navbar/Navbardesktop,";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASEURL;

const LoginPage = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (isPopupOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => document.body.classList.remove("no-scroll");
  }, [isPopupOpen]);

  const openPopup = () => {
    setIsPopupOpen(true);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 4000);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleSignupClick = () => {
    navigate("/signup");
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    try {
     
      const response = await axios.post(`${baseUrl}/api/user/login`, {
        email: email,
        password: password,
      });
      const userData = response.data;
      localStorage.setItem("userData", JSON.stringify(userData));
      if(response.data){
        toast.success(response.data.message, {
  className: "custom-toast",
});
        
      }
      navigate('/')
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error
        (error.response.data.message, {
  className: "custom-toast",
});
      } else {
        toast.error
       ('An unexpected error occurred', {
  className: "custom-toast",
});
      }
    }
  };
  return isMobile ? (
    <MobLoginPage />
  ) : (
    <div>
      <ToastContainer/>
      {showToast && 
      <div className="toast-message">Welcome Back</div>
      }
      <div className="navbar-shadow">
        <Navbardesktop />
      </div>
      <div className="login-page-container">
        <div className="login-content">
          <img src={userIcon} alt="User Icon" className="user-icon" />
          <p className="welcome-text">WELCOME TO MANNA DELIGHTS</p>
          <div className="login-buttons-main">
            <button className="login-signup-button" onClick={openPopup}>
              Login
            </button>
            <button className="login-signup-button" onClick={handleSignupClick}>
              Signup
            </button>
          </div>
        </div>
      </div>
      <div className="help-center-section">
        <img
          src={helpcenterlogo}
          alt="Help Center Icon"
          className="help-center-icon"
        />
        <a href="/help-center" className="help-center-link">
          Help Center
        </a>
      </div>

      {isPopupOpen && (
        <div className="login-popup-container">
          <div className="login-popup-box">
            <button className="close-button" onClick={closePopup}>
              close
            </button>
            <div className="login-header">
              <h1>WELCOME</h1>
            </div>
            <form className="login-form" >
              <div className="login-field">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  placeholder="Enter Email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="login-field">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter Password"
                  id="password"
                  name="password"
                  required
                />
              </div>
              <div className="login-actions">
                <button type="submit" className="login-button" onClick={handlesubmit}>
                  Log In
                </button>
              </div>
              <div className="mob-login-notsign-field">
                <p>
                  Don't have an account? <span>SignUp</span>
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
