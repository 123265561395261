import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./MobTraining.css";
import backbutton from "../../../images/Mobile-icons/Backbutton.svg";
import settingmob from "../../../images/Mobile-icons/settings-svgrepo.svg";
import menu from "../../../images/Desktop-icons/menu-svg.svg";
import aboutus from "../../../images/Desktop-icons/about-svgrepo-com.svg";
import blogs from "../../../images/Desktop-icons/blog.svg";
import contactus from "../../../images/Desktop-icons/contact-svg.svg";
import MobFooter from "../MobFooter/MobFooter";

const baseUrl = process.env.REACT_APP_BASEURL;
const PopupMenu = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
    onClose();
  };

  return (
    <div className={`mob-training-popup-menu ${isOpen ? "show" : ""}`}>
      <ul className="mob-training-pop-menu-navbar-main">
        <li onClick={() => handleNavigate("/")}>
          Home
          <img
            src={menu}
            className="mob-training-pop-menu-navbar-img"
            alt="Menu"
          />
        </li>
        <li onClick={() => handleNavigate("/about")}>
          About Us
          <img
            src={aboutus}
            className="mob-training-pop-menu-navbar-img"
            alt="About Us"
          />
        </li>
        <li onClick={() => handleNavigate("/blog")}>
          Blogs
          <img
            src={blogs}
            className="mob-training-pop-menu-navbar-img"
            alt="Blogs"
          />
        </li>
        <li onClick={() => handleNavigate("/contact")}>
          Contact Us
          <img
            src={contactus}
            className="mob-training-pop-menu-navbar-img"
            alt="Contact Us"
          />
        </li>
      </ul>
      <button onClick={onClose} className="mob-training-close-popup">
        Close
      </button>
    </div>
  );
};

const MobTraining = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [trainingData, setTrainingData] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const navigate = useNavigate();

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const fetchTrainingDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/blog/training`);
      setTrainingData(response.data);
    } catch (error) {
      console.error("Error fetching training data:", error);
    }
  };

  useEffect(() => {
    fetchTrainingDetails();
  }, []);

  const handleToggleDescription = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div className="mob-training-full-width-container">
      <div className="mob-training-navbar">
        <img
          className="mob-training-back-button"
          src={backbutton}
          alt="Back Button"
          onClick={handleBackButtonClick}
        />
        <div className="mob-training-page-title">Training</div>
        <img
          src={settingmob}
          alt="Settings"
          className="mob-training-logo"
          onClick={togglePopup}
        />
      </div>

      <PopupMenu isOpen={isPopupOpen} onClose={togglePopup} />

      <div className="mob-training-content">
        {trainingData.map((item, index) => (
          <div key={index}>
            <h4 className="mob-training-header">{item.title}</h4>
            <p className="mob-training-text">
              {expandedIndex === index
                ? item.description
                : item.description.substring(0, 100) + "..."}
              {item.description.length > 100 && (
                <span
                  className="mob-policy-toggle-button"
                  onClick={() => handleToggleDescription(index)}
                >
                  {expandedIndex === index ? "See Less" : "See More"}
                </span>
              )}
            </p>
            <div>
            {item?.image && (
              <img
                src={item?.image}
                alt="career"
                className="mob-career-image"
              />
            )}{" "}
          </div>
          </div>
        ))}
      </div>

      <div className="mob-training-footer">
        <MobFooter />
      </div>
    </div>
  );
};

export default MobTraining;
