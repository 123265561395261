import React from 'react'

const CheckoutSuccess = () => {
  return (
    <div>
      <h2>Checkout Success</h2>
    </div>
  )
}

export default CheckoutSuccess
