import React, { useEffect, useState } from "react";
import "./Careers.css";
import Footer from "../../components/Web/Footer/Footer";
import MobCareer from "../../components/Mob/MobQuickLinks/MobCareer";
import axios from "axios";
import { isMobile } from "react-device-detect";
import ReCAPTCHA from "react-google-recaptcha";
import { storage } from "../../Firebase/Config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Navbardesktop from "../../components/Web/Navbar/Navbardesktop,";

const baseUrl = process.env.REACT_APP_BASEURL;

const Careers = () => {
  const [data, setData] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    coverLetter: "",
    position: "",
    linkedinProfile: "",
    resume: null,
  });
  const [uploadProgress, setUploadProgress] = useState(0); // For showing upload progress

  const fetchDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/blog/alloncareer`);
      setData(response.data);
      setExpandedDescriptions(Array(response.data.length).fill(false));
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const toggleDescription = (index) => {
    const updatedExpanded = expandedDescriptions.map((item, i) =>
      i === index ? !item : false
    );
    setExpandedDescriptions(updatedExpanded);
  };

  const getExcerpt = (text) => {
    const maxChars = 300;
    return text.length > maxChars ? text.slice(0, maxChars) + "..." : text;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      resume: e.target.files[0],
    }));
  };

  const validateLinkedInURL = (url) => {
    const regex = /^https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/;
    return regex.test(url);
  };

  const validateName = (name) => {
    const regex = /^[a-zA-Z\s]+$/; 
    return regex.test(name);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateName(formData.name)) {
      toast.error("Name must contain only letters and spaces.", {
        position: "top-center",
        autoClose: 3000,
      });
      return;
    }

    if (!validateLinkedInURL(formData.linkedinProfile)) {
      toast.error("Please enter a valid LinkedIn profile URL.", {
        position: "top-center",
        autoClose: 3000,
      });
      return;
    }

    if (formData.resume) {
      // Check file size (in bytes)
      if (formData.resume.size > 3 * 1024 * 1024) { // 3MB
        toast.error("Resume size must be less than 3MB.", {
          position: "top-center",
          autoClose: 3000,
        });
        return;
      }

      const storageRef = ref(storage, `resumes/${formData.resume.name}`);
      const uploadTask = uploadBytesResumable(storageRef, formData.resume);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.error("Upload failed:", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at:", downloadURL);

            const submissionData = {
              ...formData,
              resumeUrl: downloadURL,
            };

            axios.post(`${baseUrl}/api/blog/submit`, submissionData)
              .then(response => {
                console.log("Form data submitted:", response.data);
                toast.success("Application submitted successfully!", {
                  position: "top-center",
                  autoClose: 3000,
                });
                setFormData({
                  name: "",
                  coverLetter: "",
                  position: "",
                  linkedinProfile: "",
                  resume: null,
                });
                setShowModal(false);
              })
              .catch(error => {
                console.error("Error submitting form:", error);
                toast.error("Error submitting application. Please try again.", {
                  position: "top-center",
                  autoClose: 3000,
                });
              });
          });
        }
      );
    }
  };

  return (
    <div>
      <ToastContainer />
      {isMobile ? (
        <MobCareer />
      ) : (
        <div>
          <Navbardesktop/>
          <p className="career-content-h1">Be a part of something Great</p>
          {data?.map((item, index) => (
            <div className="career-main-section" key={index}>
              <div className="career-content">
                <h1 className="career-heading">{item?.jobtitle}</h1>
                <p className="career-description">
                  {expandedDescriptions[index] ? item?.description : (
                    <span>
                      {getExcerpt(item?.description)}
                      {item?.description.length > 300 && (
                        <button className="career-toggle-button" onClick={() => toggleDescription(index)}>
                          See More
                        </button>
                      )}
                    </span>
                  )}
                </p>

                <div className="career-images">
                  {item?.image && (
                    <img 
                      src={item?.image}
                      alt="career"
                      className="career-image"
                    />
                  )}
                </div>

                {expandedDescriptions[index] && (
                  <button className="career-toggle-button" onClick={() => toggleDescription(index)}>
                    See Less
                  </button>
                )}

                <p className="career-email">
                  Contact us at:{" "}
                  <a href="mailto:careers@mannadelights.com">careers@mannadelights.com</a>
                </p>
                <button className="career-apply-button" onClick={() => setShowModal(true)}>Apply Now</button>
              </div>
            </div>
          ))}

          {showModal && (
            <div className="pop-career-modal">
              <div className="pop-career-content">
                <h2 className="pop-career-content-head">Apply for Manna Delight</h2>
                <form onSubmit={handleSubmit}>
                  <div>
                    <label>Name:</label>
                    <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                  </div>
                  <div>
                    <label>Cover Letter:</label>
                    <textarea name="coverLetter" value={formData.coverLetter} onChange={handleChange} required />
                  </div>
                  <div>
                    <label>Applying Position:</label>
                    <input type="text" name="position" value={formData.position} onChange={handleChange} required />
                  </div>
                  <div>
                    <label>LinkedIn Profile:</label>
                    <input type="url" name="linkedinProfile" value={formData.linkedinProfile} onChange={handleChange} required />
                  </div>
                  <div>
                    <label>Upload Resume/CV:</label>
                    <input type="file" onChange={handleFileChange} accept=".pdf,.doc,.docx" required />
                    <small style={{ color: "gray" }}>*Resume/CV size must be less than 3MB</small>
                  </div>
                  <div className="pop-career-buttonsec">
                    <button className="pop-career-buttonsubmit" type="submit">Submit Application</button>
                    <button className="pop-career-buttonclose" type="button" onClick={() => setShowModal(false)}>Close</button>
                  </div>
                </form>
              </div>
            </div>
          )}

          <Footer />
        </div>
      )}
    </div>
  );
};

export default Careers;
