import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import "./MobPolicy.css";
import backbutton from "../../../images/Mobile-icons/Backbutton.svg";
import settingmob from "../../../images/Mobile-icons/settings-svgrepo.svg";
import menu from "../../../images/Desktop-icons/menu-svg.svg";
import aboutus from "../../../images/Desktop-icons/about-svgrepo-com.svg";
import blogs from "../../../images/Desktop-icons/blog.svg";
import contactus from "../../../images/Desktop-icons/contact-svg.svg";
import MobFooter from "../MobFooter/MobFooter";
import axios from "axios";
const baseUrl = process.env.REACT_APP_BASEURL;

const PopupMenu = ({ isOpen, onClose }) => {
  const navigate = useNavigate(); 

  const handleNavigate = (path) => {
    navigate(path);
    onClose(); 
  };

  return (
    <div className={`mob-policy-popup-menu ${isOpen ? "show" : ""}`}>
      <ul className="mob-policy-pop-menu-navbar-main">
        <li onClick={() => handleNavigate("/")}>
          Home
          <img src={menu} className="mob-policy-pop-menu-navbar-img" alt="Menu" />
        </li>
        <li onClick={()=>{navigate("/about")}}        >
          About Us
          <img src={aboutus} className="mob-policy-pop-menu-navbar-img" alt="About Us" />
        </li>
        <li onClick={()=>{navigate("/blog")}}        >
          Blogs
          <img src={blogs} className="mob-policy-pop-menu-navbar-img" alt="Blogs" />
        </li>
        <li onClick={()=>{navigate("/contact")}}        >
          Contact Us
          <img src={contactus} className="mob-policy-pop-menu-navbar-img" alt="Contact Us" />
        </li>
      </ul>
      <button onClick={onClose} className="mob-policy-close-popup">
        Close
      </button>
    </div>
  );
};

const MobPolicy = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(null); // Track which item is expanded
  const [data, setData] = useState([]);
  const navigate = useNavigate(); 

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  const handleBackButtonClick = () => {
    navigate(-1); 
  };

  const fetchDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/blog/allonpolicy`);
      setData(response.data);
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index); // Toggle between expanded and collapsed
  };

  return (
    <div className="mob-policy-full-width-container">
      <div className="mob-policy-navbar">
        <img
          className="mob-policy-back-button"
          src={backbutton}
          alt="Back Button"
          onClick={handleBackButtonClick} 
        />
        <div className="mob-policy-page-title">Policy</div>
        <img
          src={settingmob}
          alt="Settings"
          className="mob-policy-logo"
          onClick={togglePopup}
        />
      </div>

      <PopupMenu isOpen={isPopupOpen} onClose={togglePopup} />

      <div className="mob-policy-content">
        <h2 className="mob-policy-header">Privacy Policy</h2>
        {data?.map((item, index) => (
          <div key={index}>
            <h3 className="mob-policy-subhead">{item?.title}</h3>
            <p className="mob-policy-text">
              {expandedIndex === index ? item?.description : `${item?.description.substring(0, 100)}...`}
              <button
                className="mob-policy-toggle-button"
                onClick={() => toggleExpand(index)}
              >
                {expandedIndex === index ? "See Less" : "See More"}
              </button>
            </p>
            <div>
            {item?.image && (
              <img
                src={item?.image}
                alt="career"
                className="mob-career-image"
              />
            )}{" "}
          </div>
          </div>
        ))}
      </div>

      <div className="mob-policy-footer">
        <MobFooter />
      </div>
    </div>
  );
};

export default MobPolicy;
