import React, { useEffect, useState } from "react";
import "./cart.css";
import icecream from "../../images/sweeny-home2-decor-img-02.png";
import whatsappicon from "../../images/whatsapp-svgrepo-com.svg";
import backbutton from "../../images/Mobile-icons/Backbutton.svg";
import sidemainlogo from "../../images/bottom-logo.svg";
import PaymentButton from "../../components/Web/Checkout/PaymentButton";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { isMobile } from "react-device-detect";
import MobCart from "../../components/Mob/Mobcart/MobCart";
import EmptyCartPage from "./EmptyCartPage";

const baseUrl = process.env.REACT_APP_BASEURL;

const Cart = () => {
  const [quantities, setQuantities] = useState(1);
  const [cart, setCart] = useState([]);
  const [sumTotalPrice, setsumTotalPrice] = useState("");

  console.log(cart, "cart");
  const [orderType, setOrderType] = useState("dine-in");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [preparationInstructions, setPreparationInstructions] = useState("");
  const [isShopClosed, setIsShopClosed] = useState(false);
  const navigate = useNavigate();
  const takeawayFee = 50;
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  console.log(userData?.user, "jjj");
  useEffect(() => {
    const checkShopStatus = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/time/allon`);
        console.log(response.data[0]);

        if (response.data && response.data[0]) {
          const { opentime, closingtime } = response.data[0];

          const currentTime = new Date();
          const currentHour = currentTime.getHours();

          if (currentHour < opentime || currentHour >= closingtime) {
            setIsShopClosed(true);
          } else {
            setIsShopClosed(false);
          }
        } else {
          setIsShopClosed(false);
        }
      } catch (error) {
        console.error("Error fetching shop hours:", error);
        setIsShopClosed(true);
      }
    };

    checkShopStatus();
    const interval = setInterval(checkShopStatus, 60000);

    return () => clearInterval(interval);
  }, [baseUrl]);

  const increaseQuantity = async (index, id) => {
    let value = index + 1;
    console.log(value, "value", index);
    setQuantities(value);
    const responses = await axios.put(`${baseUrl}/api/cart/increase-quantity`, {
      userId: userData?.user?._id,
      productId: id,
      quantityToAdd: value - index,
    });
    const response = await axios.put(
      `${baseUrl}/api/cart/total/${userData?.user?._id}`
    );
    fetchSubCategories();
  };

  const decreaseQuantity = async (index, id) => {
    let value = index - 1;
    setQuantities(value);
    const responses = await axios.put(`${baseUrl}/api/cart/increase-quantity`, {
      userId: userData?.user?._id,
      productId: id,
      quantityToAdd: value - index,
    });
    const response = await axios.put(
      `${baseUrl}/api/cart/total/${userData?.user?._id}`
    );

    fetchSubCategories();
  };

  const handleOrderTypeChange = (type) => {
    setOrderType(type);
  };

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleSaveInstructions = () => {
    console.log("Instructions saved:", preparationInstructions);
    setIsPopupOpen(false);
  };

  let relatedProducts ;
  const cartitems = {
    price: "100",
    quantity: 1,
  };
  const fetchSubCategories = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/cart/all/${userData?.user?._id}`);
      setCart(Array.isArray(response.data) ? response.data : []);
      console.log(response.data[0].items,'hh');
      const items = response.data[0].items;
const sumTotalPrice = items.reduce((sum, item) => sum + item.total, 0);
setsumTotalPrice(sumTotalPrice)
console.log(sumTotalPrice, 'Total Price');

      relatedProducts=response.data[0].items;
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  useEffect(() => {
    fetchSubCategories();
  }, []);
  const handleCheckout = async () => {
    try {
      const response = await axios.post(`${baseUrl}/api/order/`, {
        userId: userData?.user?._id,
        items: cart[0]?.items,
        totalPrice: sumTotalPrice,
        contactNumber: userData?.user?.phonenumber,
      });
      if (response?.data) {
        const response = await axios.delete(
          `${baseUrl}/api/cart/items/${userData?.user?._id}`
        );
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleRemove = async (id) => {
    console.log(id, "id");
    const response = await axios.delete(
      `${baseUrl}/api/cart/${userData?.user?._id}/item/${id}`
    );
    const responses = await axios.post(`${baseUrl}/api/cart/remove/${id}`, {
      userId: userData?.user?._id,
    });
    fetchSubCategories();
  };
 const handleclick=()=>{
  navigate(-1)
  
 }
  return isMobile ? (
    <>
      <MobCart />
    </>
  ) : (
    <>
      {cart?.length === 0 || cart[0]?.items?.length === 0 ? (
        <>
          <EmptyCartPage />
        </>
      ) : (
        <>
          <div
            className={`cart-full-width-container ${
              isShopClosed ? "cart-closed" : ""
            }`}
          >
            <div className="cart-navbar" 
                      onClick={() => navigate(-1)}

            >
              <img
                src={sidemainlogo}
                className="sidemainlogo"
                alt="Side Logo"
              />
              <img
                className="cart-back-button"
                src={backbutton}
                alt="Back Button"
                onClick={() => navigate(-1)}
                />
              <div className="cart-page-title">Cart</div>
            </div>
            {isShopClosed && (
              <div className="shop-closed-overlay">
                <div className="shop-closed-container">
                  <h2 className="shop-closed-title">
                    Our shop is currently closed
                  </h2>
                  <div class="shop-container">
                    <div class="shop-closed-header">
                      <div class="shop-closed-header__buttons shop-closed-header__button-one"></div>
                      <div class="shop-closed-header__buttons shop-closed-header__button-two"></div>
                      <div class="shop-closed-header__display"></div>
                      <div class="shop-closed-header__details"></div>
                    </div>
                    <div class="shop-closed-medium">
                      <div class="shop-closed-medium__exit"></div>
                      <div class="shop-closed-medium__arm"></div>
                      <div class="shop-closed-medium__liquid"></div>
                      <div class="shop-closed-medium__smoke shop-closed-medium__smoke-one"></div>
                      <div class="shop-closed-medium__smoke shop-closed-medium__smoke-two"></div>
                      <div class="shop-closed-medium__smoke shop-closed-medium__smoke-three"></div>
                      <div class="shop-closed-medium__smoke shop-closed-medium__smoke-for"></div>
                      <div class="shop-closed-medium__cup"></div>
                    </div>
                    <div class="shop-closed-footer"></div>
                  </div>

                  <p className="shop-closed-message">
                    We're sorry for the inconvenience. Please visit us again
                    later.
                  </p>
                  <button
                    className="cart-go-back-button"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Go Back
                  </button>
                </div>
              </div>
            )}
            <div
              className={`cart-item-container ${isShopClosed ? "blurred" : ""}`}
            >
              <div className="cart-item-firstboxcontainer">
                <div className="cart-secondbox">
                  <div className="cart-item-details">
                    <img
                      src={whatsappicon}
                      className="cart-whatsappicon"
                      alt="WhatsApp Icon"
                    />
                    <span className="cart-whatsapp-text">
                       (We'll send order updates on this number)
                    </span>
                  </div>

                  <hr className="cart-grey-line" />
                  <p className="cart-items-in-cart">Items in cart</p>
                  {cart?.map((item, index) => (
                    <>
                      <div key={index}>
                        {item?.items?.map((products, index) => (
                          <>
                            <div className="cart-allitemsdiv">
                              <>
                                <img
                                  src={products?.productId?.image[0]}
                                  className="cart-product-image"
                                  alt="Ice Cream"
                                />
                                <div className="cart-product-details">
                                  <p className="cart-product-details-name">
                                    {products?.productId?.productname}
                                  </p>
                                  <p className="cart-item-description">
                                    Small scoope
                                  </p>
                                  <p className="cart-item-price">
                                    ₹{products?.productId?.price}
                                  </p>
                                </div>

                                <div className="cart-quantity-control">
                                  <button
                                    className="cart-quantity-button"
                                    onClick={() =>
                                      decreaseQuantity(
                                        products?.quantity,
                                        products?.productId?._id
                                      )
                                    }
                                  >
                                    -
                                  </button>
                                  <p className="cart-quantity-display">
                                    {products?.quantity}
                                  </p>
                                  <button
                                    className="cart-quantity-button"
                                    onClick={() =>
                                      increaseQuantity(
                                        products?.quantity,
                                        products?.productId?._id
                                      )
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                              </>
                            </div>
                            <div className="cart-preparation-instructions">
                              <button
                                className="cart-delete-button"
                                onClick={() => {
                                  handleRemove(products?.productId?._id);
                                }}
                              >
                                Remove
                              </button>
                              <button
                                className="cart-preparation-button"
                                onClick={handleOpenPopup}
                              >
                                Add Preparation Instructions +
                              </button>
                            </div>
                          </>
                        ))}
                      </div>
                    </>
                  ))}
                </div>
                {/* <div className="cart-ordertype-and-related-container">
            <div className="cart-ordertypebox">
              <p className="cart-ordertype-title">Dine In or Take Away</p>
              <div className="cart-ordertype-options">
                <button
                  className={`cart-order-button ${
                    orderType === "dine-in" ? "active" : ""
                  }`}
                  onClick={() => handleOrderTypeChange("dine-in")}
                >
                  Dine In
                </button>
                <button
                  className={`cart-order-button ${
                    orderType === "take-away" ? "active" : ""
                  }`}
                  onClick={() => handleOrderTypeChange("take-away")}
                >
                  Take Away
                </button>
              </div>
            </div>
            <div className="cart-related-products">
              <p className="cart-related-products-title">Related Products</p>
              <div className="cart-related-products-list">
                {relatedProducts?.map((product) => (
                  <div key={product.id} className="cart-related-product-item">
                    <div className="cart-related-product-details">
                      <img
                        src={product.image}
                        className="cart-related-product-image"
                        alt={product.name}
                      />
                      <div className="cart-related-product-details-sub">
                        <p>{product.name}</p>
                        <p className="cart-related-product-price">
                          {product.price}
                        </p>
                      </div>
                    </div>

                    <button className="cart-related-product-add-button">
                      Add
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div> */}
              </div>
              <div className="cart-thirdbox">
                <p className="cart-billdetatilstext">Bill details</p>
                <div className="cart-thirdbox-item-details">
                  <span>Item total</span>
                  <span>₹{sumTotalPrice}</span>
                </div>
                <div className="cart-thirdbox-item-details">
                  <span>CGST</span>
                  <span>₹95</span>
                </div>
                <div className="cart-thirdbox-item-details">
                  <span>SGST</span>
                  <span>₹100</span>
                </div>
                <div className="cart-thirdbox-item-details">
                  <span>Delivery charge</span>
                  <span>₹100</span>
                </div>
                {orderType === "take-away" && (
                  <div className="cart-thirdbox-item-details">
                    <span>Takeaway Fee</span>
                    <span>₹{takeawayFee}</span>
                  </div>
                )}
                <p className="cart-topaytext">To Pay</p>
                <PaymentButton
                  cartItem={cart}
                  Total={sumTotalPrice}
                  relatedProducts={relatedProducts}
                  className="cart-addaddressandpaybutton"
                  onClick={handleCheckout}
                />
                {/* <button className="cart-addaddressandpaybutton">CONFIRM & PAY</button> */}
              </div>
            </div>
            {isPopupOpen && (
              <div className="popup-overlay">
                <div className="popup-content">
                  <h2 className="popup-pre-head">Preparation Instructions</h2>
                  <textarea
                    className="popup-textarea"
                    value={preparationInstructions}
                    onChange={(e) => setPreparationInstructions(e.target.value)}
                    placeholder="Enter your instructions here"
                  ></textarea>
                  <div className="popup-buttons">
                    <button
                      className="popup-button skip-button"
                      onClick={handleClosePopup}
                    >
                      Skip
                    </button>
                    <button
                      className="popup-button add-button"
                      onClick={handleSaveInstructions}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Cart;
