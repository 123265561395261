import React from 'react';
import './MobPopupDine.css';

const MobPopupDine = ({ showPopup, 
  // handlePopupClose,
 handleOrderTypeSelect }) => {
  if (!showPopup) return null;

  return (
    <div className="mob-dine-popup-overlay" 
    // onClick={handlePopupClose}
    >
      <div className="mob-dine-popup" onClick={(e) => e.stopPropagation()}>
        <h3 className="mob-dine-popup-header">HOW WOULD YOU LIKE TO RECEIVE YOUR ORDER</h3>
        <div className="mob-dine-popup-options">
          <button className="mob-dine-popup-button" onClick={() => handleOrderTypeSelect('Dinein')}>Dine In</button>
          <button className="mob-dine-popup-button" onClick={() => handleOrderTypeSelect('Takeaway')}>Take Away</button>
        </div>
        {/* <button className="mob-dine-popup-close"
        //  onClick={handlePopupClose}
         >Close</button> */}
      </div>
    </div>
  );
};

export default MobPopupDine;
