import React, { useState } from "react";
import "./OtpSignupPage.css";
import icevan from "../../images/Desktop-icons/ice-cream-van.svg";
import Navbardesktop from "../../components/Web/Navbar/Navbardesktop,";
import { isMobile } from "react-device-detect";
import MobOtpLoginPage from "../../components/Mob/MobLogin/MobOtpLoginPage";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASEURL;

const OtpSignupPage = () => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const navigate = useNavigate();
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  console.log(userData, "userData");

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleKeyUp = (e, index) => {
    if (e.keyCode === 8 && e.target.previousSibling) {
      e.target.previousSibling.focus();
    }
  };

  const isOtpComplete = otp.every((digit) => digit !== "");
  const handlesubmit = async () => {
    const otparray = otp.join("");
    try {
      const response = await axios.post(`${baseUrl}/api/otp/verify`, {
        email: userData?.email,
        otp: otparray,
        // password: formValues.password,
      });
      console.log(response.data.message);
      toast.success(response.data.message, {
  className: "custom-toast",
});
      navigate("/signup/otppage/personal");
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message, {
  className: "custom-toast",
});
      } else {
        toast.error("An unexpected error occurred", {
  className: "custom-toast",
});
      }
    }
  };
  return isMobile ? (
    <MobOtpLoginPage />
  ) : (
    <div>
      <ToastContainer />
      <Navbardesktop />
      <div className="otp-sign-main">
        <div className="otp-sign-main-head"></div>
        <div className="otp-sign-main-body">
          <div className="otp-sign-main-steps">
            <div className="otp-sign-main-step otp-sign-main-step-1">1</div>
            <div className="otp-sign-main-step otp-sign-main-step-2">2</div>
            <div className="otp-sign-main-step otp-sign-main-step-3">3</div>
            <div className="otp-sign-main-line"></div>
            <img
              src={icevan}
              alt="Ice Cream Van"
              className="otp-sign-main-ice-van"
            />
          </div>
        </div>
        <div className="otp-sign-main-second-section">
          <div className="otp-sign-main-otp-section">
            <h4 className="otp-sign-main-otp-section-head">
            OTP sent to your registered contact information.
            </h4>
            <h5 className="otp-sign-main-otp-section-p">
              Please enter the OTP you have received on your registered contact information.
            </h5>
          </div>
          <div className="otp-sign-main-otp-inputs">
            <p className="otp-sign-main-otp-head">Enter the OTP</p>
            <div className="otp-sign-main-otp-columns">
              {otp?.map((data, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  className="otp-input"
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onKeyUp={(e) => handleKeyUp(e, index)}
                />
              ))}
            </div>
            <div className="otp-sign-main-otp-resend">
              <p className="otp-sign-main-otp-resend-p">
                Didn't receive an OTP? <a href="#">Resend</a>
              </p>
            </div>
            <button
              className="otp-submit-button"
              disabled={!isOtpComplete}
              onClick={handlesubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpSignupPage;
