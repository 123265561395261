import React, { useState } from 'react';
import './MobErrorPage.css';
import errorImage from '../../../images/Desktop-icons/error.png';
import settingmob from '../../../images/Mobile-icons/settings-svgrepo.svg';
import backbutton from '../../../images/Mobile-icons/Backbutton.svg';
import menu from '../../../images/Desktop-icons/menu-svg.svg';
import aboutus from '../../../images/Desktop-icons/about-svgrepo-com.svg';
import blogs from '../../../images/Desktop-icons/blog.svg';
import contactus from '../../../images/Desktop-icons/contact-svg.svg';
import { useNavigate } from "react-router-dom";

const PopupMenu = ({ isOpen, onClose }) => {
  const navigate = useNavigate(); 

  const handleNavigate = (path) => {
    navigate(path); 
    onClose();       
  };

  return (
    <div className={`mob-training-popup-menu ${isOpen ? "show" : ""}`}>
      <ul className="mob-training-pop-menu-navbar-main">
        <li onClick={() => handleNavigate("/")}>
          Home
          <img src={menu} className="mob-training-pop-menu-navbar-img" alt="Menu" />
        </li>
        <li onClick={() => handleNavigate("/about")}>
          About Us
          <img src={aboutus} className="mob-training-pop-menu-navbar-img" alt="About Us" />
        </li>
        <li onClick={() => handleNavigate("/blog")}>
          Blogs
          <img src={blogs} className="mob-training-pop-menu-navbar-img" alt="Blogs" />
        </li>
        <li onClick={() => handleNavigate("/contact")}>
          Contact Us
          <img src={contactus} className="mob-training-pop-menu-navbar-img" alt="Contact Us" />
        </li>
      </ul>
      <button onClick={onClose} className="mob-training-close-popup">
        Close
      </button>
    </div>
  );
};

const MobErrorPage = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const navigate = useNavigate();

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  return (
    <div className="mob-error-page">
      <div className="mob-order-navbar">
        <img src={backbutton} alt="Back" className="mob-back-button" onClick={() => navigate(-1)} />
        {/* <div className="mob-order-title">Error</div> */}
        <img src={settingmob} alt="Settings" className="mob-logo" onClick={togglePopup} />
      </div>
      <PopupMenu isOpen={isPopupOpen} onClose={togglePopup} />
      <div className="mob-error-content">
        <h1>Oops! Page Not Found</h1>
        <p>The page you are looking for might have been removed or is temporarily unavailable.</p>
        <button onClick={() => window.location.href = '/'}>Back to Home</button>
      </div>
      <div className="mob-error-image">
        <img src={errorImage} alt="Ice Cream" />
      </div>
    </div>
  );
}

export default MobErrorPage;
