import React, { useEffect, useState } from "react";
import "./MobCart.css";
import icecream from "../../../images/sweeny-home2-decor-img-02.png";
import whatsappicon from "../../../images/whatsapp-svgrepo-com.svg";
import backbutton from "../../../images/Mobile-icons/Backbutton.svg";
import settingmob from "../../../images/Mobile-icons/settings-svgrepo.svg";
import menu from "../../../images/Desktop-icons/menu-svg.svg";
import aboutus from "../../../images/Desktop-icons/about-svgrepo-com.svg";
import blogs from "../../../images/Desktop-icons/blog.svg";
import contactus from "../../../images/Desktop-icons/contact-svg.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import MobEmptyCart from "./MobEmptyCart";
import PaymentButton from "../../Web/Checkout/PaymentButton";

const baseUrl = process.env.REACT_APP_BASEURL;

const PopupMenu = ({ isOpen, onClose }) => {
  const navigate = useNavigate(); 

  const handleNavigate = (path) => {
    navigate(path); 
    onClose();       
  };

  return (
    <div className={`mob-training-popup-menu ${isOpen ? "show" : ""}`}>
      <ul className="mob-training-pop-menu-navbar-main">
        <li onClick={() => handleNavigate("/")}>
          Home
          <img src={menu} className="mob-training-pop-menu-navbar-img" alt="Menu" />
        </li>
        <li onClick={() => handleNavigate("/about")}>
          About Us
          <img src={aboutus} className="mob-training-pop-menu-navbar-img" alt="About Us" />
        </li>
        <li onClick={() => handleNavigate("/blog")}>
          Blogs
          <img src={blogs} className="mob-training-pop-menu-navbar-img" alt="Blogs" />
        </li>
        <li onClick={() => handleNavigate("/contact")}>
          Contact Us
          <img src={contactus} className="mob-training-pop-menu-navbar-img" alt="Contact Us" />
        </li>
      </ul>
      <button onClick={onClose} className="mob-training-close-popup">
        Close
      </button>
    </div>
  );
};

const PreparationInstructionsPopup = ({
  isOpen,
  onClose,
  onSave,
  instructions,
  setInstructions,
}) => {
  if (!isOpen) return null;
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2 className="popup-pre-head">Preparation Instructions</h2>
        <textarea
          className="popup-textarea"
          value={instructions}
          onChange={(e) => setInstructions(e.target.value)}
          placeholder="Enter your instructions here"
        ></textarea>
        <div className="popup-buttons">
          <button className="popup-button skip-button" onClick={onClose}>
            Skip
          </button>
          <button className="popup-button add-button" onClick={onSave}>
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

const MobCart = () => {
  const [quantities, setQuantities] = useState([1, 1]);
  const [isMenuPopupOpen, setMenuPopupOpen] = useState(false);
  const [isInstructionsPopupOpen, setInstructionsPopupOpen] = useState(false);
  const [isShopClosed, setIsShopClosed] = useState(false);
  const [cart, setCart] = useState([]);
  const [orderType, setOrderType] = useState("dine-in");
  const [preparationInstructions, setPreparationInstructions] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const takeawayFee = 50;
  const [sumTotalPrice, setsumTotalPrice] = useState("");
  let relatedProducts ;
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  console.log(userData?.user,'jjj');
  const navigate = useNavigate();
  const cartitems = {
    price: "100",
    quantity: 1,
  };
  useEffect(() => {
    const checkShopStatus = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/time/allon`);
        console.log(response.data[0]);

        if (response.data && response.data[0]) {
          const { opentime, closingtime } = response.data[0];

          const currentTime = new Date();
          const currentHour = currentTime.getHours();

          if (currentHour < opentime || currentHour >= closingtime) {
            setIsShopClosed(true);
          } else {
            setIsShopClosed(false);
          }
        } else {
          setIsShopClosed(false);
        }
      } catch (error) {
        console.error("Error fetching shop hours:", error);
        setIsShopClosed(true);
      }
    };

    checkShopStatus();
    const interval = setInterval(checkShopStatus, 60000);
    return () => clearInterval(interval);
  }, [baseUrl]);

  const fetchSubCategories = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/cart/all/${userData?.user?._id}`);
      setCart(Array.isArray(response.data) ? response.data : []);
      const items = response.data[0].items;
const sumTotalPrice = items.reduce((sum, item) => sum + item.total, 0);
setsumTotalPrice(sumTotalPrice)
console.log(sumTotalPrice, 'Total Price');
relatedProducts=response.data[0].items;

    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchSubCategories();
  }, []);

  const increaseQuantity = async (index, id) => {
    let value = index + 1;
    console.log(value, "value", index);
    setQuantities(value);
    await axios.put(`${baseUrl}/api/cart/increase-quantity`, {
      userId: userData?.user?._id,
      productId: id,
      quantityToAdd: value - index,
    });
    await axios.put(`${baseUrl}/api/cart/total/${userData?.user?._id}`);
    fetchSubCategories();
  };

  const decreaseQuantity = async (index, id) => {
    let value = index - 1;
    setQuantities(value);
    await axios.put(`${baseUrl}/api/cart/increase-quantity`, {
      userId:userData?.user?._id,
      productId: id,
      quantityToAdd: value - index,
    });
    await axios.put(`${baseUrl}/api/cart/total/${userData?.user?._id}`);
    fetchSubCategories();
  };

  const toggleMenuPopup = () => {
    setMenuPopupOpen(!isMenuPopupOpen);
  };

  const handleOrderTypeChange = (type) => {
    setOrderType(type);
  };

  const handleCheckout = async () => {
    try {
      const response = await axios.post(`${baseUrl}/api/order/`, {
        userId:userData?.user?._id,
        items: cart[0]?.items,
        totalPrice: cart[0]?.totalPrice,
        contactNumber: userData?.user?.phonenumber,
      });
      if (response?.data) {
        await axios.delete(
          `${baseUrl}/api/cart/items/${userData?.user?._id}`
        );
        // navigate("/");
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemove = async (id) => {
    console.log(id, "id");
    await axios.delete(
      `${baseUrl}/api/cart/${userData?.user?._id}/item/${id}`
    );
    const responses = await axios.post(
      `${baseUrl}/api/cart/remove/${id}`,{
        userId:userData?.user?._id
      })
    fetchSubCategories();
  };

  const handleAddPreparationInstructions = (productId) => {
    setSelectedProduct(productId);
    setInstructionsPopupOpen(true);
  };

  const handleSaveInstructions = () => {
    console.log(
      `Save instructions for product ${selectedProduct}: ${preparationInstructions}`
    );
    setInstructionsPopupOpen(false);
    setPreparationInstructions("");
    setSelectedProduct(null);
  };

  const handleCloseInstructionsPopup = () => {
    setInstructionsPopupOpen(false);
    setPreparationInstructions("");
    setSelectedProduct(null);
  };

  return (
    <>
      {cart?.length === 0 || cart[0]?.items?.length === 0 ? (
        <MobEmptyCart />
      ) : (
        <div className="mob-cart-full-width-container">
          <div className="mob-cart-navbar">
            <img
              className="mob-cart-back-button"
              src={backbutton}
              onClick={() => navigate(-1)}
              alt="Back Button"
            />
            <div className="mob-cart-page-title">Cart</div>
            <img
              src={settingmob}
              alt="Settings"
              className="mob-cart-logo"
              onClick={toggleMenuPopup}
            />
          </div>

          <PopupMenu isOpen={isMenuPopupOpen} onClose={toggleMenuPopup} />

          {isShopClosed && (
            <div className="shop-closed-overlay">
              <div className="shop-closed-container">
                <h2 className="shop-closed-title">
                  Our shop is currently closed
                </h2>
                <div className="shop-container">
                  <div className="shop-closed-header">
                    <div className="shop-closed-header__buttons shop-closed-header__button-one"></div>
                    <div className="shop-closed-header__buttons shop-closed-header__button-two"></div>
                    <div className="shop-closed-header__display"></div>
                  </div>
                  <div className="shop-closed-medium">
                    <div className="shop-closed-medium__content">
                      <p>We are open from 10:00 AM to 8:00 PM</p>
                    </div>
                  </div>
                  <button
                    className="cart-go-back-button"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Go Back
                  </button>
                </div>
              </div>
            </div>
          )}

          <div
            className={`mob-cart-content-container ${
              isShopClosed ? "blurred" : ""
            }`}
          >
            {/* <div className="mob-cart-contact-info">
              <img
                src={whatsappicon}
                className="mob-cart-whatsapp-icon"
                alt="WhatsApp Icon"
              />
              <span className="mob-cart-whatsapp-icon-span">
999                <p className="mob-cart-whatsapp-icon-p">
                  (We'll send order updates on this number)
                </p>
              </span>
            </div> */}

            {/* <div className="mob-cart-ordertype-and-related-container">
              <div className="mob-cart-ordertypebox">
                <p className="mob-cart-ordertype-title">Dine In or Take Away</p>
                <div className="mob-cart-ordertype-options">
                  <button
                    className={`mob-cart-order-button ${
                      orderType === "dine-in" ? "active" : ""
                    }`}
                    onClick={() => handleOrderTypeChange("dine-in")}
                  >
                    Dine In
                  </button>
                  <button
                    className={`mob-cart-order-button ${
                      orderType === "take-away" ? "active" : ""
                    }`}
                    onClick={() => handleOrderTypeChange("take-away")}
                  >
                    Take Away
                  </button>
                </div>
              </div>
            </div> */}

            <div className="mob-cart-items-container">
              <h2>Items in cart</h2>
              {cart?.map((item, index) => (
                <div key={index}>
                  {item?.items?.map((products, index) => (
                    <React.Fragment key={index}>
                      <div className="mob-cart-item">
                        <img
                          src={products?.productId?.image[0]}
                          className="mob-cart-product-image"
                          alt="Ice Cream"
                        />
                        <div className="mob-cart-product-details">
                          <h3>Icecreams</h3>
                          <p className="mob-cart-item-description">
                            {products?.productId?.productname}
                          </p>
                          <p className="mob-cart-item-price">
                            ₹{products?.productId?.price}
                          </p>
                        </div>
                        <div className="mob-cart-quantity-control">
                          <button
                            className="mob-cart-quantity-button"
                            onClick={() =>
                              decreaseQuantity(
                                products?.quantity,
                                products?.productId?._id
                              )
                            }
                          >
                            -
                          </button>
                          <p className="mob-cart-quantity-display">
                            {products?.quantity}
                          </p>
                          <button
                            className="mob-cart-quantity-button"
                            onClick={() =>
                              increaseQuantity(
                                products?.quantity,
                                products?.productId?._id
                              )
                            }
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <div className="cart-preparation-instructions">
                        <button
                          className="cart-delete-button"
                          onClick={() => {
                            handleRemove(products?.productId?._id);
                          }}
                        >
                          Remove
                        </button>
                        <button
                          className="cart-preparation-button"
                          onClick={() =>
                            handleAddPreparationInstructions(
                              products?.productId?._id
                            )
                          }
                        >
                          Add Preparation Instructions +
                        </button>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              ))}
            </div>

            <div className="mob-cart-bill-details">
              <h2>Bill details</h2>
              <div className="mob-cart-item-row">
                <span>Item total</span>
                <span>₹{sumTotalPrice}</span>
              </div>
              <div className="mob-cart-item-row">
                <span>CGST</span>
                <span>₹9.40</span>
              </div>
              <div className="mob-cart-item-row">
                <span>SGST</span>
                <span>₹9.40</span>
              </div>
              <div className="mob-cart-item-row">
                <span>Delivery charge</span>
                <span>₹20</span>
              </div>
              {orderType === "take-away" && (
                <div className="mob-cart-item-row">
                  <span>Takeaway Fee</span>
                  <span>₹{takeawayFee}</span>
                </div>
              )}
              <div className="mob-cart-total-row">
                <span>To Pay</span>
                <span>₹{cart[0]?.totalPrice}</span>
              </div>
            </div>
          </div>

          <div className="mob-cart-footer">
            {/* <button className="mob-cart-checkout-button" onClick={handleCheckout}>
              Checkout
            </button> */}
             <PaymentButton
                  cartItem={cartitems}
                  Total={sumTotalPrice}
                  relatedProducts={relatedProducts}
                  className="mob-cart-checkout-button"
                  onClick={handleCheckout}
                />
          </div>

          <PreparationInstructionsPopup
            isOpen={isInstructionsPopupOpen}
            onClose={handleCloseInstructionsPopup}
            onSave={handleSaveInstructions}
            instructions={preparationInstructions}
            setInstructions={setPreparationInstructions}
          />
        </div>
      )}
    </>
  );
};

export default MobCart;
