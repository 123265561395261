import React, { useEffect, useState } from "react";
import Footer from "../../components/Web/Footer/Footer";
import "./Policy.css";
import axios from "axios";
import { isMobile } from "react-device-detect";
import MobPolicy from "../../components/Mob/MobQuickLinks/MobPolicy";
import Navbardesktop from "../../components/Web/Navbar/Navbardesktop,";

const baseUrl = process.env.REACT_APP_BASEURL;

const Policy = () => {
  const [data, setData] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState([]);

  const fetchDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/blog/allonpolicy`);
      setData(response.data);
      setExpandedDescriptions(Array(response.data.length).fill(false));
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const toggleDescription = (index) => {
    const updatedExpanded = expandedDescriptions.map((item, i) =>
      i === index ? !item : item
    );
    setExpandedDescriptions(updatedExpanded);
  };

  return isMobile ? (
    <MobPolicy />
  ) : (
    <div>
      <Navbardesktop />
      <div className="policy-main-section">
        <div className="policy-content">
          <h1 className="policy-heading">Our Policies</h1>
          {data?.map((item, index) => (
            <div key={index} className="policy-item">
              <h2 className="policy-subheading">{item?.title}</h2>
              <p className="policy-text">
                {expandedDescriptions[index] ? item?.description : item?.description.slice(0, 300) + (item?.description.length > 300 ? "..." : "")}
                {item?.description.length > 300 && (
                  <span className="toggle-container">
                    <button className="policy-toggle-button" onClick={() => toggleDescription(index)}>
                      {expandedDescriptions[index] ? "See Less" : "See More"}
                    </button>
                  </span>
                )}
              </p>

                <div
                  className="policy-images policy-images"
                >
                  {item?.image?
                  <>
                    <img
                    
                      src={item?.image}
                      alt="policy-image"
                      className="career-image"
                    />
                  </>:<>
                  </>
                 }
                </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Policy;
