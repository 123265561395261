import React, { useEffect, useState } from "react";
import axios from "axios";
import "./MobAboutUs.css";
import backbutton from "../../../images/Mobile-icons/Backbutton.svg";
import settingmob from "../../../images/Mobile-icons/settings-svgrepo.svg";
import menu from "../../../images/Desktop-icons/menu-svg.svg";
import aboutus from "../../../images/Desktop-icons/about-svgrepo-com.svg";
import blogs from "../../../images/Desktop-icons/blog.svg";
import contactus from "../../../images/Desktop-icons/contact-svg.svg";
import MobFooter from "../MobFooter/MobFooter";
import { useNavigate } from "react-router-dom";

const baseUrl = process.env.REACT_APP_BASEURL;

const MobAboutUs = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [blogData, setBlogData] = useState([]);
  const navigate = useNavigate();

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/blog/allonaboutus`);
        setBlogData(response.data); 
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="mob-about-page">
      <div className="mob-about-navbar">
        <img src={backbutton} alt="Back" className="mob-about-back-button" onClick={() => navigate(-1)} />
        <div className="mob-about-title">About Us</div>
        <img
          src={settingmob}
          alt="Menu"
          className="mob-about-menu-icon"
          onClick={togglePopup}
        />
      </div>

      <PopupMenu isOpen={isPopupOpen} onClose={togglePopup} />

      <section className="mob-about-hero-section">
        <div className="mob-about-hero-content">
          <h1>Welcome to Manna Delights</h1>
          <p>Discover the art of flavors in every bite.</p>
        </div>
      </section>

      <section className="mob-about-content-section">
        {blogData.map((item, index) => (
          <div className="mob-about-content-item" key={index}>
            <h2 className="mob-about-content-head">{item.title}</h2>
            <p className="mob-about-content-te">{item.description}</p>
            {/* <img src={item.imageUrl} alt={item.title} /> */}
          </div>
        ))}
      </section>

      <MobFooter />
    </div>
  );
};

const PopupMenu = ({ isOpen, onClose }) => {
  const navigate = useNavigate(); 

  const handleNavigate = (path) => {
    navigate(path); 
    onClose();       
  };

  return (
    <div className={`mob-training-popup-menu ${isOpen ? "show" : ""}`}>
      <ul className="mob-training-pop-menu-navbar-main">
        <li onClick={() => handleNavigate("/")}>
          Home
          <img src={menu} className="mob-training-pop-menu-navbar-img" alt="Menu" />
        </li>
        <li onClick={() => handleNavigate("/about")}>
          About Us
          <img src={aboutus} className="mob-training-pop-menu-navbar-img" alt="About Us" />
        </li>
        <li onClick={() => handleNavigate("/blog")}>
          Blogs
          <img src={blogs} className="mob-training-pop-menu-navbar-img" alt="Blogs" />
        </li>
        <li onClick={() => handleNavigate("/contact")}>
          Contact Us
          <img src={contactus} className="mob-training-pop-menu-navbar-img" alt="Contact Us" />
        </li>
      </ul>
      <button onClick={onClose} className="mob-training-close-popup">
        Close
      </button>
    </div>
  );
};

export default MobAboutUs;
