import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./NavbarDesktop.css";
import navmainlogo from "../../../images/bottom-logo.svg";
import usericon from "../../../images/Desktop-icons/user-icon.svg";
import iceicon from "../../../images/Desktop-icons/ice-cream-food-and-restaurant-usericon.svg";
import axios from "axios";
import { toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASEURL;

const Navbardesktop = () => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [newUserName, setNewUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [searchstring, setSearchstring] = useState("");
  const [products, setProducts] = useState([]);
const [data,setData]=useState({})
  const handleProfileClick = () => {
    setShowDropdown(false);
    setShowProfilePopup(true);
  };

  const closeProfilePopup = () => {
    setShowProfilePopup(false);
    setNewPassword("");
    setPasswordError("");
  };
  const fetchDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/user/singleprofile/${userData?.user?._id}`)
      setData(response.data);
    } catch (error) {
      console.error("Network error:", error);
      toast.error("Network error: Unable to fetch details");
    }
  };
useEffect(()=>{
  fetchDetails()
},[])
  const handlePasswordChange = (e) => {
    const newPasswordValue = e.target.value;
    setNewPassword(newPasswordValue);

    let errors = [];

    if (!/\d/.test(newPasswordValue)) {
      errors.push("1 Number");
    }

    if (!/[!@#$%^&*]/.test(newPasswordValue)) {
      errors.push("1 Special Char");
    }

    if (!/[A-Z]/.test(newPasswordValue)) {
      errors.push("1 Uppercase");
    }

    if (newPasswordValue.length < 8) {
      errors.push("8 Chars");
    }

    if (errors.length > 0) {
      setPasswordError(`Missing ${errors.join(", ")}`);
    } else {
      setPasswordError("");
    }
  };

  const handleSaveChanges = async () => {
    try {
      const response = await axios.put(`${baseUrl}/api/user/allprofile/${userData?.user?._id}`, {
        "firstname": newUserName,
        "lastname": newPassword,
        "middlename": newUserEmail,
      });
      setShowProfilePopup(false)
    } catch (error) {
    }
  };
  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSearchstring(value);
    console.log("Search string:", value);
    try {
      const response = await axios.post(`${baseUrl}/api/products/search/all`, {
        searchString: value
      });
      setProducts(response.data);
    } catch (error) {
      console.error("Search request error:", error.response?.data || error.message);
    }
  };
  const storedData = localStorage.getItem('userData');
    const userData = JSON.parse(storedData);
    const handlelogout=()=>{
      console.log('jjjjeeee');
      
     const item= localStorage.removeItem('userData');
    }
  return (
    <>
      <nav className="navbar">
        <div className="navbar-left">
          <img
            src={navmainlogo}
            alt="Logo"
            className="navbar-logo"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="navbar-center">
          <ul className="navbar-links">
            <li className="navbar-links-head">
              <p onClick={() => navigate("/")}>Home</p>
            </li>
            <li className="navbar-links-head">
              <p onClick={() => navigate("/menu")}>Menu</p>
            </li>
            <li className="navbar-links-head">
              <p onClick={() => navigate("/order")}>Order</p>
            </li>
            <li className="navbar-links-head">
              <p onClick={() => navigate("/blog")}>BLOG</p>
            </li>
            <li className="navbar-links-head">
              <a href="/contact">CONTACT</a>
            </li>
          </ul>
        </div>
        <div className="navbar-right">
          <input
            type="text"
            placeholder="Search"
            className="navbar-search"
            value={searchstring}
            onChange={handleSearchChange}
          />
          <div
            className="navbar-user"
            onMouseEnter={() => setShowDropdown(true)}
            onMouseLeave={() => setShowDropdown(false)}
          >
            <img className="navbar-user-icon" src={usericon} alt="user" />
            {showDropdown && (
              <div className="user-dropdown">
                <p className="user-dropdown-p" onClick={handleProfileClick}>
                  Profile
                </p>
                <p className="user-dropdown-p" onClick={() => navigate(-1)}>Orders</p>
                {/* <p className="user-dropdown-p">Notifications</p> */}
                <p className="user-dropdown-p" onClick={handlelogout}>Logout</p>
              </div>
            )}
          </div>
        </div>
      </nav>
      {showProfilePopup && (
        <div className="profile-popup-overlay" onClick={closeProfilePopup}>
          <div className="profile-popup" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeProfilePopup}>
              Skip
            </button>
            <div>
              <img src={iceicon} alt="user" className="profile-icon" />
            </div>
            <h2 className="profile-icon-head">Edit Profile</h2>
            <form className="profile-form">
              <label>
              Firstname:
                <input
                  type="text"
                  placeholder={data?.firstname}
                  value={newUserName}
                  onChange={(e) => setNewUserName(e.target.value)}
                />
              </label>
              <label>
              Middlename:
                <input
                  type="text"
                  placeholder={data?.middlename}

                  value={newUserEmail}
                  onChange={(e) => setNewUserEmail(e.target.value)}
                />
              </label>
              <label>
              Lastname:
                <input
                  type="text"
                  placeholder={data?.lastname}

                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </label>
            
              <div className="profile-buttons">
                <button
                  type="button"
                  className='save-button'
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </button>
                <button
                  type="button"
                  className="cancel-button"
                  onClick={closeProfilePopup}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbardesktop;
