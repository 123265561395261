import React, { useLayoutEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import HomePage from "./pages/HomePage";
import "./app.css";
import MobFooter from "./components/Mob/MobFooter/MobFooter";
import MobHomePage from "./components/Mob/MobHomePage";
import ProductDetails from "./pages/Productdetail/ProductDetails";
import Cart from "./pages/Cart/Cart";
import MobMenupage from "./components/Mob/MobMenu/MobMenupage";
import MobCart from "./components/Mob/Mobcart/MobCart";
import LoginPage from "./pages/login/LoginPage";
import MobLoginPage from "./components/Mob/MobLogin/MobLoginPage";
import SignupPage from "./pages/login/SignupPage";
import MobSignupPage from "./components/Mob/MobLogin/MobSignupPage";
import OtpSignupPage from "./pages/login/OtpSignupPage";
import MobOtpLoginPage from "./components/Mob/MobLogin/MobOtpLoginPage";
import PersonalDetailPage from "./pages/login/PersonalDetailPage";
import MobPersonalSign from "./components/Mob/MobLogin/MobPersonalSign";
import OrderPage from "./pages/Cart/OrderPage";
import MobOrderPage from "./components/Mob/MobOrder/MobOrderPage";
import MenuPage from "./pages/Menupage/MenuPage";
import Navbardesktop from "./components/Web/Navbar/Navbardesktop,";
import EmptyOrderPage from "./pages/Cart/EmptyOrderPage";
import AdminSignupPage from "./pages/Admin/AdminSignupPage";
import CategoryCreationPage from "./pages/Admin/CategoryCreationPage";
import MobHeader from "./components/Mob/MobHeader/MobHeader";
import BlogPage from "./pages/BlogPage/BlogPage";
import MobileBlogPage from "./components/Mob/MobileBlog/MobileBlogPage";
import ContactUs from "./pages/Contact/ContactUs";
import AboutUs from "./pages/Contact/AboutUs";
import MobAboutUs from "./components/Mob/MobContact/MobAboutUs";
import MobContactUs from "./components/Mob/MobContact/MobContactUs";
import CheckoutSuccess from "./components/Web/Checkout/CheckoutSuccess";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import MobErrorPage from "./components/Mob/MobErrorPage/MobErrorPage";
import MobEmptyCartPage from "./components/Mob/Mobcart/MobEmptyCart";
import EmptyCartPage from "./pages/Cart/EmptyCartPage";
import MobEmptyOrder from "./components/Mob/Mobcart/MobEmptyOrder";
import FormMain from "./pages/FormPages/FormMain";
import BulkOrderingForm from "./pages/FormPages/BulkOrderingForm ";
import InfluencerForm from "./pages/FormPages/InfluencerForm ";
import DealershipForm from "./pages/FormPages/DealershipForm ";
import MobFormMain from "./components/Mob/MobForms/MobFormMain";
import MobBulkOrderingForm from "./components/Mob/MobForms/MobBulkOrderingForm";
import MobDealershipForm from "./components/Mob/MobForms/MobDealershipForm";
import MobInfluencerForm from "./components/Mob/MobForms/MobInfluencerForm";
import Careers from "./pages/QuickLinks/Careers";
import Policy from "./pages/QuickLinks/Policy";
import Terms from "./pages/QuickLinks/Terms";
import Training from "./pages/QuickLinks/Training";
import MobCareer from "./components/Mob/MobQuickLinks/MobCareer";
import MobPolicy from "./components/Mob/MobQuickLinks/MobPolicy";
import MobTerms from "./components/Mob/MobQuickLinks/MobTerms";
import MobTraining from "./components/Mob/MobQuickLinks/MobTraining";

const Wrapper = ({ children }) => {
  const location = useLocation();

  return children;
};

function App() {
  return (
    <React.Fragment>
      <Router>
        <Wrapper>
          <AppContent />
        </Wrapper>
      </Router>
    </React.Fragment>
  );
}

function AppContent() {
  return (
    <React.Fragment>
      <Routes>
        <>
          <Route path="/empty" element={<EmptyOrderPage />} />
          <Route path="/emptymob" element={<MobEmptyCartPage />} />
          <Route path="/emptycart" element={<EmptyCartPage />} />

          <Route path="/career" element={<Careers />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/training" element={<Training />} />
          <Route path="/mobcareer" element={<MobCareer />} />
          <Route path="/mobpolicy" element={<MobPolicy />} />
          <Route path="/mobterms" element={<MobTerms />} />
          <Route path="/mobtrain" element={<MobTraining />} />


          <Route path="/emptymoborder" element={<MobEmptyOrder />} />
          <Route path="/menu" element={<MenuPage />} />
          <Route path="/menumob" element={<MobMenupage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/loginmob" element={<MobLoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/signupmob" element={<MobSignupPage />} />
          <Route path="/signup/otppage" element={<OtpSignupPage />} />
          <Route path="/otpmob" element={<MobOtpLoginPage />} />
          <Route
            path="/signup/otppage/personal"
            element={<PersonalDetailPage />}
          />
          <Route path="/personalmob" element={<MobPersonalSign />} />
          <Route path="/order" element={<OrderPage />} />
          <Route path="/ordermob" element={<MobOrderPage />} />
          <Route path="/admin" element={<AdminSignupPage />} />
          <Route path="/cat" element={<CategoryCreationPage />} />

          <Route path="/form" element={<FormMain />} />
          <Route path="/bulk-ordering" element={<BulkOrderingForm />} />
          <Route path="/influencer" element={<InfluencerForm />} />
          <Route path="/dealership" element={<DealershipForm />} />
 
          <Route path="/formmob" element={<MobFormMain />} />
          {/* <Route path="/blukmob" element={<MobBulkOrderingForm />} /> */}
          <Route path="/dealmob" element={<MobDealershipForm />} />
          <Route path="/influmob" element={<MobInfluencerForm />} />
          <Route path="/otp" element={<OtpSignupPage />} />

          <Route path="/aboutmob" element={<MobAboutUs />} />
          <Route path="/contactmob" element={<MobContactUs />} />

          <Route path="/*" element={<ErrorPage />} />

          <Route path="/contact" element={<ContactUs />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/nav" element={<Navbardesktop />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blogmob" element={<MobileBlogPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/productdetail" element={<ProductDetails />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout-success" element={<CheckoutSuccess />} />
          <Route path="/mobcart" element={<MobCart />} />
        </>
      </Routes>
    </React.Fragment>
  );
}

export default App;
