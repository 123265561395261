import React from "react";
import sidemainlogo from "../../images/bottom-logo.svg";
import backbutton from "../../images/Mobile-icons/Backbutton.svg";
import "./EmptyCartPage.css"; 
import menu from "../../images/Desktop-icons/menu-svg.svg";
import order from "../../images/Desktop-icons/bag-order-svgrepo.svg";
import aboutus from "../../images/Desktop-icons/about-svgrepo-com.svg";
import blogs from "../../images/Desktop-icons/blog.svg";
import contactus from "../../images/Desktop-icons/contact-svg.svg";
import emptycart from "../../images/Desktop-icons/empty-cart.svg";
import MobEmptyCart from "../../components/Mob/Mobcart/MobEmptyCart";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

const EmptyCartPage = () => {
  const navigate=useNavigate();
  return isMobile ? (
    <MobEmptyCart />
  ) : (
    <div className="emptycart-full-width-container">
      <div className="emptycart-navbar">
        <img src={sidemainlogo} className="emptycart-sidemainlogo" alt="Side Logo" />
        <img className="emptycart-back-button" src={backbutton} alt="Back Button"                       onClick={() => navigate(-1)}
        />
        <div className="emptycart-page-title">Cart</div>
      </div>

      <div className="emptycart-page-content">
        <div className="emptycart-sticky-navbar">
          <div className="emptycart-navbar-item" onClick={()=>{ navigate("/menu")}}>
            Menu
            <img src={menu} className="emptycart-navbar-img" />
          </div>
          <div className="emptycart-navbar-item selected">
            Cart
            <img src={order} className="emptycart-navbar-img" />
          </div>
          <div className="emptycart-navbar-item" onClick={()=>{ navigate("/about")}}>
            About Us
            <img src={aboutus} className="emptycart-navbar-img" />
          </div>
          <div className="emptycart-navbar-item" onClick={()=>{ navigate("/blog")}}>
            Blogs
            <img src={blogs} className="emptycart-navbar-img" />
          </div>
          <div className="emptycart-navbar-item" onClick={()=>{ navigate("/contact")}}>
            Contact Us
            <img src={contactus} className="emptycart-navbar-img" />
          </div>
        </div>
        <div className="emptycart-content">
          <div className="emptycart-content-inner">
            <img src={emptycart} className="emptycart-img" alt="Empty Cart" />
            <h2 className="emptycart-title">No Items in Cart</h2>
            <p className="emptycart-description">Your cart is currently empty.</p>
            <button className="emptycart-button" onClick={()=>{ navigate("/menu")}}>Start Shopping</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyCartPage;
