import React from "react";
import "./Footer.css";
import sideborder from "../../../images/Mask Group 10.svg";
import downborder from "../../../images/Mask Group 8.svg";
import leftborder from "../../../images/Mask Group 11.svg";
import leftsideborder from "../../../images/Mask Group 9.svg";
import facebookicon from "../../../images/Group 70.svg";
import instaicon from "../../../images/Group 69.svg";
import linkicon from "../../../images/Group 68.svg";
import footmainlogo from "../../../images/bottom-logo.svg";
import { isMobile } from "react-device-detect";
import MobFooter from "../../Mob/MobFooter/MobFooter";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return isMobile ? (
    <MobFooter/>
  ):(
    <div>
      <div className="mainfooter">
        <img src={sideborder} alt="Side Border" className="sideborder" />
        <img src={downborder} alt="down Border" className="downborder" />
        <img src={leftborder} alt="Side Border" className="leftborder" />
        <img
          src={leftsideborder}
          alt="Side Border"
          className="leftsideborder"
        />
        <div className="footersub-sectionmain">
          <div className="footer-left-section">
            <p className="footer-left-sectionp">
              Manna delight Newlands N3 2AX
            </p>
            {/* <p className='footer-left-sectionp'>Newlands</p>
          <p className='footer-left-sectionp'>N3 2AX</p> */}
            <div className="footer-social-icons">
              <img
                src={facebookicon}
                alt="Side Border"
                className="social-foot-icon"
              />
              <img
                src={instaicon}
                alt="Side Border"
                onClick={() => window.location.href = 'https://www.instagram.com/manna_delights_ice_cream/'}
                className="social-foot-icon"
              />
              <img
                src={linkicon}
                alt="Side Border"
                className="social-foot-icon"
              />
            </div>
          </div>
          <div className="footer-center-section">
            <img
              src={footmainlogo}
              alt="Side Border"
              className="footmainlogo"
            />
            <br />
            <p className="footer-center-contacttext">CONTACT US</p>
            <p className="footer-center-contactnumber">03 059 076 312</p>
            <p className="footer-centersec-contactnumber">01 526 734 996</p>
            <p className="footer-center-contactcontent">
              manager@mannadelight.cm
            </p>
          </div>
          <div className="footer-right-section">
            <h4 className="footer-right-sectionhead">QUICK LINKS</h4>
            <p className="footer-right-sectionp" onClick={()=>{navigate('/career')}}>Careers</p>
            <p className="footer-right-sectionp" onClick={()=>{navigate('/terms')}}>Terms</p>
            <p className="footer-right-sectionp" onClick={()=>{navigate('/Policy')}}>Policy</p>
            <p className="footer-right-sectionp" onClick={()=>{navigate('/training')}}>Training</p>
          </div>
        </div>
        <br />
        <div className="footer-copyright">
          <hr />
          <p>MANNA DELIGHT - © 2024 ALL RIGHTS RESERVED</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
