import React from 'react';
import './PopupDine.css';

const PopupDine = ({ showPopup, 
    // handlePopupClose,
     handleOrderTypeSelect }) => {
    if (!showPopup) return null;

    return (
        <div className="dinein-popup-overlay" 
        // onClick={handlePopupClose}
        >
            <div className="dinein-popup" onClick={(e) => e.stopPropagation()}>
                <h3 className="dinein-popup-header">HOW WOULD YOU LIKE TO RECEIVE YOUR ORDER</h3>
                <div className="dinein-popup-options">
                    <button className="dinein-popup-button" onClick={() => handleOrderTypeSelect('Dinein')}>Dine In</button>
                    <button className="dinein-popup-button" onClick={() => handleOrderTypeSelect('Takeaway')}>Take Away</button>
                </div>
                {/* <button className="dinein-popup-close" onClick={handlePopupClose}>Close</button> */}
            </div>
        </div>
    );
};

export default PopupDine;
