import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './MobFormMain.css';
import settingmob from '../../../images/Mobile-icons/settings-svgrepo.svg';
import backbutton from '../../../images/Mobile-icons/Backbutton.svg';
import menu from '../../../images/Desktop-icons/menu-svg.svg';
import aboutus from '../../../images/Desktop-icons/about-svgrepo-com.svg';
import blogs from '../../../images/Desktop-icons/blog.svg';
import contactus from '../../../images/Desktop-icons/contact-svg.svg';

const PopupMenu = ({ isOpen, onClose }) => {
    const navigate = useNavigate(); 
  
    const handleNavigate = (path) => {
      navigate(path); 
      onClose();       
    };
  
    return (
      <div className={`mob-training-popup-menu ${isOpen ? "show" : ""}`}>
        <ul className="mob-training-pop-menu-navbar-main">
          <li onClick={() => handleNavigate("/")}>
            Home
            <img src={menu} className="mob-training-pop-menu-navbar-img" alt="Menu" />
          </li>
          <li onClick={() => handleNavigate("/about")}>
            About Us
            <img src={aboutus} className="mob-training-pop-menu-navbar-img" alt="About Us" />
          </li>
          <li onClick={() => handleNavigate("/blog")}>
            Blogs
            <img src={blogs} className="mob-training-pop-menu-navbar-img" alt="Blogs" />
          </li>
          <li onClick={() => handleNavigate("/contact")}>
            Contact Us
            <img src={contactus} className="mob-training-pop-menu-navbar-img" alt="Contact Us" />
          </li>
        </ul>
        <button onClick={onClose} className="mob-training-close-popup">
          Close
        </button>
      </div>
    );
  };
  

const MobFormMain = () => {
    const [isPopupOpen, setPopupOpen] = useState(false);
    const navigate = useNavigate();

    const togglePopup = () => {
        setPopupOpen(!isPopupOpen);
    };

    return (
        <div className="mob-form-main">
            <div className="mob-order-navbar">
                <img src={backbutton} alt="Back" className="mob-back-button" onClick={() => navigate(-1)} />
                        <div className="mob-order-title">Forms</div>

                <img src={settingmob} alt="Settings" className="mob-logo" onClick={togglePopup} />
            </div>
            <PopupMenu isOpen={isPopupOpen} onClose={togglePopup} />
            <div className="mob-form-main-nav"></div>
            <div className="mob-form-main-container">
                <div className="mob-form-main-content">
                    <button className="mob-close-button-form" onClick={() => navigate('/')}>x</button>
                    <h2 className="mob-form-main-heading">Create Forms</h2>
                    <div className="mob-form-main-buttons">
                        <button className="mob-form-main-button" onClick={() => navigate('/bulkmob')}>Bulk Ordering</button>
                        <button className="mob-form-main-button" onClick={() => navigate('/influmob')}>Influencers</button>
                        <button className="mob-form-main-button" onClick={() => navigate('/dealmob')}>Dealership</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobFormMain;
