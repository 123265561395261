import React, { useState } from 'react';
import './MobInfluencerForm.css';
import { useNavigate } from 'react-router-dom';

import settingmob from '../../../images/Mobile-icons/settings-svgrepo.svg';
import backbutton from '../../../images/Mobile-icons/Backbutton.svg';
import menu from '../../../images/Desktop-icons/menu-svg.svg';
import aboutus from '../../../images/Desktop-icons/about-svgrepo-com.svg';
import blogs from '../../../images/Desktop-icons/blog.svg';
import contactus from '../../../images/Desktop-icons/contact-svg.svg';
import { toast } from 'react-toastify';
import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASEURL;

const PopupMenu = ({ isOpen, onClose }) => {
  const navigate = useNavigate(); 

  const handleNavigate = (path) => {
    navigate(path); 
    onClose();       
  };

  return (
    <div className={`mob-training-popup-menu ${isOpen ? "show" : ""}`}>
      <ul className="mob-training-pop-menu-navbar-main">
        <li onClick={() => handleNavigate("/")}>
          Home
          <img src={menu} className="mob-training-pop-menu-navbar-img" alt="Menu" />
        </li>
        <li onClick={() => handleNavigate("/about")}>
          About Us
          <img src={aboutus} className="mob-training-pop-menu-navbar-img" alt="About Us" />
        </li>
        <li onClick={() => handleNavigate("/blog")}>
          Blogs
          <img src={blogs} className="mob-training-pop-menu-navbar-img" alt="Blogs" />
        </li>
        <li onClick={() => handleNavigate("/contact")}>
          Contact Us
          <img src={contactus} className="mob-training-pop-menu-navbar-img" alt="Contact Us" />
        </li>
      </ul>
      <button onClick={onClose} className="mob-training-close-popup">
        Close
      </button>
    </div>
  );
};


const MobInfluencerForm = () => {
  const navigate = useNavigate();
  const [isPopupOpen, setPopupOpen] = useState(false);

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };
  const [formValues, setFormValues] = useState({
    email: "",
    name: "",
    socialchannels: "",
    collabrationteams: "",
    contact: "",


  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const responses = await axios.post(
        `${baseUrl}/api/products/influencer`,
        {
          email: formValues.email,
          name: formValues.name,
          contact: formValues.contact,
          socialchannels: formValues.socialchannels,
          collabrationteams: formValues.collabrationteams,


        }
      );
      toast.success("Created successfully");
      setFormValues({ email: "", name: "", contact: "",collabrationteams: "", socialchannels: "" });
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };
  return (
    <div className="mob-influencer-form-page">
      <div className="mob-order-navbar">
        <img src={backbutton} alt="Back" className="mob-back-button" onClick={() => navigate(-1)} />
        <img src={settingmob} alt="Settings" className="mob-logo" onClick={togglePopup} />
      </div>
      <PopupMenu isOpen={isPopupOpen} onClose={togglePopup} />

      <div className="mob-form-container">
        <h3 className="mob-form-header-h3">Influencer Form</h3>
        <form className="mob-form">
          <div className="mob-form-group">
            <label htmlFor="influencer-name">Name:</label>
            <input type="text" id="influencer-name" name="name"   onChange={handleInputChange} required />
          </div>
          <div className="mob-form-group">
            <label htmlFor="influencer-email">Email:</label>
            <input type="email" id="influencer-email" name="email"    onChange={handleInputChange}required />
          </div>
          <div className="mob-form-group">
            <label htmlFor="influencer-contact">Contact:</label>
            <input type="text" id="influencer-contact" name="contact"   onChange={handleInputChange} required />
          </div>
          <div className="mob-form-group">
            <label htmlFor="influencer-social">Social Channels:</label>
            <input type="text" id="influencer-social" name="socialchannels"   onChange={handleInputChange} required />
          </div>
          <div className="mob-form-group">
            <label htmlFor="influencer-comments">Collaboration Terms:</label>
            <textarea id="influencer-comments" name="collabrationteams"   onChange={handleInputChange} required />
          </div>
          <div className="mob-form-actions">
            <button  className="form-actions-submit" onClick={handleSubmit}>Submit</button>
            <button type="button" className="mob-close-button-secondary" onClick={() => navigate(-1)}>Close</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MobInfluencerForm;
