import React, { useState } from "react";
import "./MobCart.css";
import emptyCartImage from "../../../images/Desktop-icons/empty-cart.svg";
import backbutton from "../../../images/Mobile-icons/Backbutton.svg";
import settingmob from "../../../images/Mobile-icons/settings-svgrepo.svg";
import menu from "../../../images/Desktop-icons/menu-svg.svg";
import aboutus from "../../../images/Desktop-icons/about-svgrepo-com.svg";
import blogs from "../../../images/Desktop-icons/blog.svg";
import contactus from "../../../images/Desktop-icons/contact-svg.svg";
import { useNavigate } from "react-router-dom";

const PopupMenu = ({ isOpen, onClose }) => {
  const navigate = useNavigate(); 

  const handleNavigate = (path) => {
    navigate(path); 
    onClose();       
  };

  return (
    <div className={`mob-training-popup-menu ${isOpen ? "show" : ""}`}>
      <ul className="mob-training-pop-menu-navbar-main">
        <li onClick={() => handleNavigate("/")}>
          Home
          <img src={menu} className="mob-training-pop-menu-navbar-img" alt="Menu" />
        </li>
        <li onClick={() => handleNavigate("/about")}>
          About Us
          <img src={aboutus} className="mob-training-pop-menu-navbar-img" alt="About Us" />
        </li>
        <li onClick={() => handleNavigate("/blog")}>
          Blogs
          <img src={blogs} className="mob-training-pop-menu-navbar-img" alt="Blogs" />
        </li>
        <li onClick={() => handleNavigate("/contact")}>
          Contact Us
          <img src={contactus} className="mob-training-pop-menu-navbar-img" alt="Contact Us" />
        </li>
      </ul>
      <button onClick={onClose} className="mob-training-close-popup">
        Close
      </button>
    </div>
  );
};

const MobEmptyCart = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const navigate = useNavigate();

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  return (
    <div className="mob-emmpty-cart-full-width-container">
      <div className="mob-cart-navbar">
        <img
          className="mob-cart-back-button"
          src={backbutton}
          alt="Back Button"
          onClick={() => navigate(-1)}
        />
        <div className="mob-cart-page-title">Cart</div>
        <img
          src={settingmob}
          alt="Settings"
          className="mob-cart-logo"
          onClick={togglePopup}
        />
      </div>

      <PopupMenu isOpen={isPopupOpen} onClose={togglePopup} />

      <div className="mob-empty-cart-content">
        <img
          src={emptyCartImage}
          alt="Empty Cart"
          className="mob-empty-cart-image"
        />
        <h2 className="mob-empty-cart-emptycart-head">Your Cart is Empty</h2>
        <p className="mob-empty-cart-emptycart-text">
          Looks like you haven't added anything to your cart yet.
        </p>
        <button           onClick={() => navigate("/menu")}
 className="mob-empty-cart-button">Start Shopping</button>
      </div>
    </div>
  );
};

export default MobEmptyCart;
