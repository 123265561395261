import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import firstimage from "../../images/Group 3.png";
import Slider from "react-slick";

import rightarrow from "../../images/Group 44.svg";
import leftarrow from "../../images/Group 43.svg";
import sweetdealice from "../../images/icecream2-slider-pic7.png";
import milkwaveimg from "../../images/Group 57@2x.png";
import bottomwave from "../../images/bottomwave.svg";
import lastdiviceimg from "../../images/h5_img-5@2x.png";
import ourstoryspcl1 from "../../images/ice-ourstory1.png";
import ourstoryspcl2 from "../../images/ice-oustory-2.png";
import ourstoryspcl3 from "../../images/ice-oustory-3.png";
import ourstoryspcl4 from "../../images/ice-oustory-4.png";
import ourstoryspcl5 from "../../images/ice-oustory-5.png";
import ourstoryspcl6 from "../../images/ice-oustory-6.png";
import doubleline from "../../images/double-line.svg";
import "./MobHomepage.css";
import MobHeader from "./MobHeader/MobHeader";
import MobFooter from "./MobFooter/MobFooter";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const baseUrl = process.env.REACT_APP_BASEURL;

const MobHomePage = () => {
  const [coffeeText, setCoffeeText] = useState("COFFEE");
  const [icecreamText, setIcecreamText] = useState("icecream");
  const [gelantoText, setGelatoText] = useState("GELATO");
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [data, setData] = useState([]);
  const [productsdata, setproductsdata] = useState([]);
  const [linkdata, setlinkdata] = useState([]);

  const navigate = useNavigate();

  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 2;
  useEffect(() => {
    const fetchSubCategories = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/alloncategories`);
        setCategories(Array.isArray(response.data) ? response.data : []);
        console.log(response?.data, "categories");
        if (response.data.length > 0) {
          setSelectedCategory(response.data[0]._id);
          const responses = await axios.get(
            `${baseUrl}/api/products/all/category/${response?.data[0]?._id}`
          );
          console.log(responses.data, "response.data");
          setProducts(responses.data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchSubCategories();
  }, []);
  const handleCoffeeClick = (value, newGelatoText, newIcecreamText) => {
    setCoffeeText(value);
    setGelatoText(newGelatoText);
    setIcecreamText(newIcecreamText);
  };

  const fetchDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/blog/oncarousals`);
      console.log(response.data, "kk");
      setData(response.data);
    } catch (error) {
      console.error("Network error:", error);
    }
  };
  const sliderSettings = {
    dots: true,
    infinite: data?.length > 1,
    speed: 200,
    slidesToShow: 1,
    autoplay: data.length > 1,
    autoplaySpeed: 3000,
    arrows: data.length > 1,
    slidesToScroll: 1,
    arrows: data?.length > 1,
  };
  const handleIcecreamClick = (
    newIcecreamText,
    newGelatoText,
    newCoffeeText
  ) => {
    setIcecreamText(newIcecreamText);
    setGelatoText(newGelatoText);
    setCoffeeText(newCoffeeText);
  };

  const handlegelantoClick = (value, value1, value2) => {
    setGelatoText(value);
    setCoffeeText(value1);
    setIcecreamText(value2);
  };
  const handleCategoryClick = async (category, ...args) => {
    setSelectedCategory(category);
    const response = await axios.get(
      `${baseUrl}/api/products/all/category/${category}`
    );
    console.log(response.data, "response.data");
    setProducts(response.data);
  };
  const handleNext = () => {
    if (currentIndex + itemsPerPage < products.length) {
      setCurrentIndex(currentIndex + itemsPerPage);
    }
  };
  const handleDiscoverClick = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handlePrev = () => {
    if (currentIndex - itemsPerPage >= 0) {
      setCurrentIndex(currentIndex - itemsPerPage);
    }
  };
  useEffect(() => {
    fetchDetails();
  }, []);
  useEffect(() => {
    const fetchproducts = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/user/allonhomepage`);
        const responses = await axios.get(`${baseUrl}/api/user/allonlinkpage`);
        setlinkdata(responses.data);
        console.log(response.data, "kk");
        setproductsdata(response.data);
      } catch (error) {
        console.error("Network error:", error);
      }
    };
    fetchproducts();
  }, []);
  return (
    <div>
      <MobHeader />
      <div className="Mob-home-ice-made-main-section">
        <div className="Mob-home-ice-made-img-section">
          <div className="Mob-home-image-container">
            <motion.img
              src={firstimage}
              className="Mob-home-firstimage"
              alt="First Ice Cream"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1, ease: "easeOut" }}
              whileHover={{ scale: 1.2 }}
            />
          </div>
          <div className="Mob-home-text-content">
            <p className="Mob-home-icecream-text-head">
              ice cream
              <br />
              <span className="Mob-home-icecream-text-passion">
                made with passion
              </span>
            </p>
            <p className="Mob-home-icecream-text-p">
              Dive into a frozen paradise and let our ice cream take you to a
              land of pure ecstasy.
            </p>
            <motion.div
              className="Mob-home-discover-button"
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 2 }}
              // onClick={handleDiscoverClick}
            >
              DISCOVER NOW
            </motion.div>
          </div>
        </div>
        {/* <img src={thirdimage} className="Mob-home-thirdimage" alt="Third Ice Cream" /> */}
      </div>
      <div className="mob-homepage-carousel-container">
        {data?.length > 0 && (
          <Slider {...sliderSettings}>
            {data?.map((item, index) => (
              <div key={index}>
                <img
                  src={item?.image[0]}
                  alt={`Slide ${index + 1}`}
                  className="mob-carousel-image"
                />
              </div>
            ))}
          </Slider>
        )}
      </div>
      {/* <img src={logo} className="Mob-home-logoimage" alt="Logo" /> */}
      <img src={bottomwave} className="Mob-home-topwave" alt="Top Wave" />
      <div className="Mob-home-bottombackground">
        <p className="Mob-home-bottombackground-head-text">Our Products</p>
        <p className="Mob-home-bottombackground-head-scoope">
          Explore Our Scoops!
        </p>
        <div className="Mob-home-products-container">
          {categories?.map((item, index) => (
            <p
              onClick={() => handleCategoryClick(item?._id)}
              className={
                selectedCategory === item?._id ? "icecreams" : "category"
              }
            >
              {item?.category}
            </p>
          ))}
        </div>
        <div className="Mob-home-icecream-products-slider">
          <div className="Mob-home-icecream-products-bottombackground">
            {Array?.isArray(products) &&
              products
                ?.slice(currentIndex, currentIndex + itemsPerPage)
                ?.map((item, index) => (
                  <div
                    className="Mob-home-icecream-singel-products-bottombackground"
                    key={index}
                  >
                    <motion.div
                      className="Mob-home-image-container"
                      whileHover={{ y: -40 }}
                    >
                      <img
                        src={item?.image[0]}
                        className="Mob-home-icreamslide"
                        alt="ice cream"
                        onClick={() => {
                          navigate("/menu");
                        }}
                      />
                      <div>
                        <p className="Mob-home-icreamslide-head-p">
                          {item?.productname}
                        </p>
                        <p className="Mob-home-icreamslide-sub-p">
                          {item?.subcategory?.subcategory}
                        </p>
                      </div>
                    </motion.div>
                  </div>
                ))}
          </div>
        </div>
        <div className="Mob-home-Icreamslider-arrow-section">
          <img
            src={rightarrow}
            className="Mob-home-sliderarrow"
            alt="right arrow"
            onClick={handlePrev}

          />
          <img
            src={leftarrow}
            className="Mob-home-sliderarrow"
            alt="left arrow"
            onClick={handleNext}

          />
        </div>
      </div>
      <p className="Mob-home-ourstory-head">
        our <span className="Mob-home-Our-story-span">Story</span>
      </p>

      <div className="Mob-home-ourstory-container-main">
        <div className="Mob-home-ourstory-layout">
          <div className="Mob-home-ourstory-ice-img-section-left">
            <div className="Mob-home-ourstory-ice-img-section1">
              <p className="Mob-home-ourstory-ice-img-section1-p">
                {productsdata[0]?.description}{" "}
              </p>
              <img
                src={productsdata[0]?.image}
                className="Mob-home-ourstory-mainimage"
                alt="Our Story Special 1"
              />
            </div>
            <div className="Mob-home-ourstory-ice-img-section2">
              <p className="Mob-home-ourstory-ice-img-section2-p">
                {productsdata[1]?.description}
              </p>
              <img
                src={productsdata[1]?.image}
                alt="Our Story Special 2"
                className="Mob-home-ourstory-mainimage"
              />
            </div>
            <div className="Mob-home-ourstory-ice-img-section3">
              <p className="Mob-home-ourstory-ice-img-section3-p">
                {productsdata[2]?.description}{" "}
              </p>
              <img
                src={productsdata[2]?.image}
                alt="Our Story Special 3"
                className="Mob-home-ourstory-mainimage"
              />
            </div>
          </div>
          <div>
            <div className="Mob-home-container">
              <div className="Mob-home-bluediv">
                <div className="Mob-home-image-container-bluediv">
                  <img
                    src={lastdiviceimg}
                    className="Mob-home-lastdiviceimg"
                    alt="Last Div Ice Image"
                  />
                  <motion.div
                    className="Mob-home-discover-button-onice"
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 2 }}
                    onClick={()=>{navigate(`/${linkdata[0]?.link}`)}}

                  >
                    DISCOVER NOW
                  </motion.div>
                </div>
              </div>
              <div className="Mob-home-milkwave-container">
                <motion.img
                  src={milkwaveimg}
                  className="Mob-home-milkwaveimg"
                  alt="Milk Wave"
                  animate={{
                    y: [0, 15, 0],
                  }}
                  transition={{
                    repeat: Infinity,
                    duration: 5,
                    ease: "linear",
                  }}
                />
                <img
                  src={doubleline}
                  className="Mob-home-doubleline"
                  alt="Double Line"
                />
              </div>
            </div>
          </div>
          <div className="Mob-home-ourstory-ice-img-section-right">
  <div className="Mob-home-ourstory-ice-img-section4">
    <p className="Mob-home-ourstory-ice-img-section1-p">
      {productsdata[3]?.description}{" "}
    </p>
    <img
      src={productsdata[3]?.image}
      alt="Our Story Special 4"
      className="Mob-home-ourstory-mainimage"
    />
  </div>
  <div className="Mob-home-ourstory-ice-img-section5">
    <p className="Mob-home-ourstory-ice-img-section1-p">
      {productsdata[4]?.description}
    </p>
    <img
      src={productsdata[4]?.image}
      alt="Our Story Special 5"
      className="Mob-home-ourstory-mainimage"
    />
  </div>
  <div className="Mob-home-ourstory-ice-img-section6">
    <p className="Mob-home-ourstory-ice-img-section3-p">
      {productsdata[5]?.description}{" "}
    </p>
    <img
      src={productsdata[5]?.image}
      alt="Our Story Special 6"
      className="Mob-home-ourstory-mainimage"
    />
  </div>
</div>

        </div>
      </div>

      <div className="Mob-home-sweetdeal-bottombackground">
        <div className="Mob-home-sweet-deal-ourstory">
          <div className="Mob-home-ice-made-main-section">
            <div className="Mob-home-sweetdeal-image-container">
              <motion.img
                src={sweetdealice}
                whileHover={{ scale: 1.2 }}
                className="Mob-home-sweetdeal-ice"
                alt="Sweet Deal Ice Cream"
              />
            </div>
            <div className="Mob-home-text-content">
              <h3 className="Mob-home-sweetdeal-icecream-text-head">
                Sweet Deals for Big Cravings!
              </h3>
              <p className="Mob-home-sweetdeal-icecream-text-p">
                And the best part? Our bulk order specials come with unbeatable
                discounts, so you can enjoy more of your favorite treats for
                less.
              </p>
              <motion.div
                className="Mob-home-discoverbuttonhomepage"
                whileHover={{
                  scale: 1.1,
                  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
                }}
                whileTap={{ scale: 0.9 }}

              >
                ORDER NOW
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      <MobFooter />
      {showPopup && (
        <div className="mob-dine-popup-overlay" onClick={closePopup}>
          <div className="mob-dine-popup" onClick={(e) => e.stopPropagation()}>
            <h3 className="mob-dine-popup-header">
              HOW WOULD YOU LIKE TO RECEIVE YOUR ORDER
            </h3>
            <div className="mob-dine-popup-options">
              <button className="mob-dine-popup-button">Dine In</button>
              <button className="mob-dine-popup-button">Take Away</button>
            </div>
            <button className="mob-dine-popup-close" onClick={closePopup}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobHomePage;
