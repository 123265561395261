import React, { useState } from "react";
import "./AdminSignupPage.css";
import sidemainlogo from "../../images/bottom-logo.svg";
import backbutton from "../../images/Mobile-icons/Backbutton.svg";

const AdminSignupPage = () => {
  const [formFields, setFormFields] = useState({
    email: "",
    name: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formFields);
  };

  return (
    <div className="admin-signup-container">
      <div className="admin-signup-navbar">
        <img src={sidemainlogo} className="admin-signup-logo" alt="Logo" />
        <img className="admin-signup-back-button" src={backbutton} alt="Back Button" />
        <div className="admin-signup-title">Admin Signup</div>
      </div>
      <div className="admin-signup-content">
        <form onSubmit={handleSubmit} className="admin-signup-form">
        <h4 className="admin-signuphead">Signup</h4>
          <div className="admin-signup-field">
            <label htmlFor="email">Email ID</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter Your Email Address"
              value={formFields.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="admin-signup-field">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              placeholder="Enter Your Name"
              name="name"
              value={formFields.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="admin-signup-field">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Enter Your Password"
              value={formFields.password}
              onChange={handleInputChange}
              required
            />
          </div>
          <button type="submit" className="admin-signup-submit-button">Signup</button>
        </form>
      </div>
    </div>
  );
};

export default AdminSignupPage;
