import React from 'react';
import './ErrorPage.css';
import errorImage from '../../images/Desktop-icons/error.png';
import Navbardesktop from '../../components/Web/Navbar/Navbardesktop,';
import MobErrorPage from '../../components/Mob/MobErrorPage/MobErrorPage';
import { isMobile } from "react-device-detect";

const ErrorPage = () => {
  return isMobile ? (
    <MobErrorPage />
  ) : (
    <div>
        <Navbardesktop/>
    <div className="error-page">
      <div className="error-content">
        <h1>Oops! Page Not Found</h1>
        <p>The page you are looking for might have been removed or is temporarily unavailable.</p>
        <button onClick={() => window.location.href = '/'}>Back to Home</button>
      </div>
      <div className="error-image">
        <img src={errorImage} alt="Ice Cream" />
      </div>
      </div>
    </div>
  );
}

export default ErrorPage;
