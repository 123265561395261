import React, { useState } from "react";
import "./CategoryCreationPage.css";
import backbutton from "../../images/Mobile-icons/Backbutton.svg";
import homeIcon from "../../images/Desktop-icons/user-icon.svg";
import menuIcon from "../../images/Desktop-icons/menu-svg.svg";
import orderIcon from "../../images/Desktop-icons/bag-order-svgrepo.svg";
import settingsIcon from "../../images/Mobile-icons/settings-svgrepo.svg";

const CategoryCreationPage = () => {
  const [formFields, setFormFields] = useState({
    categoryName: "",
    categoryImage: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleImageChange = (e) => {
    setFormFields({ ...formFields, categoryImage: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Category Created:", formFields);
  };

  return (
    <div className="category-creation-container">
      <div className="category-creation-navbar">
        <img className="category-back-button" src={backbutton} alt="Back Button" />
        <p className="category-creation-title">Create New Category</p>
      </div>
      <div className="category-creation-content">
        <div className="sticky-sidebar">
          <div className="sidebar-item">
            Home
            <img src={homeIcon} className="sidebar-img" alt="Home" />
          </div>
          <div className="sidebar-item">
            Menu
            <img src={menuIcon} className="sidebar-img" alt="Menu" />
          </div>
          <div className="sidebar-item selected">
            Orders
            <img src={orderIcon} className="sidebar-img" alt="Orders" />
          </div>
          <div className="sidebar-item">
            Settings
            <img src={settingsIcon} className="sidebar-img" alt="Settings" />
          </div>
        </div>
        <div className="category-content">
          <form onSubmit={handleSubmit} className="category-creation-form">
            <div className="category-creation-field">
              <label htmlFor="categoryName">Category Name</label>
              <input
                type="text"
                id="categoryName"
                name="categoryName"
                value={formFields.categoryName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="category-creation-field">
              <label htmlFor="categoryImage">Category Image</label>
              <input
                type="file"
                id="categoryImage"
                name="categoryImage"
                accept="image/*"
                onChange={handleImageChange}
                required
              />
            </div>
            <button type="submit" className="category-creation-submit-button">Create Category</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CategoryCreationPage;
