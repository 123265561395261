import React, { useEffect, useState } from "react";
import "./OrderPage.css";
import sidemainlogo from "../../images/bottom-logo.svg";
import backbutton from "../../images/Mobile-icons/Backbutton.svg";
import icecream from "../../images/sweeny-home2-decor-img-02.png";
import menu from "../../images/Desktop-icons/menu-svg.svg";
import order from "../../images/Desktop-icons/bag-order-svgrepo.svg";
import aboutus from "../../images/Desktop-icons/about-svgrepo-com.svg";
import blogs from "../../images/Desktop-icons/blog.svg";
import contactus from "../../images/Desktop-icons/contact-svg.svg";
import EmptyOrderPage from "./EmptyOrderPage";
import { isMobile } from "react-device-detect";
import MobOrderPage from "../../components/Mob/MobOrder/MobOrderPage";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const baseUrl = process.env.REACT_APP_BASEURL;

const products = [
  {
    id: 1,
    name: "Order Name 1",
    orderId: "123456",
    date: "2024-07-01",
    price: "$20.00",
    image: icecream,
  },
  {
    id: 2,
    name: "Order Name 2",
    orderId: "789012",
    date: "2024-07-02",
    price: "$25.00",
    image: icecream,
  },
];

const OrderPage = () => {
  const [orders, setorder] = useState([]);
  console.log(orders,'order');
  const navigate = useNavigate();
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  console.log(userData?.user, "jjj");
  useEffect(() => {
    const fetchSubCategories = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/order/${userData?.user?._id}`
        );
        setorder(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchSubCategories();
  }, []);
  return isMobile ? (
    <>
      <MobOrderPage />
    </>
  ) : (
    <div className="order-full-width-container">
      <div className="cart-navbar">
        <img src={sidemainlogo} className="sidemainlogos" alt="Side Logo"  onClick={()=>{navigate('/')}} />
        <img className="cart-back-button" src={backbutton} alt="Back Button" onClick={()=>{navigate(-1)}}/>
        <div className="cart-page-title">Orders</div>
      </div>
      <div className="order-page-content">
        <div className="sticky-navbar">
          <div className="navbar-item" onClick={()=>{navigate('/menu')}}>
            Menu
            <img src={menu} className="navbar-img" />
          </div>
          <div className="navbar-item selected">
            Orders
            <img src={order} className="navbar-img" />
          </div>
          <div className="navbar-item" onClick={()=>{navigate('/about')}}>
            About Us
            <img src={aboutus} className="navbar-img" />
          </div>
          <div className="navbar-item" onClick={()=>{navigate('/blog')}}>
            Blogs
            <img src={blogs} className="navbar-img" />
          </div>
          <div className="navbar-item" onClick={()=>{navigate('/contact')}}>
            Contact Us
            <img src={contactus} className="navbar-img" />
          </div>
        </div>
        <div className="order-content">
          {orders?.map((product) => (
            <div key={product.id} className="order-past-order">
              {product?.items?.map((products) => (
                <>
                  <div className="order-details">
                    <img
                      src={products?.productId?.image[0]}
                      className="order-image"
                      alt="Order"
                    />
                    <div className="order-info">
                      <div className="order-name">
                        {" "}
                        {products?.productId?.productname}
                      </div>
                      <div className="order-id">
                        Order ID: {product._id}
                      </div>
                      <div className="order-date">
                        Ordered on: {product?.updatedAt}
                      </div>
                      ₹{products?.productId?.price}
                    </div>
                  </div>
                  <div className="order-dotted-line"></div>
                  <div className="order-actions">
                    <button className="reorder-button" onClick={()=>{navigate('/menu')}}>Reorder</button>
                    <div className="order-price">{product.price}</div>
                  </div>
                </>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrderPage;
