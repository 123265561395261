import React, { useEffect, useState } from "react";
import "./MobOrderPage.css";
import settingmob from "../../../images/Mobile-icons/settings-svgrepo.svg";
import backbutton from "../../../images/Mobile-icons/Backbutton.svg";
import icecream from "../../../images/ice-oustory-3.png";
import menu from "../../../images/Desktop-icons/menu-svg.svg";
import aboutus from "../../../images/Desktop-icons/about-svgrepo-com.svg";
import blogs from "../../../images/Desktop-icons/blog.svg";
import contactus from "../../../images/Desktop-icons/contact-svg.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const baseUrl = process.env.REACT_APP_BASEURL;

const ordersData = [
  {
    id: 1,
    name: "Order Name 1",
    orderId: "123456",
    orderDate: "2024-07-01",
    price: "$20.00",
    image: icecream,
  },
  {
    id: 2,
    name: "Order Name 2",
    orderId: "123457",
    orderDate: "2024-07-02",
    price: "$25.00",
    image: icecream,
  },
  {
    id: 3,
    name: "Order Name 3",
    orderId: "123458",
    orderDate: "2024-07-03",
    price: "$30.00",
    image: icecream,
  },
  {
    id: 4,
    name: "Order Name 4",
    orderId: "123459",
    orderDate: "2024-07-04",
    price: "$35.00",
    image: icecream,
  },
];

const PopupMenu = ({ isOpen, onClose }) => {
  const navigate = useNavigate(); // Use navigate here

  const handleNavigate = (path) => {
    navigate(path); // Navigate to the specified path
    onClose(); // Close the popup
  };

  return (
    <div className={`popup-menu ${isOpen ? 'show' : ''}`}>
      <ul className="pop-menu-navbar-main">
        <li onClick={() => handleNavigate("/")}>
          Home <img src={menu} className="pop-menu-navbar-img" alt="Menu" />
        </li>
        <li onClick={() => handleNavigate("/about")}>
          About Us <img src={aboutus} className="pop-menu-navbar-img" alt="About Us" />
        </li>
        <li onClick={() => handleNavigate("/blogs")}>
          Blogs <img src={blogs} className="pop-menu-navbar-img" alt="Blogs" />
        </li>
        <li onClick={() => handleNavigate("/contact")}>
          Contact Us <img src={contactus} className="pop-menu-navbar-img" alt="Contact Us" />
        </li>
      </ul>
      <button onClick={onClose} className="close-popup">Close</button>
    </div>
  );
};

const MobOrderPage = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/order/${userData?.user?._id}`);
        setOrders(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
    fetchOrders();
  }, []);

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  return (
    <div className="mob-order-page">
      <div className="mob-order-navbar">
        <img onClick={() => { navigate(-1); }} src={backbutton} alt="Back" className="mob-back-button" />
        <div className="mob-order-title">Orders</div>
        <img src={settingmob} alt="Logo" className="mob-logo" onClick={togglePopup} />
      </div>
      <PopupMenu isOpen={isPopupOpen} onClose={togglePopup} />
      <div className="mob-order-content">
        {orders?.map((order) => (
          <div key={order.id} className="mob-order-container">
            {order?.items?.map((product) => (
              <div className="mob-order-past-order" key={product.id}>
                <div className="mob-order-details">
                  <div className="mob-order-details-buttonandimg">
                    <img src={product?.productId?.image[0]} className="mob-order-image" alt="Order" />
                    <button className="mob-reorder-button" onClick={() => { navigate('/menu'); }}>Reorder</button>
                  </div>
                  <div className="mob-order-info">
                    <div className="mob-order-name">{product?.productId?.productname}</div>
                    <div className="mob-order-id">Order ID: {order._id}</div>
                    <div className="mob-order-date">Ordered on: {order?.updatedAt}</div>
                  </div>
                </div>
                <div className="mob-order-actions">
                  <div className="mob-order-price">₹{product?.productId?.price}</div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobOrderPage;
