import React from "react";
import axios from "axios";
const baseUrl = process.env.REACT_APP_BASEURL;
const PaymentButton = ({ cartItem,onClick,relatedProducts,Total}) => {
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  console.log(userData?.user,'jjj',cartItem);
  const handleCheckout = async () => {

    try{
      const response = await axios.post(
        `${baseUrl}/api/products/create-checkout-success`,
        // `${baseUrl}/api/subcategory/all/`,
  
        {
          cartItem,
          relatedProducts,
          userId: userData?.user?._id,
          Total
        }
      );
      console.log(response.data.url, "response.data.url");
      if (response.data.url) {
        window.location.href = response.data.url;
      }
    }
    catch (error) {
      console.error(error);
    }
  };
  
  return (
    <div>
      <button
                  className="cart-addaddressandpaybutton"
                  onClick={() => {
                    if (onClick) {
                      onClick(); // Execute the passed onClick function
                    }
                    handleCheckout(); // Then perform the checkout
                  }}
      >
        Checkout
      </button>
    </div>
  );
};

export default PaymentButton;
