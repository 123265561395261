import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Training.css";
import Footer from "../../components/Web/Footer/Footer";
import { isMobile } from "react-device-detect";
import MobTraining from "../../components/Mob/MobQuickLinks/MobTraining"; 
import Navbardesktop from "../../components/Web/Navbar/Navbardesktop,";

const baseUrl = process.env.REACT_APP_BASEURL;

const Training = () => {
  const [trainingData, setTrainingData] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState([]);

  const fetchTrainingDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/blog/training`);
      setTrainingData(response.data);
      setExpandedDescriptions(Array(response.data.length).fill(false)); // Initialize with false
    } catch (error) {
      console.error("Error fetching training data:", error);
    }
  };

  useEffect(() => {
    fetchTrainingDetails();
  }, []);

  const toggleDescription = (index) => {
    const updatedExpanded = expandedDescriptions.map((item, i) =>
      i === index ? !item : item
    );
    setExpandedDescriptions(updatedExpanded);
  };

  return isMobile ? (
    <MobTraining />
  ) : (
    <div>
      <Navbardesktop />
      <div className="training-main-section">
        <p className="training-content-h1">Training</p>
        <div className="training-content">
  {trainingData?.map((item, index) => (
    <div key={index} className="training-item">
      <h2 className="training-heading">{item.title}</h2>
      <p className="training-text">
        {expandedDescriptions[index] ? item.description : item.description.slice(0, 300) + (item.description.length > 300 ? "..." : "")}
        {item.description.length > 300 && (
          <span className="toggle-container">
            <button 
              className="training-toggle-button" 
              onClick={() => toggleDescription(index)}
            >
              {expandedDescriptions[index] ? "See Less" : "See More"}
            </button>
          </span>
        )}
      </p>

      {item?.image?
      <>
        <div className="training-images-container ">
        
            <img  src={item?.image || ""} alt="Training Image" className="career-image" />
         
        </div>
      </>:<>
    
      </>}
   
    </div>
  ))}
  {/* <button className="training-enroll-button">Enroll Now</button> */}
</div>

      </div>
      <Footer />
    </div>
  );
};

export default Training;
