import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./MobTerms.css";
import backbutton from "../../../images/Mobile-icons/Backbutton.svg";
import settingmob from "../../../images/Mobile-icons/settings-svgrepo.svg";
import menu from "../../../images/Desktop-icons/menu-svg.svg";
import aboutus from "../../../images/Desktop-icons/about-svgrepo-com.svg";
import blogs from "../../../images/Desktop-icons/blog.svg";
import contactus from "../../../images/Desktop-icons/contact-svg.svg";
import MobFooter from "../MobFooter/MobFooter";
import axios from "axios";
const baseUrl = process.env.REACT_APP_BASEURL;

const PopupMenu = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
    onClose(); // Close the popup after navigating
  };

  return (
    <div className={`mob-terms-popup-menu ${isOpen ? "show" : ""}`}>
      <ul className="mob-terms-pop-menu-navbar-main">
        <li onClick={() => handleNavigate("/")}>
          Home
          <img
            src={menu}
            className="mob-terms-pop-menu-navbar-img"
            alt="Menu"
          />
        </li>
        <li onClick={() => handleNavigate("/about")}>
          About Us
          <img
            src={aboutus}
            className="mob-terms-pop-menu-navbar-img"
            alt="About Us"
          />
        </li>
        <li onClick={() => handleNavigate("/blog")}>
          Blogs
          <img
            src={blogs}
            className="mob-terms-pop-menu-navbar-img"
            alt="Blogs"
          />
        </li>
        <li onClick={() => handleNavigate("/contact")}>
          Contact Us
          <img
            src={contactus}
            className="mob-terms-pop-menu-navbar-img"
            alt="Contact Us"
          />
        </li>
      </ul>
      <button onClick={onClose} className="mob-terms-close-popup">
        Close
      </button>
    </div>
  );
};

const MobTerms = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(null); // Track which item is expanded
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const fetchDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/blog/allonterms`);
      setData(response.data);
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index); // Toggle between expanded and collapsed
  };

  return (
    <div className="mob-terms-full-width-container">
      <div className="mob-terms-navbar">
        <img
          className="mob-terms-back-button"
          src={backbutton}
          alt="Back Button"
          onClick={handleBackButtonClick}
        />
        <div className="mob-terms-page-title">Terms and Conditions</div>
        <img
          src={settingmob}
          alt="Settings"
          className="mob-terms-logo"
          onClick={togglePopup}
        />
      </div>

      <PopupMenu isOpen={isPopupOpen} onClose={togglePopup} />

      <div className="mob-terms-content">
        <h2 className="mob-terms-header">Terms of Service</h2>
        {data?.map((item, index) => (
          <div key={index}>
            <h3 className="mob-terms-subhead">{item?.title}</h3>
            <p className="mob-terms-text">
              {expandedIndex === index ? item?.description : `${item?.description.substring(0, 100)}...`}
              <button
                className="mob-terms-toggle-button"
                onClick={() => toggleExpand(index)}
              >
                {expandedIndex === index ? "See Less" : "See More"}
              </button>
            </p>
          </div>
        ))}
      </div>

      <div className="mob-terms-footer">
        <MobFooter />
      </div>
    </div>
  );
};

export default MobTerms;
