import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './MobBulkOrderingForm.css';
import settingmob from '../../../images/Mobile-icons/settings-svgrepo.svg';
import backbutton from '../../../images/Mobile-icons/Backbutton.svg';
import menu from '../../../images/Desktop-icons/menu-svg.svg';
import aboutus from '../../../images/Desktop-icons/about-svgrepo-com.svg';
import blogs from '../../../images/Desktop-icons/blog.svg';
import contactus from '../../../images/Desktop-icons/contact-svg.svg';
import { toast } from 'react-toastify';
import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASEURL;

const PopupMenu = ({ isOpen, onClose }) => {
  const navigate = useNavigate(); 

  const handleNavigate = (path) => {
    navigate(path); 
    onClose();       
  };

  return (
    <div className={`mob-training-popup-menu ${isOpen ? "show" : ""}`}>
      <ul className="mob-training-pop-menu-navbar-main">
        <li onClick={() => handleNavigate("/")}>
          Home
          <img src={menu} className="mob-training-pop-menu-navbar-img" alt="Menu" />
        </li>
        <li onClick={() => handleNavigate("/about")}>
          About Us
          <img src={aboutus} className="mob-training-pop-menu-navbar-img" alt="About Us" />
        </li>
        <li onClick={() => handleNavigate("/blog")}>
          Blogs
          <img src={blogs} className="mob-training-pop-menu-navbar-img" alt="Blogs" />
        </li>
        <li onClick={() => handleNavigate("/contact")}>
          Contact Us
          <img src={contactus} className="mob-training-pop-menu-navbar-img" alt="Contact Us" />
        </li>
      </ul>
      <button onClick={onClose} className="mob-training-close-popup">
        Close
      </button>
    </div>
  );
};


const MobDealershipForm = () => {
  const navigate = useNavigate();
  const [isPopupOpen, setPopupOpen] = useState(false);

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  const [formValues, setFormValues] = useState({
    email: "",
    name: "",
    phone: "",
    website: "",
    address: "",
    jobTitle: "",
    comments: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const responses = await axios.post(`${baseUrl}/api/products/dealership`, {
        email: formValues.email,
        name: formValues.name,
        phone: formValues.phone,
        website: formValues.website,
        address: formValues.address,
        jobTitle: formValues.jobTitle,
        comments: formValues.comments,
      });
      toast.success("Created successfully");
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  return (
    <div className="mob-dealership-form">
      <div className="mob-order-navbar">
        <img src={backbutton} alt="Back" className="mob-back-button" onClick={() => navigate(-1)} />
        <img src={settingmob} alt="Settings" className="mob-logo" onClick={togglePopup} />
      </div>
      <PopupMenu isOpen={isPopupOpen} onClose={togglePopup} />
      <div className="mob-form-container">
        <h3 className="form-header-h3">Dealership Form</h3>
        <form className="mob-form">
          <div className="mob-form-group">
            <label htmlFor="dealer-name">Name:</label>
            <input
              type="text"
              id="dealer-name"
              name="name"
              required
              onChange={handleInputChange}
              placeholder="Enter your name"
            />
          </div>
          <div className="mob-form-group">
            <label htmlFor="dealer-email">Email:</label>
            <input
              type="email"
              id="dealer-email"
              name="email"
              required
              onChange={handleInputChange}
              placeholder="Enter your email"
            />
          </div>
          <div className="mob-form-group">
            <label htmlFor="dealer-phone">Phone Number:</label>
            <input
              type="tel"
              id="dealer-phone"
              name="phone"
              required
              onChange={handleInputChange}
              placeholder="Enter your phone number"
            />
          </div>
          <div className="mob-form-group">
            <label htmlFor="dealer-website">Website (optional):</label>
            <input
              type="url"
              id="dealer-website"
              name="website"
              onChange={handleInputChange}
              placeholder="Enter your website URL"
            />
          </div>
          <div className="mob-form-group">
            <label htmlFor="dealer-address">Address:</label>
            <input
              type="text"
              id="dealer-address"
              name="address"
              required
              onChange={handleInputChange}
              placeholder="Enter your address"
            />
          </div>
          <div className="mob-form-group">
            <label htmlFor="dealer-job-title">Job Title:</label>
            <input
              type="text"
              id="dealer-job-title"
              name="jobTitle"
              required
              onChange={handleInputChange}
              placeholder="Enter your job title"
            />
          </div>
          <div className="mob-form-group">
            <label htmlFor="dealer-comments">Comments:</label>
            <textarea
              id="dealer-comments"
              name="comments"
              required
              onChange={handleInputChange}
              placeholder="Add any additional comments"
            />
          </div>
          <div className="mob-form-actions">
            <button className="form-actions-submit" onClick={handleSubmit}>Submit</button>
            <button type="button" className="close-button-secondary" onClick={() => navigate(-1)}>Close</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MobDealershipForm;
