import React, { useState, useEffect } from "react";
import "./MobSignupPage.css";
import backIcon from "../../../images/Mobile-icons/Backbutton.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const baseUrl = process.env.REACT_APP_BASEURL;

const MobSignupPage = () => {
  const navigate = useNavigate();

  const [filledFields, setFilledFields] = useState({
    email: false,
    mobile: false,
    password: false,
    confirmPassword: false,
  });

  const [formValues, setFormValues] = useState({
    email: "",
    mobile: "",
    password: "",
    confirmPassword: "",
  });

  const [validationMessages, setValidationMessages] = useState({
    password: [],
    confirmPassword: "",
  });

  const [isButtonActive, setIsButtonActive] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let passwordMessages = [];
    let confirmPasswordMessage = "";

    if (name === "password") {
      if (!/(?=.*[A-Z])/.test(value)) {
        passwordMessages.push("Add a capital letter");
      }
      if (!/(?=.*\d)/.test(value)) {
        passwordMessages.push("Add a number");
      }
      if (!/(?=.*[@$!%*?&])/.test(value)) {
        passwordMessages.push(
          "Add a special character (e.g. @, $, !, %, *, ?, &)"
        );
      }
      if (value.length < 8) {
        passwordMessages.push("Password must be at least 8 characters long");
      }

      if (formValues.confirmPassword && value !== formValues.confirmPassword) {
        confirmPasswordMessage = "Passwords do not match.";
      }
    }

    if (name === "confirmPassword") {
      confirmPasswordMessage =
        value !== formValues.password ? "Passwords do not match." : "";
    }

    setValidationMessages((prevState) => ({
      ...prevState,
      password: passwordMessages,
      confirmPassword: confirmPasswordMessage,
    }));

    setFilledFields((prevState) => ({
      ...prevState,
      [name]:
        !!value &&
        passwordMessages.length === 0 &&
        confirmPasswordMessage.length === 0,
    }));
  };

  useEffect(() => {
    const allFieldsFilled = Object.values(filledFields).every(Boolean);
    setIsButtonActive(allFieldsFilled);
  }, [filledFields]);

  const progress = Object.values(filledFields).filter(Boolean).length * 25;
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}/api/otp/`, {
        email: formValues.email,
        // mobile: formValues.mobile,
        // password: formValues.password,
      });
      const responses = await axios.post(`${baseUrl}/api/user/checkuser`, {
        email: formValues.email,
        mobile: formValues.mobile,
        // password: formValues.password,
      });
      const responseData = {
        email: formValues.email,
        mobile: formValues.mobile,
        password: formValues.password,
      };
      localStorage.setItem("userData", JSON.stringify(responseData));

      toast.success("Signup successful!", {
        className: "mob-toast ",
      });

      navigate("otppage");
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message, {
          className: "mob-toast ",
        });
      } else {
        toast.error("An unexpected error occurred", {
          className: "mob-toast ",
        });
      }
    }
  };
  return (
    <div>
      <div className="mob-signup-navbar">
        <img src={backIcon} alt="Back" className="mob-signup-back-icon" onClick={() => navigate(-1)} />
        <p className="mob-signup-title">Create Account</p>
      </div>
      <div className="mob-signup-main-head"></div>
      <div className="mob-signup-main-body">
        <div className="mob-signup-main-steps">
          <div
            className={`mob-signup-main-step mob-signup-main-step-1 ${
              progress >= 25 ? "progress-25" : ""
            }`}
          >
            1
          </div>
          <div
            className={`mob-signup-main-line ${
              progress >= 25 ? "progress-25" : ""
            } ${progress >= 50 ? "progress-50" : ""} ${
              progress >= 75 ? "progress-75" : ""
            } ${progress === 100 ? "progress-100" : ""}`}
          ></div>
          <div
            className={`mob-signup-main-step mob-signup-main-step-2 ${
              progress >= 50 ? "progress-50" : ""
            }`}
          >
            2
          </div>
          <div
            className={`mob-signup-main-step mob-signup-main-step-3 ${
              progress >= 75 ? "progress-75" : ""
            }`}
          >
            3
          </div>
        </div>
      </div>
      <div className="mob-signup-main-login-section">
        <h2 className="mob-signup-main-login-head">
          Sign Up to Manna Delights
        </h2>
        <div className="mob-signup-main-fields-container">
          <div className="mob-signup-main-field">
            <p>Email ID</p>
            <input
              type="email"
              name="email"
              placeholder="Enter your Email"
              onChange={handleInputChange}
            />
          </div>
          <div className="mob-signup-main-field">
            <p>Mobile Number</p>
            <input
              type="text"
              name="mobile"
              placeholder="Enter your Mobile number*"
              onChange={handleInputChange}
            />
            <p className="mob-signup-main-otp-placeholder">
              OTP will be sent to this number
            </p>
          </div>
          <div className="mob-signup-main-field">
            <p>Password</p>
            <input
              type="password"
              name="password"
              placeholder="Enter New Password"
              onChange={handleInputChange}
              className={
                validationMessages.password.length ? "input-error" : ""
              }
            />
            <div className="mob-signup-main-validation-message">
              {validationMessages.password.includes("Add a capital letter") && (
                <div className="mob-signup-main-validation-column">
                  <p>1 Uppercase</p>
                </div>
              )}
              {validationMessages.password.includes("Add a number") && (
                <div className="mob-signup-main-validation-column">
                  <p>1 Numeric</p>
                </div>
              )}
              {validationMessages.password.includes(
                "Add a special character (e.g. @, $, !, %, *, ?, &)"
              ) && (
                <div className="mob-signup-main-validation-column">
                  <p>1 Symbol</p>
                </div>
              )}
              {validationMessages.password.includes(
                "Password must be at least 8 characters long"
              ) && (
                <div className="mob-signup-main-validation-column">
                  <p>8 characters</p>
                </div>
              )}
            </div>
          </div>
          <div className="mob-signup-main-field">
            <p>Confirm Password</p>
            <input
              type="password"
              name="confirmPassword"
              placeholder="Re-Enter Password*"
              onChange={handleInputChange}
              className={
                validationMessages.confirmPassword ? "input-error" : ""
              }
            />
            <p className="mob-signup-main-validation-message">
              {validationMessages.confirmPassword}
            </p>
          </div>
        </div>
        <div className="mob-signup-main-fields-buttons">
          <button
            className={`mob-signup-main-button ${
              isButtonActive ? "active" : "inactive"
            }`}
            type="submit"
            // disabled={!isButtonActive}
            onClick={handleSubmit}
          >
            Sign Up
          </button>
          <p>
            Already have an account? <a href="#">Login</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default MobSignupPage;
