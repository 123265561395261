import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import './FormMain.css';
import MobFormMain from '../../components/Mob/MobForms/MobFormMain';
import Navbardesktop from '../../components/Web/Navbar/Navbardesktop,';


const FormMain = () => {
    const navigate = useNavigate();

    if (isMobile) {
        return <MobFormMain/>;
    }

    return (
        <div>
            <Navbardesktop/>
            <div className="form-main-nav"></div>
            <div className="form-main-container">
                <div className="form-main-content">
                    <button className="close-button-form" onClick={() => navigate('/')}>x</button>
                    <h2 className="form-main-heading">Create Forms</h2>
                    <div className="form-main-buttons">
                        <button className="form-main-button" onClick={() => navigate('/bulk-ordering')}>Bulk Ordering</button>
                        <button className="form-main-button" onClick={() => navigate('/influencer')}>Influencers</button>
                        <button className="form-main-button" onClick={() => navigate('/dealership')}>Dealership</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormMain;
