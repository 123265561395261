import React, { useState } from 'react';
import "./BulkOrderingForm .css";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import MobInfluencerForm from '../../components/Mob/MobForms/MobInfluencerForm';
import Navbardesktop from '../../components/Web/Navbar/Navbardesktop,';

const baseUrl = process.env.REACT_APP_BASEURL;

const InfluencerForm = () => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    email: "",
    name: "",
    instagram: "",
    facebook: "",
    youtube: "",
    collabrationteams: "",
    contact: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const { instagram, facebook, youtube } = formValues;
    return instagram || facebook || youtube;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please provide at least one social channel link.");
      return;
    }

    try {
      await axios.post(
        `${baseUrl}/api/products/influencer`,
        formValues
      );
      toast.success("Created successfully");
      setFormValues({
        email: "",
        name: "",
        contact: "",
        collabrationteams: "",
        instagram: "",
        facebook: "",
        youtube: "",
      });
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  return isMobile ? (
    <MobInfluencerForm />
  ) : (
    <div>
      <Navbardesktop/>
      <div className="form-container">
        <h3 className="form-header-h3">Influencer Form</h3>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="influencer-name">Name:</label>
            <input
              type="text"
              id="influencer-name"
              name="name"
              value={formValues.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="influencer-email">Email:</label>
            <input
              type="email"
              id="influencer-email"
              name="email"
              value={formValues.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="influencer-contact">Contact:</label>
            <input
              type="text"
              id="influencer-contact"
              name="contact"
              value={formValues.contact}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group social-links-group">
            <label>Social Media Links:</label>
            <div className="social-links">
              <div className="social-link">
                <input
                  type="url"
                  id="influencer-instagram"
                  name="instagram"
                  value={formValues.instagram}
                  onChange={handleInputChange}
                  placeholder="Instagram"
                />
              </div>
              <div className="social-link">
                <input
                  type="url"
                  id="influencer-facebook"
                  name="facebook"
                  value={formValues.facebook}
                  onChange={handleInputChange}
                  placeholder="Facebook"
                />
              </div>
              <div className="social-link">
                <input
                  type="url"
                  id="influencer-youtube"
                  name="youtube"
                  value={formValues.youtube}
                  onChange={handleInputChange}
                  placeholder="YouTube"
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="influencer-comments">Collaboration Terms:</label>
            <textarea
              id="influencer-comments"
              name="collabrationteams"
              value={formValues.collabrationteams}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-actions">
            <button type="submit">Submit</button>
            <button type="button" className="close-button-secondary" onClick={() => navigate(-1)}>Close</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default InfluencerForm;
