import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import carticon from "../../images/Desktop-icons/carticon.svg";
import "./MenuPage.css";
import PopupDine from "../PopupDine/PopupDine";
import { useNavigate } from "react-router-dom";
import navmainlogo from "../../images/bottom-logo.svg";
import usericon from "../../images/Desktop-icons/user-icon.svg";
import eeee from "../../images/p-3.png";

import iceicon from "../../images/Desktop-icons/ice-cream-food-and-restaurant-usericon.svg";
import axios from "axios";
import { isMobile } from "react-device-detect";
import MobMenupage from "../../components/Mob/MobMenu/MobMenupage";
import { toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASEURL;

const MenuPage = () => {
  const navigate = useNavigate();
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  console.log(userData?.user, "jjj");
  const [funheading, setfunheading] = useState([]);
  const [variationheading, setvariationheading] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [data, setData] = useState([]);

  const [funtoppings, setFuntoppings] = useState([]);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [selectedToppings, setSelectedToppings] = useState([]);
  const [totalprice, setTotalprice] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [singleproduct, setsingleProducts] = useState("");
  console.log(singleproduct);
  const [productsdata, setproductsdata] = useState([]);

  const [showDoubleScoopOptions, setShowDoubleScoopOptions] = useState(false);
  const [expandedProducts, setExpandedProducts] = useState({});
  const [popupshow, setpopupshow] = useState(false);
  const [orderType, setOrderType] = useState("Dinein");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [userName, setUserName] = useState("John Doe");
  const [userEmail, setUserEmail] = useState("john.doe@example.com");
  const [newUserName, setNewUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [searchstring, setSearchstring] = useState("");
  const [showPopup, setShowPopup] = useState(true);
  const userIdToCheck = userData?.user?._id;
  const handleScoopChange = (event, variation) => {
    setSelectedVariation(variation);
    setSelectedAttributes([]);
  };

  const handleAttributeChange = (event, attribute) => {
    setSelectedAttributes(event);
  };

  const handleToppingChange = (event, item) => {
    if (event.target.checked) {
      setSelectedToppings([...selectedToppings, item]);
    } else {
      setSelectedToppings(
        selectedToppings.filter((topping) => topping !== item)
      );
    }
  };
  console.log(products, "products");
  const openPopup = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 4000);
  };
  const fetchSubCategories = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/all`);
      const categories = Array.isArray(response.data) ? response.data : [];
      setCategories(categories);
      if (categories.length > 0) {
        const selectedCategoryId = categories[0]._id;
        const responses = await axios.get(
          `${baseUrl}/api/subcategory/category/${selectedCategoryId}`
        );
        const subcategories = responses?.data || [];
        setSubcategories(subcategories);

        setSelectedCategory(selectedCategoryId);
        if (subcategories.length > 0) {
          setSelectedSubcategory(subcategories[0]._id);

          const responsedata = await axios.post(
            `${baseUrl}/api/products/category/${selectedCategoryId}`,
            {
              subcategory: subcategories[0]?._id,
              options: orderType,
            }
          );

          setProducts(responsedata.data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchSubCategories();
  }, [orderType]);
  const fetchDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/user/singleprofile/${userData?.user?._id}`)
      setData(response.data);
    } catch (error) {
      console.error("Network error:", error);
      toast.error("Network error: Unable to fetch details");
    }
  };
useEffect(()=>{
  fetchDetails()
},[])
  const handleAddClick = async (id) => {
    setPopupVisible(true);
    console.log(id, "id");
    const response = await axios.get(`${baseUrl}/api/products/on/${id}`);
    console.log(response.data);
    setsingleProducts(response.data);
    const responses = await axios.get(
      `${baseUrl}/api/funtoppings/category/${selectedCategory}`
    );
    setFuntoppings(responses?.data);
    const variationresponse = await axios.get(
      `${baseUrl}/api/variation/allon/${selectedCategory}`
    );
    setvariationheading(variationresponse?.data);
    const funtoppingsresponse = await axios.get(
      `${baseUrl}/api/funtoppings/allontoppinsheading/${selectedCategory}`
    );
    setfunheading(funtoppingsresponse?.data);
  };

  const handleThreedots = () => {
    setpopupshow(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  const handleCategoryClick = async (category) => {
    try {
      setSelectedCategory(category);

      const response = await axios.post(
        `${baseUrl}/api/products/category/${category}`,
        {
          subcategory: selectedSubcategory,
          options: orderType,
        }
      );
      setProducts(response.data);
      const productIds = products.map((product) => product._id);
      const userId = userData?.user?._id;

      const allresponses = await axios.get(
        `${baseUrl}/api/subcategory/category/${category}`
      );
      setSubcategories(allresponses?.data);
      console.log(allresponses?.data, "datas");

      const responses = await axios.get(
        `${baseUrl}/api/funtoppings/category/${category}`
      );
      setFuntoppings(responses?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubcategory = async (category, id) => {
    setSelectedSubcategory(category);
    const response = await axios.post(
      `${baseUrl}/api/products/subcategory/${category}`,
      {
        options: orderType,
        category: selectedCategory,
      }
    );

    setProducts(response.data);
    const responses = await axios.get(
      `${baseUrl}/api/funtoppings/category/${id}`
    );
    setFuntoppings(responses?.data);
  };

  const toggleExpandProduct = (index) => {
    setExpandedProducts((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };



  const getTotalPrice = (price) => {
    let totalPrice = selectedVariation ? selectedVariation?.price+price : price;
    let halfVariationPrice = selectedVariation
      ? selectedVariation.price / 2
      : 0;

    if (Object.keys(selectedAttributes).length > 0) {
      halfVariationPrice += selectedAttributes.price || 0;
      selectedToppings.forEach((topping) => {
        halfVariationPrice += parseInt(topping.price);
      });

      return halfVariationPrice;
    } else {
      selectedToppings.forEach((topping) => {
        totalPrice += topping.price;
      });
      return totalPrice;
    }
  };


  const handleAddtocart = async () => {
    // if (!selectedVariation) {
    //   alert("Please select variant");
    // }
    const totalPrice = getTotalPrice(singleproduct?.price);

    try {
      const selectedToppingIds = selectedToppings?.map(
        (topping) => topping._id
      );
      const response = await axios.post(`${baseUrl}/api/cart/`, {
        userId: userData?.user?._id,
        productId: singleproduct?._id,
        quantity: 1,
        productdetails: {
          price: singleproduct?.price,
          selectedvariation: selectedVariation,
          selectedAttributes: selectedAttributes,
          funtoppings: selectedToppingIds,
        },
        totalPrice: totalPrice,
      });
      const responses = await axios.post(
        `${baseUrl}/api/cart/products/${singleproduct?._id}`,
        {
          userId: userData?.user?._id,
          quantity: 1,
        }
      );
      const data = await axios.post(
        `${baseUrl}/api/products/subcategory/${selectedSubcategory}`,
        {
          options: orderType,
          category: selectedCategory,
        }
      );
      openPopup();
      setPopupVisible(false);
    } catch (err) {}
  };
  const handleProfileClick = () => {
    setShowDropdown(false);
    setShowProfilePopup(true);
  };

  const closeProfilePopup = () => {
    setShowProfilePopup(false);
  };


  const handleDecrementClick = async (id) => {
    try {
      const response = await axios.post(`${baseUrl}/api/cart/${id}/decrement`, {
        userId: userIdToCheck,
      });

      if (response.data.success) {
        toast.success("Quantity decreased successfully!");
        fetchSubCategories();
      } else {
        toast.error("Failed to decrease quantity");
      }
    } catch (error) {
      console.error("Error decrementing quantity:", error);
      toast.error("An error occurred while decreasing quantity");
    }
  };

  const handleIncrementClick = async (id) => {
    try {
      const response = await axios.post(`${baseUrl}/api/cart/${id}/increment`, {
        userId: userIdToCheck,
      });
      if (response.data.success) {
        fetchSubCategories();
        toast.success("Quantity increased successfully!");
      } else {
        toast.error("Failed to increase quantity");
      }
    } catch (error) {
      console.error("Error incrementing quantity:", error);
      toast.error("An error occurred while increasing quantity");
    }
  };

  const handleSaveChanges = async () => {
    try {
      const response = await axios.put(`${baseUrl}/api/user/allprofile/${userData?.user?._id}`, {
        "firstname": newUserName,
        "lastname": newPassword,
        "middlename": newUserEmail,
      });
      setShowProfilePopup(false)
    } catch (error) {
    }
  };
  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSearchstring(value);
    console.log("Search string:", value);
    try {
      const response = await axios.post(`${baseUrl}/api/products/search/all`, {
        searchString: value,
      });
      setProducts(response.data);
    } catch (error) {
      console.error(
        "Search request error:",
        error.response?.data || error.message
      );
    }
  };
  const handleOrderTypeSelect = (type) => {
    setOrderType(type);
    console.log(type, "uuuu");

    setShowPopup(false);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleOrderTypeChange = async (type) => {
    setOrderType(type);
    try {
      const response = await axios.post(
        `${baseUrl}/api/products/subcategory/${selectedSubcategory}`,
        {
          options: orderType,
          category: selectedCategory,
        }
      );
      setProducts(response.data);
    } catch (error) {}
  };
  const handlelogout = () => {
    const item = localStorage.removeItem("userData");
    navigate("/");
  };

  return isMobile ? (
    <>
      <MobMenupage />
    </>
  ) : (
    <div className="menu-page">
      <nav className="navbar">
        <div className="navbar-left">
          <img
            src={navmainlogo}
            alt="Logo"
            className="navbar-logo"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="navbar-center">
          <ul className="navbar-links">
            <li className="navbar-links-head">
              <p onClick={() => navigate("/")}>Home</p>
            </li>
            <li className="navbar-links-head">
              <p onClick={() => navigate("/cart")}>Cart</p>
            </li>
            <li className="navbar-links-head">
              <p onClick={() => navigate("/order")}>Order</p>
            </li>
            <li className="navbar-links-head">
              <p onClick={() => navigate("/blog")}>BLOG</p>
            </li>
            <li className="navbar-links-head">
              <a href="/contact">CONTACT</a>
            </li>
          </ul>
        </div>
        <div className="navbar-right">
       
          <input
            type="text"
            placeholder="Search"
            className="navbar-search"
            value={searchstring}
            onChange={handleSearchChange}
          />
               <div
            className="navbar-user"
            >
             <img className="navbar-user-icon"  src={carticon} onClick={() => navigate("/cart")} alt="user" />
 
            </div>
          <div
            className="navbar-user"
            onMouseEnter={() => setShowDropdown(true)}
            onMouseLeave={() => setShowDropdown(false)}
          >
            <img className="navbar-user-icon" src={usericon} alt="user" />
            {showDropdown && (
              <div className="user-dropdown">
                <p className="user-dropdown-p" onClick={handleProfileClick}>
                  Profile
                </p>
                <p className="user-dropdown-p" onClick={() => navigate("/order")}>Orders</p>
                {/* <p className="user-dropdown-p" onClick={() => navigate("/notifications")}>Notifications</p> */}
                <p className="user-dropdown-p" onClick={handlelogout}>
                  Logout
                </p>
              </div>
            )}
          </div>
        </div>
      </nav>
      {showToast && (
        <div className="toast-message">Item has been added to your cart!</div>
      )}
      <div className="menu-content">
        <div className="sidebar">
          <div className="main-categories">
            {categories?.map((item, index) => (
              <div
                key={index}
                className={`main-category ${
                  selectedCategory === item?._id ? "selected" : ""
                }`}
                onClick={() => handleCategoryClick(item?._id)}
              >
                <p>{item?.category}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="menu-body">
          <h1 className="menu-title">What's on your mind?</h1>

          {/* <div className="order-type-selection">
            <button
              className={`order-type-button ${
                orderType === "Dinein" ? "selected" : ""
              }`}
              onClick={() => handleOrderTypeChange("Dinein")}
            >
              <img src={dinein} alt="Dine In" className="order-type-icon" />
              Dine In
            </button>
            <button
              className={`order-type-button ${
                orderType === "Takeaway" ? "selected" : ""
              }`}
              onClick={() => handleOrderTypeChange("Takeaway")}
            >
              <img src={takeAway} alt="Take Away" className="order-type-icon" />
              Take Away
            </button>
          </div> */}

          <div className="menu-categories">
            <div className="category-list">
              {Array.isArray(subcategories) ? (
                subcategories?.map((category, index) => (
                  <div
                    key={index}
                    className={`category-item ${
                      selectedSubcategory === category?._id ? "selected" : ""
                    }`}
                    onClick={() =>
                      handleSubcategory(category?._id, category?.category?._id)
                    }
                  >
                    <img
                      src={category?.image}
                      alt={category?.subcategory}
                      className="category-image"
                    />
                    <p>{category?.subcategory}</p>
                  </div>
                ))
              ) : (
                <p>No categories available</p>
              )}
            </div>
          </div>

          <div className="menu-icecream-list">
  {products?.map((icecream, index) => {
    const isExpanded = expandedProducts[index];
    const isOutOfStock = icecream?.stock === "False" || icecream?.stock === 0;

    return (
      <div key={index} className={`menu-icecream-item ${isOutOfStock ? "out-of-stock" : ""}`}>
        {isOutOfStock && (
          <div className="menu-icecream-out-of-stock-banner">Out of Stock</div>
        )}
        <img
          src={icecream?.image}
          alt={icecream?.productname}
          className="menu-icecream-image"
        />
        <h2 className={`menu-icecream-name ${isExpanded ? "expanded" : ""}`}>
          {icecream?.productname}
        </h2>
        {icecream?.productname?.length > 10 && (
          <button
            onClick={() => toggleExpandProduct(index)}
            className="menu-icecream-toggle"
          >
            {isExpanded ? "See Less" : "See More"}
          </button>
        )}
        <p className="menu-front-price">${icecream?.price}</p>
        
        {userData?.user ? (
          <>
            {icecream?.userIds?.find((user) => user.userId === userIdToCheck) ? (
              <div className="menu-cart-controls">
                <motion.button
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 1.1 }}
                  className="menu-decrement-button"
                  onClick={() => handleDecrementClick(icecream?._id)}
                  disabled={isOutOfStock}
                >
                  -
                </motion.button>
                <p>
                  {
                    icecream.userIds.find(
                      (user) => user.userId === userIdToCheck
                    )?.quantity
                  }
                </p>
                <span className="menu-cart-quantity">{icecream.quantity}</span>
                <motion.button
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 1.1 }}
                  className="menu-increment-button"
                  onClick={() => handleIncrementClick(icecream?._id)}
                  
                  disabled={isOutOfStock}
                >
                  +
                </motion.button>
              </div>
            ) : (
              <motion.button
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 1.1 }}
                className="menu-add-cart-button"
                onClick={() => handleAddClick(icecream?._id)}
                disabled={isOutOfStock}
              >
                ADD
              </motion.button>
            )}
          </>
        ) : (
          <>
            <motion.button
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 1.1 }}
              className="menu-add-cart-button"
              onClick={() => {
                navigate("/login");
              }}
              disabled={isOutOfStock}
            >
              ADD
            </motion.button>
          </>
        )}
      </div>
    );
  })}
  <PopupDine
    showPopup={showPopup}
    handleOrderTypeSelect={handleOrderTypeSelect}
  />
</div>

        </div>
      </div>

      {popupVisible && (
        <div className="menu-pop-overlay">
          <div className="menu-pop-content">
            <div className="menu-pop-header">
              <h2 className="menu-pop-header-name">
                {singleproduct?.productname}
              </h2>
              <button onClick={closePopup} className="menu-pop-skip-button">
                Close
              </button>
            </div>

            <div className="menu-pop-section">
              
                <h3  className="menu-pop-option-head">
                  {singleproduct?.variationheading}
                </h3>
             

              <div className="menu-pop-section">
                {singleproduct?.variations?.map((item, index) => (
                  <div key={index} className="menu-pop-option">
                    <p>{item?.variationname}</p>
                    <div className="menu-pop-option-right-check">
                      <p>+{item?.price}</p>
                      <input
                        type="radio"
                        name="scoop"
                        value={item?.variationname}
                        onChange={(e) => handleScoopChange(e, item)}
                      />
                    </div>
                  </div>
                ))}
              </div>

              {selectedVariation &&
                selectedVariation?.attributes?.length > 0 && (
                  <div className="menu-pop-sectionscoop">
                    <h3 className="menu-pop-option-head">
                        Options
                    </h3>
                    {selectedVariation.attributes.map(
                      (attribute, attrIndex) => (
                        <div key={attrIndex} className="menu-pop-option">
                          <p>{attribute?.name}</p>
                          <div className="menu-pop-option-right-check">
                            {attribute?.price==0?<>
                            <p>Free</p>
                            </>:<>
                            <p>+{attribute?.price}</p>

                            </>}
                            <input
                              name="attribute"
                              type="radio"
                              value={attrIndex}
                              onChange={(e) => handleAttributeChange(attribute)}
                            />
                          </div>
                        </div>
                      )
                    )}
                  </div>
                )}
            </div>

            <div className="menu-pop-section">
              <div className="menu-pop-option-headsec">
              <h3 className="menu-pop-option-head">
                {singleproduct?.funtoppinsheading}
              </h3>
</div>
              {singleproduct?.funtoppinsList?.map((item, index) => (
                <div key={index} className="menu-pop-option">

                  <img src={item?.image} className="menu-pop-option-image"/>
                  <p className="ptag-menu-pop-option">{item?.name}</p>

                  <div className="menu-pop-option-right-check">
                    {item?.price==0?<>
                      <span>Free</span>
                      </>:<>
                    <span>+ ₹{item?.price}</span>
                    </>}
                    <input
                      type="checkbox"
                      onChange={(e) => handleToppingChange(e, item)}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="menu-pop-footer">
              <div className="menu-pop-footer-main">
                <h3>Total Price:</h3>
                <h4>₹{getTotalPrice(singleproduct?.price)}</h4>
              </div>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 1.1 }}
                className="menu-pop-continue-button"
                onClick={handleAddtocart}
              >
                CONTINUE
              </motion.button>
            </div>
          </div>
        </div>
      )}

      {showProfilePopup && (
        <div className="profile-popup-overlay" onClick={closeProfilePopup}>
          <div className="profile-popup" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeProfilePopup}>
              Skip
            </button>
            <div>
              <img src={iceicon} alt="user" className="profile-icon" />
            </div>
            <h2 className="profile-icon-head">Edit Profile</h2>
            <form className="profile-form">
              <label>
              Firstname:
                <input
                  type="text"
                  placeholder={data?.firstname}
                  value={newUserName}
                  onChange={(e) => setNewUserName(e.target.value)}
                />
              </label>
              <label>
              Middlename:
                <input
                  type="text"
                  placeholder={data?.middlename}

                  value={newUserEmail}
                  onChange={(e) => setNewUserEmail(e.target.value)}
                />
              </label>
              <label>
              Lastname:
                <input
                  type="text"
                  placeholder={data?.lastname}

                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </label>
            
              <div className="profile-buttons">
                <button
                  type="button"
                  className='save-button'
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </button>
                <button
                  type="button"
                  className="cancel-button"
                  onClick={closeProfilePopup}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuPage;
