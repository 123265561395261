import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./BulkOrderingForm .css";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { isMobile } from "react-device-detect";
import Select from "react-select"; 
import MobBulkOrderingForm from "../../components/Mob/MobForms/MobBulkOrderingForm";
import Navbardesktop from "../../components/Web/Navbar/Navbardesktop,";
const baseUrl = process.env.REACT_APP_BASEURL;

const BulkOrderingForm = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [formValues, setFormValues] = useState({
    email: "",
    name: "",
    details: "",
    selectedProducts: [], 
  });
console.log(formValues,'formValues');

  const fetchSubCategories = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/products/allbulkproducts/on`);
      const categories = Array.isArray(response.data) ? response.data : [];
      setProducts(categories);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchSubCategories();
  }, []); 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleProductChange = (selectedOptions) => {
    setFormValues((prevState) => ({
      ...prevState,
      selectedProducts: selectedOptions.map((option) => {
        const product = products.find(p => p.productname === option.value);
        return {
          product: option.value,
          quantity: product.minQuantity || 1,  // Set to minQuantity or default to 1
          quantityType: "piece" // default to "piece"
        };
      }),
    }));
  };
  
  const handleQuantityChange = (index, quantity) => {
    const updatedProducts = [...formValues.selectedProducts];
    const minQuantity = products.find(p => p.productname === updatedProducts[index].product)?.minQuantity || 1;
    updatedProducts[index].quantity = Math.max(quantity, minQuantity); // Enforce minQuantity
    setFormValues((prevState) => ({
      ...prevState,
      selectedProducts: updatedProducts,
    }));
  };
  

  const handleQuantityTypeChange = (index, quantityType) => {
    const updatedProducts = [...formValues.selectedProducts];
    updatedProducts[index].quantityType = quantityType;
    setFormValues((prevState) => ({
      ...prevState,
      selectedProducts: updatedProducts,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${baseUrl}/api/products/bulkorderform`,
        {
          email: formValues.email,
          name: formValues.name,
          details: formValues.details,
          products: formValues.selectedProducts, 
        }
      );
      toast.success("Created successfully");
      setFormValues({
        email: "",
        name: "",
        details: "",
        selectedProducts: [],
      });
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  const quantityTypeOptions = [
    { label: "Piece", value: "piece" },
    { label: "Box", value: "box" }
  ];

  return isMobile ? (
    <MobBulkOrderingForm />
  ) : (
    <div>
      <ToastContainer />
      <Navbardesktop />
      <div className="form-container">
        <div className="form-header"></div>
        <h3 className="form-header-h3">Bulk Ordering Form</h3>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="bulk-name">Name:</label>
            <input
              type="text"
              id="bulk-name"
              name="name"
              value={formValues.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="bulk-email">Email:</label>
            <input
              type="email"
              id="bulk-email"
              name="email"
              value={formValues.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="bulk-product">Select Products:</label>
            <Select
              id="bulk-product"
              name="products"
              options={products.map((product) => ({
                label: product.productname,
                value: product.productname,
              }))}
              isMulti
              onChange={handleProductChange}
              value={formValues.selectedProducts.map((product) => ({
                label: product.product,
                value: product.product,
              }))}
              required
            />
          </div>

          {formValues.selectedProducts.map((product, index) => {
  const minQuantity = products.find(p => p.productname === product.product)?.minquantity || 1;
  return (
    <div key={index} className="form-group">
      <label htmlFor={`product-quantity-${index}`}>
        Quantity for {product.product} (Min: {minQuantity}):
      </label>
      <input
        type="number"
        id={`product-quantity-${index}`}
        name="quantity"
        value={product.quantity}
        onChange={(e) => handleQuantityChange(index, e.target.value)}
        min={minQuantity}
        required
      />
      <label htmlFor={`quantity-type-${index}`}>Type:</label>
      <Select
        id={`quantity-type-${index}`}
        options={quantityTypeOptions}
        onChange={(option) => handleQuantityTypeChange(index, option.value)}
        value={quantityTypeOptions.find(option => option.value === product.quantityType)}
        required
      />
    </div>
  );
})}

          <div className="form-group">
            <label htmlFor="bulk-details">Order Details:</label>
            <textarea
              id="bulk-details"
              name="details"
              value={formValues.details}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-actions">
            <button type="submit">Submit</button>
            <button
              type="button"
              className="close-button-secondary"
              onClick={() => navigate(-1)}
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BulkOrderingForm;
