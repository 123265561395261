import React, { useEffect, useState } from "react";
import "./MobHeader.css";
import { motion, AnimatePresence } from "framer-motion";
import toplogo from "../../../images/top-logo.svg";
import icecreammainimage from "../../../images/Group 72.png";
import menuIcon from "../../../images/menu-alt-02-svgrepo-com (1).svg";
import profileIcon from "../../../images/Desktop-icons/ice-cream-food-and-restaurant-usericon.svg";
import { useNavigate } from "react-router-dom";
import user1 from "../../../images/Desktop-icons/user-hand-up-.svg";
import axios from "axios";
import { toast } from "react-toastify";

const baseUrl = process.env.REACT_APP_BASEURL;

const MobHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [newUserName, setNewUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  const [data, setData] = useState([]);
  const fetchDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/user/singleprofile/${userData?.user?._id}`)
      setData(response.data);
    } catch (error) {
      console.error("Network error:", error);
      toast.error("Network error: Unable to fetch details");
    }
  };
useEffect(()=>{
  fetchDetails()
},[])
  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };
  const handleLoginClick = () => {
    navigate("/login");
  };
  const toggleProfilePopup = () => {
    setShowProfilePopup((prev) => !prev);
  };

  const handlePasswordChange = (e) => {
    const newPasswordValue = e.target.value;
    setNewPassword(newPasswordValue);

    let errors = [];

    if (!/\d/.test(newPasswordValue)) {
      errors.push("1 Number");
    }

    if (!/[!@#$%^&*]/.test(newPasswordValue)) {
      errors.push("1 Special Char");
    }

    if (!/[A-Z]/.test(newPasswordValue)) {
      errors.push("1 Uppercase");
    }

    if (newPasswordValue.length < 8) {
      errors.push("8 Chars");
    }

    if (errors.length > 0) {
      setPasswordError(`Missing ${errors.join(", ")}`);
    } else {
      setPasswordError("");
    }
  };

  const handleSaveChanges = async () => {
    try {
      const response = await axios.put(`${baseUrl}/api/user/allprofile/${userData?.user?._id}`, {
        "firstname": newUserName,
        "lastname": newPassword,
        "middlename": newUserEmail,
      });
      setShowProfilePopup(false)
    } catch (error) {
    }
  };
  const handleBulkOrderClick = () => {
    setIsPopupOpen(!isPopupOpen); 
  };
  return (
    <div className="mob-header">
      <div className="mob-navbar">
      <div id="nav-icon" className={isMenuOpen ? "open" : ""} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <img src={toplogo} className="mob-top-logo" alt="Top Logo" />
        {/* <motion.button className="mob-order-button" whileHover={{ scale: 1.1 }}>
          Order
        </motion.button> */}
             {userData?.user ? (
          <>
            <motion.button
 className="mob-order-button"              whileHover={{ scale: 1.1 }}
              onClick={handleBulkOrderClick}
            >
              <img src={user1} className="top-user-logo" alt="User Logo" />
              {userData?.user?.firstname}
            </motion.button>
          </>
        ) : (
          <>
            <motion.button
 className="mob-order-button"              whileHover={{ scale: 1.1 }}
              onClick={handleLoginClick}
            >
              <img src={user1} className="top-user-logo" alt="User Logo" />
              Login
            </motion.button>
          </>
        )}
      </div>
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            className="mob-nav-links"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
           
            <motion.div className="mob-nav-item" whileHover={{ scale: 1.1 }} onClick={()=>{navigate("/about")}}>
              <p className="mob-nav-link">ABOUT US</p>
            </motion.div>
            <motion.div className="mob-nav-item" whileHover={{ scale: 1.1 }} onClick={()=>{navigate("/menu")}}>
              <p className="mob-nav-link">PRODUCTS</p>
            </motion.div>
            <motion.div className="mob-nav-item" whileHover={{ scale: 1.1 }} onClick={()=>{navigate("/blog")}}>
              <p className="mob-nav-link">OUR STORY</p>
            </motion.div>
            <motion.div className="mob-nav-item" whileHover={{ scale: 1.1 }} onClick={()=>{navigate("/order")}}>
              <p className="mob-nav-link">ORDERS</p>
            </motion.div>
            <motion.div className="mob-nav-item" whileHover={{ scale: 1.1 }} onClick={()=>{navigate("/contact")}}>
              <p className="mob-nav-link">CONTACT US</p>
            </motion.div>
            <motion.div className="mob-nav-item" whileHover={{ scale: 1.1 }}>
              <p className="mob-nav-link" onClick={toggleProfilePopup}>PROFILE</p>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <div className="mob-header-content">
      <motion.img
  src={icecreammainimage}
  className="mob-ice-cream-image"
  alt="Ice Cream"
  initial={{ scale: 1 }}
  animate={{ scale: [1, 1.1, 1.05, 1.1, 1] }}
  transition={{
    duration: 3,
    times: [0, 0.3, 0.35, 0.65, 1],
    ease: "easeInOut",
    repeat: Infinity,
    repeatType: "mirror"
  }}
/>

        <div className="mob-header-text">
          <motion.h1 className="mob-main-text" whileHover={{ scale: 1.1 }}>
            Make your sweet dream come true.
          </motion.h1>
          <p className="mob-sub-text">
            Dive into a frozen paradise and let our ice cream take you to a land of pure ecstasy.
          </p>
          <motion.div
            className="mob-discover-button"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
          >
            DISCOVER NOW
          </motion.div>
        </div>
      </div>
      {showProfilePopup && (
        <div className="mob-profile-popup-overlay">
          <div className="mob-profile-popup-edit">
            <button className="close-button" onClick={toggleProfilePopup}>
              Skip
            </button>
            <img src={profileIcon} alt="Profile" className="profile-icon" />
            <h2 className="profile-icon-head">Edit Profile</h2>
            <form className="profile-form">
              <label>
              Firstname:
                <input
                  type="text"
                  placeholder={data?.firstname}
                  value={newUserName}
                  onChange={(e) => setNewUserName(e.target.value)}
                />
              </label>
              <label>
              Middlename:
                <input
                  type="email"
                  placeholder={data?.middlename}

                  value={newUserEmail}
                  onChange={(e) => setNewUserEmail(e.target.value)}
                />
              </label>
              <label>
              Lastname:
                <input
                  placeholder={data?.lastname}

                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </label>
           
              <div className="profile-buttons">
                <button
                  type="button"
                  className="save-button"
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </button>
                <button
                  type="button"
                  className="cancel-button"
                  onClick={toggleProfilePopup}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobHeader;
