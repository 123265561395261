import React from "react";
import { useNavigate } from "react-router-dom";  // Import useNavigate hook
import "./MobFooter.css";

import sideborder from "../../../images/Mask Group 10.svg";
import downborder from "../../../images/Mask Group 8.svg";
import leftborder from "../../../images/Mask Group 11.svg";
import leftsideborder from "../../../images/Mask Group 9.svg";
import facebookicon from "../../../images/Group 70.svg";
import instaicon from "../../../images/Group 69.svg";
import linkicon from "../../../images/Group 68.svg";
import footmainlogo from "../../../images/bottom-logo.svg";

const MobFooter = () => {
  const navigate = useNavigate();  // Initialize the useNavigate hook

  // Function to handle navigation based on link clicked
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="Mob-mainfooter">
      <img src={sideborder} alt="Side Border" className="Mob-sideborder" />
      <img src={downborder} alt="Down Border" className="Mob-downborder" />
      <img src={leftborder} alt="Left Border" className="Mob-leftborder" />
      <img
        src={leftsideborder}
        alt="Left Side Border"
        className="Mob-leftsideborder"
      />

      <div className="Mob-footer-center-section">
        <img src={footmainlogo} alt="Main Logo" className="Mob-footmainlogo" />
        <p className="Mob-footer-center-contacttext">CONTACT US</p>
        <p className="Mob-footer-center-contactnumber">03 059 076 312</p>
        <p className="Mob-footer-centersec-contactnumber">01 526 734 996</p>
        <p className="Mob-footer-center-contactcontent">
          manager@mannadelight.com
        </p>
      </div>

      <div className="Mob-footer-middle-section">
        <div className="Mob-footer-left-section">
          <p className="Mob-footer-left-sectionp">
            Manna Delight Newlands N3 2AX
          </p>
          <div className="Mob-footer-social-icons">
            <img
              src={facebookicon}
              alt="Facebook"
              className="Mob-social-foot-icon"
            />
            <img
              src={instaicon}
              alt="Instagram"
              onClick={() => window.location.href = 'https://www.instagram.com/manna_delights_ice_cream/'}
              className="Mob-social-foot-icon"
            />
            <img src={linkicon} alt="Link" className="Mob-social-foot-icon" />
          </div>
        </div>

        <div className="Mob-footer-right-section">
          <h4 className="Mob-footer-right-sectionhead">QUICK LINKS</h4>
          <p className="Mob-footer-right-sectionp" onClick={() => handleNavigation('/career')}>
            Careers
          </p>
          <p className="Mob-footer-right-sectionp" onClick={() => handleNavigation('/terms')}>
            Terms
          </p>
          <p className="Mob-footer-right-sectionp" onClick={() => handleNavigation('/policy')}>
            Policy
          </p>
          <p className="Mob-footer-right-sectionp" onClick={() => handleNavigation('/training')}>
            Training
          </p>
        </div>
      </div>

      <div className="Mob-footer-copyright">
        <hr />
        <p>MANNA DELIGHT - © 2024 ALL RIGHTS RESERVED</p>
      </div>
    </div>
  );
};

export default MobFooter;
