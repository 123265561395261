import React, { useState } from "react";
import "./MobOtpLoginPage.css";
import backIcon from "../../../images/Mobile-icons/Backbutton.svg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const baseUrl = process.env.REACT_APP_BASEURL;

const MobOtpLoginPage = () => {
  const navigate = useNavigate();

  const [otp, setOtp] = useState(new Array(6).fill(""));
  const storedData = localStorage.getItem("userData");
  const userData = JSON.parse(storedData);
  console.log(userData, "userData");
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const handleSubmit = async () => {
    const otparray = otp.join("");
    try {
      const response = await axios.post(`${baseUrl}/api/otp/verify`, {
        email: userData?.email,
        otp: otparray,
        // password: formValues.password,
      });
      console.log(response.data.message);
      toast.success(response.data.message);
      navigate("/signup/otppage/personal");
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  return (
    <div className="mob-otp-sign-main">
      <div className="mob-signup-navbar">
        <img src={backIcon} alt="Back" className="mob-signup-back-icon" onClick={() => navigate(-1)}/>
        <p className="mob-signup-title">Verification</p>
      </div>

      <div className="mob-otp-sign-main-head"></div>
      <div className="mob-otp-sign-main-body">
        <div className="mob-otp-sign-main-steps">
          <div className="mob-otp-sign-main-step mob-otp-sign-main-step-1">
            1
          </div>
          <div className="mob-otp-sign-main-step mob-otp-sign-main-step-2">
            2
          </div>
          <div className="mob-otp-sign-main-step mob-otp-sign-main-step-3">
            3
          </div>
          <div className="mob-otp-sign-main-line"></div>
        </div>
      </div>
      <div className="mob-otp-sign-main-second-section">
        <div className="mob-otp-sign-main-otp-section">
          <h4 className="mob-otp-sign-main-otp-section-head">
          OTP sent to your registered contact information.
          </h4>
          <p className="mob-otp-sign-main-otp-section-p">
            Please enter the OTP you have received on your registered contact information.
          </p>
        </div>
        <div className="mob-otp-sign-main-otp-inputs">
          <p className="mob-otp-sign-main-otp-head">Enter the OTP</p>
          <div className="mob-otp-sign-main-otp-columns">
            {otp.map((data, index) => {
              return (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  className="mob-otp-input"
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onFocus={(e) => e.target.select()}
                />
              );
            })}
          </div>
          <div className="mob-otp-sign-main-otp-resend">
            <p>
              Didn't receive an OTP? <a href="#">Resend</a>
            </p>
          </div>
        </div>
        <div className="mob-otp-sign-main-submit">
          <button
            onClick={handleSubmit}
            className="mob-main-submit-button"
            disabled={otp.join("").length !== 6}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobOtpLoginPage;
