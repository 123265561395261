import React, { useState, useEffect } from "react";
import "./MobContactUs.css";
import axios from "axios";
import backbutton from "../../../images/Mobile-icons/Backbutton.svg";
import settingmob from "../../../images/Mobile-icons/settings-svgrepo.svg";
import menu from "../../../images/Desktop-icons/menu-svg.svg";
import aboutus from "../../../images/Desktop-icons/about-svgrepo-com.svg";
import blogs from "../../../images/Desktop-icons/blog.svg";
import contactus from "../../../images/Desktop-icons/contact-svg.svg";
import helpImage from "../../../images/Desktop-icons/about-svgrepo-com.svg";
import phoneImage from "../../../images/Desktop-icons/phone-svgrepo.svg";
import MobFooter from "../MobFooter/MobFooter";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

const baseUrl = process.env.REACT_APP_BASEURL;

const MobContactUs = () => {
  const [isContactFormOpen, setContactFormOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [issue, setIssue] = useState("");
  const [questions, setQuestions] = useState("");
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [blogData, setBlogData] = useState([]); 
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/blog/alloncontactus`);
        setBlogData(response.data);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };
    fetchBlogData();
  }, []);

  const toggleContactForm = () => {
    setContactFormOpen(!isContactFormOpen);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleIssueChange = (e) => {
    setIssue(e.target.value);
  };

  const handleQuestionsChange = (e) => {
    setQuestions(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email && issue && questions) {
      console.log("Form submitted:", { email, issue, questions });
      setContactFormOpen(false);
    } else {
      alert("Please fill out all required fields.");
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <div className="mob-about-navbar">
        <img src={backbutton} alt="Back" className="mob-contact-back-button" onClick={() => navigate(-1)}/>
        <div className="mob-contact-title">CONTACT US</div>
        <img
          src={settingmob}
          alt="Menu"
          className="mob-contact-menu-icon"
          onClick={toggleMenu}
        />
      </div>

      <PopupMenu isOpen={isMenuOpen} onClose={toggleMenu} />

      <div className="mob-contact-help-section">
        <div className="mob-contact-help-content">
          <div className="mob-contact-help-left">
            <img
              src={helpImage}
              alt="Help Center"
              className="mob-contact-help-image"
            />
            <div>
              <p>Please get in touch and we will be happy to help you</p>
            </div>
          </div>
          <div className="mob-contact-help-right">
            <button
              className="mob-contact-write-to-us"
              onClick={toggleContactForm}
            >
              Write to Us
            </button>
            <div className="mob-contact-phone-contact">
              <img
                src={phoneImage}
                alt="Phone"
                className="mob-contact-phone-image"
              />
              <span className="mob-contact-phone-number">01 526 734 996</span>
            </div>
          </div>
        </div>
      </div>

      <div className="mob-contact-about-us-section">
        {blogData.map((item, index) => (
          <div className="mob-contact-content-block" key={index}>
            <h2>{item.title}</h2>
            <p>{item.description}</p>
            {item.imageUrl && (
              <img
                src={item.imageUrl}
                alt={item.title}
                className="mob-contact-blog-image"
              />
            )}
          </div>
        ))}
      </div>

      {isContactFormOpen && (
        <div className="mob-contact-aboutus-popup">
          <div className="mob-contact-aboutus-popup-content">
            <span
              className="mob-contact-aboutus-close"
              onClick={toggleContactForm}
            >
              &times;
            </span>
            <h2>Contact Us</h2>
            <form onSubmit={handleSubmit}>
              <div className="mob-contact-aboutus-form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  className="mob-contact-aboutus-input"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
              </div>
              <div className="mob-contact-aboutus-form-group">
                <label htmlFor="issue">How can we help you?</label>
                <select
                  id="issue"
                  className="mob-contact-aboutus-select"
                  value={issue}
                  onChange={handleIssueChange}
                  required
                >
                  <option value="">Select an option</option>
                  <option value="Order Related Issue">
                    Order Related Issue
                  </option>
                  <option value="Issue with My Starbucks Rewards Card">
                    Issue with My Starbucks Rewards Card
                  </option>
                  <option value="Store Feedback">Store Feedback</option>
                  <option value="Payment or Refund Related Query">
                    Payment or Refund Related Query
                  </option>
                  <option value="Login Issue">Login Issue</option>
                  <option value="Issue with e-Gift Card">
                    Issue with e-Gift Card
                  </option>
                  <option value="Others">Others</option>
                  <option value="Report Stolen Card">Report Stolen Card</option>
                </select>
              </div>
              <div className="mob-contact-aboutus-form-group">
                <label htmlFor="questions">Any other questions?</label>
                <textarea
                  id="questions"
                  className="mob-contact-aboutus-textarea"
                  placeholder="Type your message here..."
                  value={questions}
                  onChange={handleQuestionsChange}
                  required
                />
              </div>
              <button type="submit" className="mob-contact-aboutus-submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
      <MobFooter />
    </div>
  );
};

const PopupMenu = ({ isOpen, onClose }) => {
  const navigate = useNavigate(); 

  const handleNavigate = (path) => {
    navigate(path); 
    onClose();       
  };

  return (
    <div className={`mob-training-popup-menu ${isOpen ? "show" : ""}`}>
      <ul className="mob-training-pop-menu-navbar-main">
        <li onClick={() => handleNavigate("/")}>
          Home
          <img src={menu} className="mob-training-pop-menu-navbar-img" alt="Menu" />
        </li>
        <li onClick={() => handleNavigate("/about")}>
          About Us
          <img src={aboutus} className="mob-training-pop-menu-navbar-img" alt="About Us" />
        </li>
        <li onClick={() => handleNavigate("/blog")}>
          Blogs
          <img src={blogs} className="mob-training-pop-menu-navbar-img" alt="Blogs" />
        </li>
        <li onClick={() => handleNavigate("/contact")}>
          Contact Us
          <img src={contactus} className="mob-training-pop-menu-navbar-img" alt="Contact Us" />
        </li>
      </ul>
      <button onClick={onClose} className="mob-training-close-popup">
        Close
      </button>
    </div>
  );
};

export default MobContactUs;
