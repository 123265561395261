import React, { useState, useEffect } from "react";
import "./MobLoginpage.css";
import userIcon from "../../../images/Desktop-icons/icecream-login-icon.svg";
import helpcenterlogo from "../../../images/Desktop-icons/help-center.svg";
import backIcon from "../../../images/Mobile-icons/Backbutton.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASEURL;

const MobLoginPage = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (isPopupOpen) {
      document.body.classList.add("mob-login-no-scroll");
    } else {
      document.body.classList.remove("mob-login-no-scroll");
    }
    return () => document.body.classList.remove("mob-login-no-scroll");
  }, [isPopupOpen]);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const goBack = () => {
    console.log("Back button clicked");
   
navigate(-1)
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    try {
     
      const response = await axios.post(`${baseUrl}/api/user/login`, {
        email: email,
        password: password,
      });
      const userData = response.data;
      localStorage.setItem("userData", JSON.stringify(userData));
      if(response.data){
        toast.success(response.data.message, {
  className: "custom-toast",
});
        
      }
      navigate('/')
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error
        (error.response.data.message, {
  className: "custom-toast",
});
      } else {
        toast.error
       ('An unexpected error occurred', {
  className: "custom-toast",
});
      }
    }
  };
  return (
    <div>
      <div className="mob-login-navbar">
        <img
          src={backIcon}
          alt="Back"
          className="mob-login-back-icon"
          onClick={goBack}

        />
        <p className="mob-login-navbar-title">Account</p>
      </div>
      <div className="mob-login-navbar-shadow"></div>
      <div className="mob-login-page-container">
        <div className="mob-login-content">
          <img src={userIcon} alt="User Icon" className="mob-login-user-icon" />
          <p className="mob-login-welcome-text">WELCOME TO MANNA DELIGHTS</p>
          <div className="mob-login-content-button-sec">

          <button className="mob-login-signup-button" onClick={openPopup}>
            Login 
          </button>
          <button className="mob-login-signup-button" onClick={() => {
                navigate("/signup");
              }}>
            Signup 
          </button>

          </div>
        </div>
      </div>
      <div className="mob-login-help-center-section">
        <img
          src={helpcenterlogo}
          alt="Help Center Icon"
          className="mob-login-help-center-icon"
        />
        <a href="/help-center" className="mob-login-help-center-link">
          Help Center
        </a>
      </div>
      {isPopupOpen && (
        <div className="mob-login-popup-container">
          <div className="mob-login-popup-box">
            <button className="mob-login-close-button" onClick={closePopup}>
              Skip
            </button>
            <div className="mob-login-header">
              <h1>Login</h1>
            </div>
            <form className="mob-login-form">
              <div className="mob-login-field">
                <label htmlFor="email">Email Address</label>
                <input 
                type="email" 
                id="email" 
                name="email" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required />
              </div>
              <div className="mob-login-field">
                <label htmlFor="password">Password</label>
                <input 
                type="password" 
                id="password" 
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Password"
                name="password"
                 required />
              </div>
              <div className="mob-login-notsign-field">
                <p>
                  Don't have an account? <span >SignUp</span>
                </p>
              </div>
              <div className="mob-login-actions">
                <button type="submit" className="mob-login-button"  onClick={handlesubmit}>
                  Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobLoginPage;
