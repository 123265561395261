import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./MobCareer.css";
import backbutton from "../../../images/Mobile-icons/Backbutton.svg";
import settingmob from "../../../images/Mobile-icons/settings-svgrepo.svg";
import menu from "../../../images/Desktop-icons/menu-svg.svg";
import aboutus from "../../../images/Desktop-icons/about-svgrepo-com.svg";
import blogs from "../../../images/Desktop-icons/blog.svg";
import contactus from "../../../images/Desktop-icons/contact-svg.svg";
import MobFooter from "../MobFooter/MobFooter";
import p44 from "../../../images/p-2.png";

import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { storage } from "../../../Firebase/Config";
import { v4 as uuidv4 } from "uuid";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
const baseUrl = process.env.REACT_APP_BASEURL;

const PopupMenu = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
    onClose();
  };

  return (
    <div className={`mob-training-popup-menu ${isOpen ? "show" : ""}`}>
      <ul className="mob-training-pop-menu-navbar-main">
        <li onClick={() => handleNavigate("/")}>
          Home
          <img
            src={menu}
            className="mob-training-pop-menu-navbar-img"
            alt="Menu"
          />
        </li>
        <li onClick={() => handleNavigate("/about")}>
          About Us
          <img
            src={aboutus}
            className="mob-training-pop-menu-navbar-img"
            alt="About Us"
          />
        </li>
        <li onClick={() => handleNavigate("/blog")}>
          Blogs
          <img
            src={blogs}
            className="mob-training-pop-menu-navbar-img"
            alt="Blogs"
          />
        </li>
        <li onClick={() => handleNavigate("/contact")}>
          Contact Us
          <img
            src={contactus}
            className="mob-training-pop-menu-navbar-img"
            alt="Contact Us"
          />
        </li>
      </ul>
      <button onClick={onClose} className="mob-training-close-popup">
        Close
      </button>
    </div>
  );
};

const ApplyPopup = ({ isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    name: "",
    coverLetter: "",
    position: "",
    linkedinProfile: "",
    resume: null,
  });
  const [captchaValue, setCaptchaValue] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0); // For showing upload progress

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      resume: e.target.files[0],
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // if (!captchaValue) {
    //   alert("Please verify that you are not a robot.");
    //   return;
    // }

    if (formData.resume) {
      const storageRef = ref(storage, `resumes/${formData.resume.name}`);
      const uploadTask = uploadBytesResumable(storageRef, formData.resume);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.error("Upload failed:", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at:", downloadURL);

            const submissionData = {
              ...formData,
              resumeUrl: downloadURL,
            };

            axios
              .post(`${baseUrl}/api/blog/submit`, submissionData)
              .then((response) => {
                console.log("Form data submitted:", response.data);
                setFormData({
                  name: "",
                  coverLetter: "",
                  position: "",
                  linkedinProfile: "",
                  resume: null,
                });
                onClose(false);
              })
              .catch((error) => {
                console.error("Error submitting form:", error);
              });
          });
        }
      );
    }
  };
  return isOpen ? (
    <div className="pop-mobcar-overlay">
      <div className="pop-mobcar-popup">
        <h2 className="pop-mobcar-popup-head">Apply for the Position</h2>
        <form onSubmit={handleSubmit}>
          <label className="pop-mobcar-label">Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="pop-mobcar-input"
            required
          />
          <label className="pop-mobcar-label">Cover Letter:</label>
          <textarea
            name="coverLetter"
            value={formData.coverLetter}
            onChange={handleChange}
            className="pop-mobcar-input"
            required
          />
          <label className="pop-mobcar-label">Applying Position:</label>
          <input
            type="text"
            name="position"
            value={formData.position}
            onChange={handleChange}
            className="pop-mobcar-input"
            required
          />
          <label className="pop-mobcar-label">LinkedIn Profile:</label>
          <input
            type="url"
            name="linkedinProfile"
            value={formData.linkedinProfile}
            onChange={handleChange}
            className="pop-mobcar-input"
          />
          <label className="pop-mobcar-label">Resume/CV:</label>
          <small style={{ color: "gray" }}>
            *Resume/CV size must be less than 3MB
          </small>
          <input
            type="file"
            name="resume"
            onChange={handleFileChange}
            className="pop-mobcar-input"
            required
          />
          {/* <div className="pop-mobcar-recaptcha">
            <ReCAPTCHA
              sitekey="YOUR_RECAPTCHA_SITE_KEY"
              onChange={(value) => setCaptchaValue(value)}
            />
          </div> */}
          <div className="pop-mobcar-button-sect">
            <button type="submit" className="pop-mobcar-submit-btn">
              Submit
            </button>
            <button className="pop-mobcar-close" onClick={onClose}>
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  ) : null;
};

const MobCareer = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isApplyPopupOpen, setApplyPopupOpen] = useState(false);
  const [data, setData] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState([]);
  const navigate = useNavigate();

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  const toggleApplyPopup = () => {
    setApplyPopupOpen(!isApplyPopupOpen);
  };

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const fetchDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/blog/alloncareer`);
      setData(response.data);
      setExpandedDescriptions(Array(response.data.length).fill(false));
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const toggleDescription = (index) => {
    const updatedExpanded = expandedDescriptions.map((item, i) =>
      i === index ? !item : item
    );
    setExpandedDescriptions(updatedExpanded);
  };

  const handleSubmitApplication = (formData) => {
    console.log("Application submitted:", formData);
  };

  return (
    <div className="mob-career-full-width-container">
      <div className="mob-career-navbar">
        <img
          className="mob-career-back-button"
          src={backbutton}
          alt="Back Button"
          onClick={handleBackButtonClick}
        />
        <div className="mob-career-page-title">Careers</div>
        <img
          src={settingmob}
          alt="Settings"
          className="mob-career-logo"
          onClick={togglePopup}
        />
      </div>

      <PopupMenu isOpen={isPopupOpen} onClose={togglePopup} />

      {data?.map((item, index) => (
        <div className="mob-career-content" key={index}>
          <h2 className="mob-career-header">{item?.jobtitle}</h2>
          <p className="mob-career-text">
            {expandedDescriptions[index]
              ? item?.description
              : item?.description.slice(0, 300) +
                (item?.description.length > 300 ? "..." : "")}
            {item?.description.length > 300 && (
              <button
                className="mob-car-toggle-button"
                onClick={() => toggleDescription(index)}
              >
                {expandedDescriptions[index] ? "See Less" : "See More"}
              </button>
            )}
          </p>
          <div>
            {item?.image && (
              <img
                src={item?.image}
                alt="career"
                className="mob-career-image"
              />
            )}{" "}
          </div>
          <button
            className="mob-career-apply-button"
            onClick={toggleApplyPopup}
          >
            Apply Now
          </button>
        </div>
      ))}

      <ApplyPopup
        isOpen={isApplyPopupOpen}
        onClose={toggleApplyPopup}
        onSubmit={handleSubmitApplication}
      />

      <div className="mob-career-footer">
        <MobFooter />
      </div>
    </div>
  );
};

export default MobCareer;
