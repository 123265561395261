import React from "react";
import sidemainlogo from "../../images/bottom-logo.svg";
import backbutton from "../../images/Mobile-icons/Backbutton.svg";
import "./EmptyOrderPage.css";
import menu from "../../images/Desktop-icons/menu-svg.svg";
import order from "../../images/Desktop-icons/bag-order-svgrepo.svg";
import aboutus from "../../images/Desktop-icons/about-svgrepo-com.svg";
import blogs from "../../images/Desktop-icons/blog.svg";
import contactus from "../../images/Desktop-icons/contact-svg.svg";
import emptycart from "../../images/Desktop-icons/empty-cart.svg";

const EmptyOrderPage = () => {
  return (
    <div className="emptyorder-full-width-container">
      <div className="emptyorder-navbar">
        <img src={sidemainlogo} className="emptyorder-sidemainlogo" alt="Side Logo" />
        <img className="emptyorder-back-button" src={backbutton} alt="Back Button" />
        <div className="emptyorder-page-title">Orders</div>
      </div>

      <div className="emptyorder-page-content">
        <div className="emptyorder-sticky-navbar">
          <div className="emptyorder-navbar-item">
            Menu
            <img src={menu} className="emptyorder-navbar-img" />
          </div>
          <div className="emptyorder-navbar-item selected">
            Orders
            <img src={order} className="emptyorder-navbar-img" />
          </div>
          <div className="emptyorder-navbar-item">
            About Us
            <img src={aboutus} className="emptyorder-navbar-img" />
          </div>
          <div className="emptyorder-navbar-item">
            Blogs
            <img src={blogs} className="emptyorder-navbar-img" />
          </div>
          <div className="emptyorder-navbar-item">
            Contact Us
            <img src={contactus} className="emptyorder-navbar-img" />
          </div>
        </div>
        <div className="emptyorder-content">
          <div className="emptyorder-content-inner">
            <img src={emptycart} className="emptyorder-img" alt="Empty Cart" />
            <h2 className="emptyorder-title">No Orders Yet</h2>
            <p className="emptyorder-description">
              Looks like you haven't placed any orders yet.
            </p>
            <button className="emptyorder-button">Start Shopping</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyOrderPage;
